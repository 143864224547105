import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {
  CardActions,
  Typography,
  Grid,
  Menu,
  MenuItem,
  CardHeader
} from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import firebaseApp from "../Firebase/firebase";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import ArchiveIcon from "@material-ui/icons/Archive";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import AlertDialog from "../Library/AlertDialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LockIcon from "@material-ui/icons/Lock";
import CodeIcon from "@material-ui/icons/Code";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import entryTheme from "../Library/EntryTheme";
import colors from "../Library/Colors";

import { getCurrentUserId } from "../Library/Helper";

import { isMobile } from "react-device-detect";

import * as ROUTES from "../../constants/routes";

const ReactMarkdown = require("react-markdown");

const simplelib = require("@bvelasquez/simple-log-lib");

const currentUserId = getCurrentUserId();
simplelib.SetUserId(currentUserId);
simplelib.SetFirebase(firebaseApp);

const LogShare = props => {
  if (props.value.shared) {
    return (
      <Tooltip title="Disable sharing on this log.">
        <ShareIcon
          style={{ width: 45, color: colors.entryPrimaryText }}
          aria-label="settings"
          onClick={() => {
            props.handleShared(props.value);
          }}
        ></ShareIcon>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Share this log with others.">
      <ShareOutlinedIcon
        style={{ width: 45, color: colors.entryPrimaryText }}
        aria-label="settings"
        onClick={() => {
          props.handleShared(props.value);
        }}
      ></ShareOutlinedIcon>
    </Tooltip>
  );
};

const rendererParagraph = props => {
  return (
    <Typography paragraph variant="body1" style={{}}>
      {props.children}
    </Typography>
  );
};

const rendererHeading = props => {
  return (
    <Typography paragraph variant={`h${props.level}`}>
      {props.children}
    </Typography>
  );
};

const EntryTitle = props => {
  if (props.title && props.title.length > 0) {
    return (
      <CardHeader
        style={{ color: colors.primaryHighlight }}
        title={props.title}
      ></CardHeader>
    );
  }
  return null;
};

class Log extends React.Component {
  constructor(props) {
    super(props);

    this.history = props.history;

    this.handleEditEntryButton = this.handleEditEntryButton.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.state = {
      menuOpen: false,
      anchorEl: null,

      alertOpen: false,
      alertTitle: "",
      alertMessage: ""
    };
  }

  showAlert = (title, message) => {
    this.setState({
      alertOpen: true,
      alertTitle: title,
      alertMessage: message
    });
  };

  handleMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget, menuOpen: true });
  };

  handleShare = value => {
    const plan = localStorage.getItem("plan");

    if (plan) {
      simplelib.FirebaseFunctions.shareLog(value.id, !value.shared);
      this.handleGetShareLink(value);
    } else {
      firebaseApp.analytics().logEvent("need_subscription_alert");
      this.showAlert(
        "Subscription",
        "You need a paid subscription to share. Sharing allows you to let others see a log or entry and let others see updates instantly. They don't need an account to view your entry. Sign up today and start sharing."
      );
    }
  };

  handleEditEntryButton = value => {
    this.props.didEdit(value);
  };

  handleDeleteLog = value => {
    if (this.props.didDeleteLog) {
      this.props.didDeleteLog(value);
    }
  };

  handleGetShareLink = value => {
    console.log("SHARE LINK ==>", this.props);

    const currentUserId = getCurrentUserId();
    if (value.tinyUrl) {
      navigator.clipboard.writeText(value.tinyUrl);
    } else {
      navigator.clipboard.writeText(
        `${window.location.origin.toString()}/shared_log/${currentUserId}/${
          value.id
        }`
      );
    }
  };

  handleGetiFrame = value => {
    navigator.clipboard.writeText(
      `
      <iframe src="${window.location.origin.toString()}/shared_log/${currentUserId}/${
        value.id
      }?limit=10&hideSearch=true&hideMenu=true" height="85%" width="400px" name="${
        value.id
      }">
    </iframe>
      `
    );
  };

  handleDelete = (value, props) => {
    const plan = localStorage.getItem("plan");
    if (plan) {
      var r = window.confirm(
        !value.archived || value.archived === false
          ? "Archive this Log? The log will not be deleted, but will be hidden from views. You can always restore an archived log."
          : "Restore this log?"
      );
    } else {
      window.confirm("You cannot archive logs without a subscription.");
      return;
    }
    if (r === true) {
      // Archive the log.

      if (!value.archived || value.archived === false) {
        if (plan) {
          simplelib.FirebaseFunctions.archiveLog(value.id);
        } else {
          window.confirm("You cannot archive logs without a subscription.");
        }
      } else {
        if (plan) {
          simplelib.FirebaseFunctions.restoreLog(value.id);
        } else {
          window.confirm(
            "You cannot restore additional logs without a subscription."
          );
        }
      }
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  handleNavigateToEntries = () => {
    this.props.history.push(`/entries?logId=${this.props.value.id}`);
  };

  render() {
    const { value } = this.props;
    const plan = localStorage.getItem("plan");
    let updatedDateString = "";
    if (value.updatedAt) {
      const updatedAt = value.updatedAt.toDate();

      updatedDateString = `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleString(
        "en-US",
        {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        }
      )}`;
    }

    return (
      <MuiThemeProvider theme={entryTheme}>
        <Card
          style={{
            marginBottom: 40,
            width: "100%"
          }}
        >
          <EntryTitle title={value.title} />

          <CardContent>
            <ReactMarkdown
              source={value.body}
              renderers={{
                paragraph: rendererParagraph,
                heading: rendererHeading
              }}
            />
            <Typography
              style={{ marginTop: "5px", fontSize: 10 }}
              variant="body2"
            >
              {`last modified: ${updatedDateString}`}
            </Typography>
            <Typography
              style={{ marginTop: "5px", fontSize: 10 }}
              variant="body2"
            >
              {`id: ${value.id}`}
            </Typography>

            {value.archived && (
              <Typography
                style={{ marginTop: "5px", fontSize: 10 }}
                variant="body2"
              >
                {`log is archived`}
              </Typography>
            )}

            {value.isVaulted && (
              <Typography
                style={{ marginTop: "5px", fontSize: 10 }}
                variant="body2"
              >
                {`log is vaulted`}
              </Typography>
            )}
          </CardContent>

          <CardActions style={{ marginBottom: 12 }}>
            <Grid container direction="row" justifyContent="flex-start">
              <EditIcon
                fontSize={isMobile ? "small" : "medium"}
                style={{ width: 45, color: colors.entryPrimaryText }}
                aria-label="settings"
                onClick={() => {
                  this.handleEditEntryButton(value);
                }}
              ></EditIcon>

              {value.archived && (
                <UnarchiveIcon
                  fontSize={isMobile ? "small" : "medium"}
                  style={{ width: 45, color: colors.entryPrimaryText }}
                  aria-label="settings"
                  onClick={() => {
                    this.handleDelete(value);
                  }}
                ></UnarchiveIcon>
              )}

              {(!value.archived || value.archived === false) && (
                <Tooltip title="Archive this log. It can be unarchived later.">
                  <ArchiveIcon
                    fontSize={isMobile ? "small" : "medium"}
                    style={{ width: 45, color: colors.entryPrimaryText }}
                    aria-label="settings"
                    onClick={() => {
                      this.handleDelete(value);
                    }}
                  ></ArchiveIcon>
                </Tooltip>
              )}

              {value.archived && (
                <DeleteIcon
                  fontSize={isMobile ? "small" : "medium"}
                  style={{ width: 45, color: colors.entryPrimaryText }}
                  aria-label="settings"
                  onClick={() => {
                    this.handleDeleteLog(value);
                  }}
                ></DeleteIcon>
              )}

              {(value.allowReadAPIAccess ||
                value.allowWriteAPIAccess ||
                value.enableWebhook) && (
                <Tooltip title="This log has API access enabled.">
                  <CodeIcon
                    fontSize={isMobile ? "small" : "medium"}
                    style={{ color: colors.entryPrimaryText, width: 45 }}
                  />
                </Tooltip>
              )}

              {plan && value.shared && value.tinyUrl && !value.isVaulted && (
                <MoreVertIcon
                  fontSize={isMobile ? "small" : "medium"}
                  style={{ color: colors.entryPrimaryText, width: 45 }}
                  onClick={this.handleMenuClick}
                />
              )}
            </Grid>
            {!value.isVaulted && (
              <Grid container direction="row" justifyContent="flex-end">
                <LogShare value={value} handleShared={this.handleShare} />
                <Tooltip title="Navigate to the logs entries.">
                  <ChevronRightIcon
                    fontSize={isMobile ? "small" : "medium"}
                    style={{ color: colors.entryPrimaryText, width: 45 }}
                    onClick={this.handleNavigateToEntries}
                  />
                </Tooltip>
              </Grid>
            )}
            {value.isVaulted && (
              <Grid container direction="row" justifyContent="flex-end">
                <Tooltip title="This log is vaulted. It requires an additional password to access.">
                  <LockIcon
                    fontSize={isMobile ? "small" : "medium"}
                    style={{ color: colors.entryPrimaryText, width: 45 }}
                  />
                </Tooltip>
              </Grid>
            )}
          </CardActions>

          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.menuOpen}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                width: 220,
                color: colors.entryPrimaryText,
                backgroundColor: colors.primaryBackground
              }
            }}
          >
            {value.shared && value.tinyUrl && (
              <MenuItem
                key="getShareLink"
                onClick={() => {
                  this.handleGetShareLink(value);
                }}
              >
                Get Share Link
              </MenuItem>
            )}
            {value.shared && (
              <MenuItem
                key="getiFrame"
                onClick={() => {
                  this.handleGetiFrame(value);
                }}
              >
                Copy iFrame to Clipboard
              </MenuItem>
            )}
          </Menu>

          <AlertDialog
            open={this.state.alertOpen}
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            handleClose={() => {
              this.setState({ alertOpen: false });
            }}
            handleNavigation={() => {
              // navigate to account.
              console.log("HANDLE NAVIGATION");
              this.props.history.push(ROUTES.ACCOUNT);
            }}
          />
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default Log;
