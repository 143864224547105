import React from "react";
import {
  Container,
  Box,
  Typography,
  Snackbar,
  TextField,
  Grid
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

import "firebase/analytics";
import "firebase/auth";
import firebaseApp from "../Firebase/firebase";

import * as ROUTES from "../../constants/routes";
import YellowButton from "../Library/YellowButton";
import colors from "../Library/Colors";

const queryString = require("query-string");

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"].join(","),
    button: {
      textTransform: "none"
    }
  },
  palette: {
    primary: {
      main: colors.secondaryBackground
    },

    background: {
      default: colors.secondaryBackground
    }
  }
});

class Handler extends React.Component {
  constructor(props) {
    super(props);

    let url = this.props.location.search;
    let params = queryString.parse(url);

    console.log("Params", params);

    this.state = {
      email: "",
      params: params,
      error: null,
      errorOpen: false,
      errorMessage: "",
      password: "",
      verifyComplete: false
    };
  }

  async componentDidMount() {
    const { params } = this.state;

    switch (params.mode) {
      case "resetPassword":
        await this.handleResetPassword();
        break;
      case "verifyEmail":
        await this.handleVerifyEmail();
        break;
      default:
        break;
    }
  }

  handleResetPassword = async () => {
    const { params } = this.state;
    try {
      const email = await firebaseApp
        .auth()
        .verifyPasswordResetCode(params.oobCode);
      console.log("email", email);

      this.setState({ email });
    } catch (e) {
      // Error during reset.
      this.setState({
        errorOpen: true,
        errorMessage: "Could not verify password reset code.",
        errorClose: () => {
          this.props.history.push(ROUTES.SIGN_IN);
        }
      });
    }
  };

  resetPassword = async () => {
    if (this.state.password && this.state.password.length > 0) {
      try {
        await firebaseApp
          .auth()
          .confirmPasswordReset(this.state.params.oobCode, this.state.password);

        await firebaseApp
          .auth()
          .signInWithEmailAndPassword(this.state.email, this.state.password);

        this.setState({
          errorOpen: true,
          errorMessage: "Password reset successfully."
        });

        this.props.history.push(ROUTES.HOME);
      } catch (e) {
        console.log("error resetting password", e);
        this.setState({
          errorOpen: true,
          errorMessage: "There was a problem resetting your password.",
          errorClose: () => {
            this.props.history.push(ROUTES.SIGN_IN);
          }
        });
      }
    } else {
      this.setState({
        errorOpen: true,
        errorMessage: "Enter a password."
      });
    }
  };

  handleVerifyEmail = async () => {
    try {
      await firebaseApp.auth().applyActionCode(this.state.params.oobCode);

      this.setState({ verifyComplete: true });
    } catch (e) {
      this.setState({
        errorOpen: true,
        errorMessage: "Error verifying email.",
        errorClose: () => {
          this.props.history.push(ROUTES.SIGN_IN);
        }
      });
    }
  };

  renderHelmet = mode => {
    let title = "SimpleLog3";
    switch (mode) {
      case "resetPassword":
        title = "SimpleLog3 - Reset Password";
        break;
      case "verifyEmail":
        title = "SimpleLog3 - Email Verification";
        break;
      default:
        break;
    }
    return (
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="Need a journal, log, or diary? SimpleLog3, the last log you'll ever need. Journal, Diary, Logging made simple. Free sign up."
        />
      </Helmet>
    );
  };

  renderVerifyComplete = () => {
    return (
      <Grid
        item
        style={{
          backgroundColor: "white",
          padding: isMobile ? 24 : 60,
          borderTopRightRadius: 12,
          borderBottomRightRadius: 12,
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12
        }}
      >
        <Typography
          style={{
            color: colors.primaryBackground,
            fontSize: 60,
            fontWeight: "700",
            marginBottom: 54
          }}
          align="center"
        >
          Email Verification
        </Typography>
        <Box>
          <Box
            style={{
              width: "100%",
              marginTop: 20,
              marginBottom: 20,
              textAlign: "center"
            }}
          >
            <Typography
              align="center"
              style={{
                color: colors.primaryBackground,
                fontSize: 20,
                fontWeight: "700",
                marginBottom: 54
              }}
            >
              {this.state.verifyComplete
                ? "Thank you for verifying your email address. Hope you enjoy SimpleLog3. Contact us with any questions."
                : "Verifying email address..."}
            </Typography>
          </Box>
          <Box
            style={{
              width: "100%",
              marginTop: 20,
              marginBottom: 20,
              textAlign: "center"
            }}
          >
            <YellowButton
              variant="contained"
              type="submit"
              style={{ width: 220 }}
              onClick={() => {
                this.props.history.push(ROUTES.SIGN_IN);
              }}
            >
              Go to Sign In
            </YellowButton>
          </Box>
        </Box>
      </Grid>
    );
  };

  renderResetPasswordContent = () => {
    return (
      <Grid
        item
        style={{
          backgroundColor: "white",
          padding: isMobile ? 24 : 60,
          borderTopRightRadius: 12,
          borderBottomRightRadius: 12,
          borderTopLeftRadius: 12,
          borderBottomLeftRadius: 12
        }}
      >
        <Typography
          style={{
            color: colors.primaryBackground,
            fontSize: isMobile ? 40 : 50,
            fontWeight: "700",
            marginBottom: 44
          }}
          align="center"
        >
          Enter a new Password
        </Typography>
        <Box>
          <Box style={{ marginBottom: 20 }}>
            <Typography
              noWrap
              style={{
                color: colors.primaryBackground,
                fontSize: isMobile ? 16 : 24,
                fontWeight: "700",
                marginBottom: 54
              }}
              align="center"
            >
              {this.state.email}
            </Typography>
          </Box>
          <Box style={{ marginBottom: 20 }}>
            <TextField
              required={true}
              fullWidth={true}
              variant="outlined"
              name="password"
              value={this.state.password}
              onChange={event => {
                this.setState({ password: event.target.value });
              }}
              type="password"
              placeholder="Password"
              autoComplete="current-password"
            />
          </Box>

          <Box
            style={{
              width: "100%",
              marginTop: 20,
              marginBottom: 20,
              textAlign: "center"
            }}
          >
            <YellowButton
              variant="contained"
              type="submit"
              style={{ width: 220 }}
              onClick={this.resetPassword}
            >
              Set Password
            </YellowButton>
          </Box>
        </Box>
      </Grid>
    );
  };

  renderContent = mode => {
    switch (mode) {
      case "resetPassword":
        return this.renderResetPasswordContent();
      case "verifyEmail":
        return this.renderVerifyComplete();
      default:
        break;
    }
  };

  render() {
    const { params } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        {this.renderHelmet(params.mode)}
        <CssBaseline />
        <Container
          maxWidth={isMobile ? "xs" : "lg"}
          style={{ marginTop: 54, marginBottom: 12, paddingBottom: 24 }}
        >
          <Container maxWidth={isMobile ? "xs" : "sm"}>
            {this.renderContent(params.mode)}
          </Container>
        </Container>
        <Snackbar
          autoHideDuration={5000}
          open={this.state.errorOpen}
          onClose={() => {
            if (this.state.errorClose) {
              this.state.errorClose();
            }
            this.setState({ errorOpen: false });
          }}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.errorMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => {
                if (this.state.errorClose) {
                  this.state.errorClose();
                }
                this.setState({ errorOpen: false });
              }}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </MuiThemeProvider>
    );
  }
}

export default Handler;
