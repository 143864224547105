import React from "react";
import {
  Container,
  Box,
  Typography,
  Snackbar,
  TextField,
  Grid,
  Button,
  Checkbox
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { isMobile } from "react-device-detect";
import { MuiThemeProvider } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import "firebase/auth";
import "firebase/analytics";
import firebaseApp from "../Firebase/firebase";
import { Link } from "react-router-dom";

import { StylesProvider } from "@material-ui/styles";

import StyledButton from "../Library/StyledButton";
import YellowButton from "../Library/YellowButton";
import ThemeSelection from "../Library/ThemeSelection";
import pageTheme from "../Library/PageTheme";
import colors from "../Library/Colors";
import currentKeys from "../Library/StripeConstants";
import { loadStripe } from "@stripe/stripe-js";
import * as ROUTES from "../../constants/routes";
import { colorThemes } from "../Library/ColorManager";

import { getCurrentUserId } from "../Library/Helper";

const simplelib = require("@bvelasquez/simple-log-lib");
simplelib.SetUserId();
simplelib.SetFirebase(firebaseApp);

const uniqid = require("uniqid");
const stripePromise = loadStripe(currentKeys.currentAPIKey);

const DarkCircularProgress = withStyles(theme => ({
  root: {
    color: colors.entryPrimaryText
  }
}))(CircularProgress);

const Subscriptions = props => {
  const [progress, setProgress] = React.useState(false);

  const handleStripe = async planId => {
    const currentUserId = getCurrentUserId();
    const email = localStorage.getItem("email");

    firebaseApp.analytics().logEvent("begin_checkout");

    console.log("getStripeSession", planId, currentUserId, email);

    const sessionResponse = await simplelib.APIFunctions.getStripeSession(
      planId,
      currentUserId,
      email
    );

    console.log("sessionResponse", sessionResponse);

    if (sessionResponse && sessionResponse.session) {
      console.log("Session Response", sessionResponse.session);

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionResponse.session.id
      });
      if (error) {
        console.log("Stripe redirect error", error.message);
      }
    }
  };

  const Progress = props => {
    return (
      <Box
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <DarkCircularProgress
          size={40}
          color="primary"
          variant="indeterminate"
        />
      </Box>
    );
  };

  return (
    <Container maxWidth="md">
      <Box
        style={{
          borderRadius: 12,
          padding: 20,
          textAlign: "center",
          backgroundColor: colors.secondaryBackground
        }}
      >
        <Typography
          style={{
            fontSize: 36,
            fontWeight: "500",
            color: colors.entryPrimaryText
          }}
        >
          SimpleLog<sup>3</sup> Free Account
        </Typography>
        <Typography
          style={{
            marginTop: 12,
            marginBottom: 25,
            color: colors.entryPrimaryText
          }}
        >
          Free account provides the Default Log, 2.0 MB Image Attachment, two
          Filters and two Templates.
        </Typography>

        <Typography
          style={{
            fontSize: 36,
            fontWeight: "500",
            color: colors.entryPrimaryText
          }}
        >
          SimpleLog<sup>3</sup> Subscription
        </Typography>
        <Box style={{ margin: 24 }}>
          <Typography style={{ color: colors.entryPrimaryText }}>
            {`Unlimited Logs, Filters and Templates`}
          </Typography>

          <Typography style={{ marginTop: 12, color: colors.entryPrimaryText }}>
            Share your entries with others, upload larger images (8 MB), plus
            access all current and future features.
          </Typography>
        </Box>

        {progress && <Progress />}

        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          {props.plans ? (
            props.plans.map(p => {
              return (
                <Grid item xs key={p.id}>
                  <Typography variant="h6" style={{ fontSize: 20 }}>
                    {p.nickname}
                  </Typography>
                  <Typography variant="h6" style={{ marginBottom: 10 }}>{`$${(
                    p.amount / 100
                  ).toFixed(2)}`}</Typography>
                  <StyledButton
                    id="checkout-button-plan_H4e7vKhbzeCLMx"
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      setProgress(true);
                      await handleStripe(p.id);
                      setProgress(false);
                    }}
                  >
                    Subscribe Now
                  </StyledButton>
                </Grid>
              );
            })
          ) : (
            <Progress />
          )}
        </Grid>
      </Box>
    </Container>
  );
};

const ErrorMessage = props => {
  return (
    <Snackbar
      autoHideDuration={5000}
      open={props.open}
      onClose={props.handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{props.errorMessage}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
};

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      errorOpen: false,
      errorMessage: "",
      email: "",
      firstName: "",
      lastName: "",
      theme: localStorage.getItem("theme")
        ? localStorage.getItem("theme")
        : colorThemes.midnightFireTheme,
      allowMarketing: false,
      allowCommunication: false,
      stripeLoaded: false,
      enableAPIAccess: false,
      apiAccessKey: null,
      apiPassword: null,
      plans: null,
      subscribing: false
    };
  }

  componentDidMount() {
    firebaseApp.analytics().logEvent("page_view", {
      page_location: "account",
      page_path: "/account",
      page_title: "Account"
    });

    this.authObserver = firebaseApp.auth().onAuthStateChanged(user => {
      if (!user) {
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("email");
        localStorage.removeItem("logId");
      } else {
        localStorage.setItem("currentUserId", user.uid);
        localStorage.setItem("email", user.email);
      }
    });

    this.setupAccountData();
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer();
    }

    if (this.authObserver) {
      this.authObserver();
    }
  }

  setupAccountData = () => {
    const currentUserId = getCurrentUserId();
    const email = localStorage.getItem("email");

    if (!currentUserId) {
      this.props.history.push(ROUTES.LANDING);
      return;
    }

    simplelib.FirebaseSnapshots.account(this.observer, data => {
      let stateUpdate = {};
      if (data) {
        stateUpdate = {
          email: email,
          theme: data.theme ? data.theme : colorThemes.midnightFireTheme,
          enableAPIAccess: data.enableAPIAccess ? data.enableAPIAccess : false,
          apiAccessKey: data.apiAccessKey ? data.apiAccessKey : null,
          allowMarketing: data.allowMarketing ? data.allowMarketing : true,
          allowCommunication: data.allowCommunication
            ? data.allowCommunication
            : true,
          stripeCustomerId: data.stripeCustomerId
            ? data.stripeCustomerId
            : null,
          stripeSubscriptionId: data.stripeSubscriptionId
            ? data.stripeSubscriptionId
            : null
        };

        if (data.firstName) {
          stateUpdate.firstName = data.firstName;
        }
        if (data.lastName) {
          stateUpdate.lastName = data.lastName;
        }
      } else {
        stateUpdate = { email: email };
        stateUpdate.firstName = "";
        stateUpdate.lastName = "";
      }
      this.setState(stateUpdate);

      simplelib.APIFunctions.getPlans(currentKeys.currentProductKey).then(r => {
        this.setState({ plans: r });
      });

      simplelib.APIFunctions.getProductPlan(currentUserId)
        .then(plan => {
          console.log("Got plan", plan);
          if (
            plan &&
            (plan.status === "active" || plan.status === "trialing")
          ) {
            localStorage.setItem("plan", plan.plan);
            this.setState({
              plan: plan && plan.plan ? plan.plan : undefined,
              loaded: true,
              source: plan.source
            });
          } else {
            localStorage.removeItem("plan");
            this.setState({ plan: undefined, loaded: true });
          }
        })
        .catch(e => {
          localStorage.removeItem("plan");
          this.setState({ plan: undefined, loaded: true });
        });
    });
  };

  onCancelSubscription = () => {
    var r = window.confirm(
      "Cancel your subscription? You will not be able to access subscription features."
    );
    if (r === true) {
      const currentUserId = getCurrentUserId();
      simplelib.APIFunctions.cancelProductPlan(currentUserId)
        .then(r => {
          this.setState({ stripeSubscriptionId: undefined });
        })
        .catch(e => {});
    }
  };

  onSubmit = () => {
    const {
      theme,
      firstName,
      lastName,
      allowCommunication,
      allowMarketing,
      enableAPIAccess
    } = this.state;

    if (firstName.trim().length === 0) {
      this.setState({
        errorOpen: true,
        errorMessage: "First name cannot be blank."
      });
      return;
    }

    if (lastName.trim().length === 0) {
      this.setState({
        errorOpen: true,
        errorMessage: "Last name cannot be blank."
      });
      return;
    }

    const email = localStorage.getItem("email");

    var apiPassword = null;
    if (enableAPIAccess) {
      apiPassword = uniqid();
      this.setState({ apiPassword: apiPassword });
    }

    simplelib.FirebaseFunctions.saveAccount({
      firstName: firstName,
      lastName: lastName,
      email: email ? email : "",
      theme: theme,
      updatedAt: new Date(),
      allowMarketing: allowMarketing,
      allowCommunication: allowCommunication,
      enableAPIAccess: enableAPIAccess,
      apiPassword: apiPassword
    })
      .then(() => {
        this.setState({
          errorOpen: true,
          errorMessage: "Successfully saved profile."
        });
      })
      .catch(e => {
        this.setState({
          errorOpen: true,
          errorMessage: "Error saving profile."
        });
      });
  };

  sendPasswordResetEmail = async () => {
    try {
      await firebaseApp.auth().sendPasswordResetEmail(this.state.email);
      this.setState({
        errorOpen: true,
        errorMessage: "Password reset was sent."
      });
    } catch (e) {
      this.setState({
        errorOpen: true,
        errorMessage:
          "Oops. We could not send the password reset email. Try again."
      });
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      firstName,
      lastName,
      loaded,
      email,
      theme,
      allowMarketing,
      allowCommunication,
      enableAPIAccess,
      apiPassword,
      apiAccessKey,
      stripeSubscriptionId,
      plan,
      subscribing,
      stripeCustomerId
    } = this.state;

    const currentUserId = getCurrentUserId();

    return (
      <MuiThemeProvider theme={pageTheme}>
        <Helmet>
          <title>SimpleLog3 - Account</title>
          <meta name="description" content="SimpleLog3 account page." />
          <meta name="robots" content="noindex" />
        </Helmet>
        <CssBaseline />
        <Container maxWidth="md" style={{ paddingBottom: 24 }}>
          <Box style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}>
            <Typography
              gutterBottom={true}
              align="center"
              variant="body1"
              style={{ fontSize: isMobile ? 24 : 42, fontWeight: "700" }}
            >
              Welcome to your account page
            </Typography>
          </Box>

          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography variant="h6">{email}</Typography>
          </Box>

          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <TextField
              style={{ width: isMobile ? 300 : 740 }}
              autoComplete="off"
              required={true}
              variant="outlined"
              name="firstName"
              value={firstName}
              onChange={this.onChange}
              type="text"
              placeholder="Your first name"
            />
          </Box>
          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <TextField
              style={{ width: isMobile ? 300 : 740 }}
              autoComplete="off"
              required={true}
              variant="outlined"
              name="lastName"
              value={lastName}
              onChange={this.onChange}
              type="text"
              placeholder="Your last name"
            />
          </Box>
          <Box style={{ textAlign: "center", marginBottom: 24 }}>
            <Typography>
              Need to update your password? We will send you an email and step
              you through it.
              <Button onClick={this.sendPasswordResetEmail}>
                <Typography className="resetPasswordButton">
                  Send it Now
                </Typography>
              </Button>
            </Typography>
          </Box>

          <Box style={{ marginBottom: 32, textAlign: "center" }}>
            <Typography variant="h6" style={{ marginBottom: 12 }}>
              Change your Theme
            </Typography>
            <ThemeSelection
              theme={theme ? theme : colorThemes.midnightFireTheme}
              selected={theme => {
                localStorage.setItem("theme", theme);

                simplelib.FirebaseFunctions.saveAccount({
                  theme: theme,
                  updatedAt: new Date()
                })
                  .then(() => {
                    this.setState({ theme: theme }, () => {
                      window.location.reload();
                    });
                  })
                  .catch(e => {
                    this.setState({ theme: theme }, () => {
                      //window.location.reload();
                    });
                  });
              }}
            />

            <Typography
              style={{ fontSize: colors.primaryBody2FontSize, marginTop: 8 }}
            >
              Themes change the look of SimpleLog3. Try one of our themes.
              Contact us if you have suggestions for another theme.
            </Typography>
          </Box>

          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography variant="h6" style={{ marginBottom: 12 }}>
              Communications
            </Typography>
            <Typography>
              Allow Important Communications
              <Checkbox
                color="secondary"
                checked={allowCommunication}
                onChange={event => {
                  this.setState({ allowCommunication: event.target.checked });
                }}
              />
            </Typography>
            <Typography style={{ fontSize: colors.primaryBody2FontSize }}>
              This includes, but not limited to, updates to policies, support
              responses, or updates to the service.
            </Typography>
          </Box>
          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography>
              Allow Marketing Communications
              <Checkbox
                color="secondary"
                checked={allowMarketing}
                onChange={event => {
                  this.setState({ allowMarketing: event.target.checked });
                }}
              />
            </Typography>
            <Typography style={{ fontSize: colors.primaryBody2FontSize }}>
              Rarely we will send information about upcoming new features and
              the occasional article on how best to use the service.
            </Typography>
          </Box>

          <Box style={{ textAlign: "center", marginBottom: 52 }}>
            <Typography variant="h6" style={{ marginBottom: 18 }}>
              API Access
            </Typography>

            <Typography>
              Enable API Access
              <Checkbox
                color="secondary"
                checked={enableAPIAccess ? enableAPIAccess : false}
                onChange={event => {
                  this.setState(
                    { enableAPIAccess: event.target.checked },
                    () => {
                      this.onSubmit();
                    }
                  );
                }}
              />
            </Typography>

            {apiPassword && (
              <Box style={{ marginBottom: 12 }}>
                <Typography style={{ fontWeight: "bold" }}>
                  API Passphrase
                </Typography>
                <Typography style={{ fontSize: 12, wordBreak: "break-all" }}>
                  {apiPassword}
                </Typography>
                <Typography style={{ fontSize: 12, marginTop: 12 }}>
                  Store this pass phrase security. You will need it when using
                  the API. It will not be shown again after this and we do not
                  store it. If you loose it, you need to disable and re-enable
                  API access to get a new passphrase and token.
                </Typography>
              </Box>
            )}

            {apiAccessKey && (
              <Box>
                <Typography style={{ fontWeight: "bold" }}>
                  API Access Key
                </Typography>
                <Typography style={{ fontSize: 12, wordBreak: "break-all" }}>
                  {apiAccessKey}
                </Typography>
                <Typography style={{ fontSize: 12, marginTop: 12 }}>
                  Keep this key secret. Someone can post to your logs using this
                  key. If it becomes compromised, disable and re-enable API
                  access to generate a new key.
                </Typography>
                <Typography style={{ marginTop: 8 }}>
                  <Link to="/help/api">API Documentation</Link>
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            style={{
              width: "100%",
              marginTop: 20,
              marginBottom: 24,
              textAlign: "center"
            }}
          >
            <YellowButton
              style={{ width: 300, height: 52 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.onSubmit();
              }}
            >
              Save Account Settings
            </YellowButton>
          </Box>

          {subscribing && (
            <Box style={{ textAlign: "center" }}>
              <Typography variant="h6" style={{ marginBottom: 12 }}>
                Subscribing to plan...
              </Typography>
              <Typography>{plan ? plan : ""}</Typography>
            </Box>
          )}

          {!plan && !subscribing && loaded && (
            <Box style={{ marginBottom: 24, textAlign: "center" }}>
              <Typography variant="h6" style={{ marginBottom: 12 }}>
                Subscriptions
              </Typography>
              <Subscriptions
                plans={this.state.plans}
                email={email}
                stripeCustomerId={stripeCustomerId}
                currentUserId={currentUserId}
                subscribeToProductPlan={
                  simplelib.APIFunctions.subscribeToProductPlan
                }
                beforeSubscribe={() => {
                  console.log("Before subscribe starts");
                  this.setState({ subscribing: true });
                }}
                afterSubscribe={() => {
                  console.log("After subscribe starts");
                  this.setState({ subscribing: false });
                }}
              />
            </Box>
          )}

          {plan && !subscribing && loaded && (
            <StylesProvider injectFirst>
              <Box style={{ textAlign: "center" }}>
                <Typography variant="h6" style={{ marginBottom: 18 }}>
                  Current Plan
                </Typography>
                <Typography>
                  {plan
                    ? plan === "plan_H6qkgAsC2duxJ9"
                      ? "Permanent Free Plan"
                      : `Monthly Subscription${
                          this.state.source && this.state.source === "mobile"
                            ? " - Mobile"
                            : ""
                        }`
                    : "Retreiving plan..."}

                  {this.state.source && this.state.source === "mobile" && (
                    <Typography style={{ fontSize: 10 }}>
                      Your subscription is through your mobile device. You may
                      cancel using In App Purchases using your mobile device.
                    </Typography>
                  )}
                </Typography>
                <Typography style={{ marginTop: 12, marginBottom: 24 }}>
                  Thank you for supporting SimpleLog3.
                </Typography>
              </Box>
              {stripeSubscriptionId && plan !== "plan_H6qkgAsC2duxJ9" && (
                <Box
                  style={{
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 20,
                    textAlign: "center"
                  }}
                >
                  <YellowButton
                    variant="contained"
                    onClick={() => {
                      this.onCancelSubscription();
                    }}
                  >
                    Cancel Subscription
                  </YellowButton>
                </Box>
              )}
            </StylesProvider>
          )}

          <ErrorMessage
            open={this.state.errorOpen}
            errorMessage={this.state.errorMessage}
            handleClose={() => {
              this.setState({ errorOpen: false });
            }}
          />
        </Container>
      </MuiThemeProvider>
    );
  }
}

export default Account;
