import React from "react";
import { Box, Typography, Container } from "@material-ui/core";
import { Link } from "react-router-dom";

import YellowButton from "../Library/YellowButton";

const EmptyData = props => {
  const { advancedSearch } = props;

  if (advancedSearch) {
    return (
      <Container maxWidth="sm">
        <Box>
          <Typography
            align="center"
            variant="h5"
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            {`No entries from ${props.timeWindowStrings.start} to ${props.timeWindowStrings.end}`}
          </Typography>

          <Typography
            style={{ marginBottom: 16, marginTop: 16 }}
            align="center"
          >
            This filter is returning no entries. Change the filters date range
            to include more entries.
          </Typography>
        </Box>
        <Box
          component="div"
          maxWidth="xs"
          style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}
        >
          <YellowButton
            variant="contained"
            color="primary"
            align="center"
            onClick={() => {
              props.addClick();
            }}
          >
            <Link to="/filters">Manage Filters</Link>
          </YellowButton>
        </Box>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="sm">
        <Box>
          <Typography
            align="center"
            variant="h5"
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            {`No entries from ${props.timeWindowStrings.start} to ${props.timeWindowStrings.end}`}
          </Typography>

          <Typography
            style={{ marginBottom: 16, marginTop: 16 }}
            align="center"
          >
            If this is a new log or you are new to SimpleLog3, you can add a new
            entry to get started.
          </Typography>

          <Box
            component="div"
            maxWidth="xs"
            style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}
          >
            <YellowButton
              variant="contained"
              color="primary"
              align="center"
              onClick={() => {
                props.addClick();
              }}
            >
              Add an Entry Now
            </YellowButton>
          </Box>

          <Typography align="center">
            You can also select a different date range to find more entries.
          </Typography>

          <Box
            component="div"
            maxWidth="xs"
            style={{ textAlign: "center", marginTop: 16, marginBottom: 16 }}
          >
            <YellowButton
              variant="contained"
              color="primary"
              align="center"
              onClick={() => {
                if (props.changeDate) {
                  props.changeDate();
                }
              }}
            >
              Change date range
            </YellowButton>
          </Box>
        </Box>
      </Container>
    );
  }
};

export default EmptyData;
