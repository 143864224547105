import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import speedIcon from "../../assets/Speed.svg";
import simplicityIcon from "../../assets/Simplicity.svg";
import utilityIcon from "../../assets/Utility.svg";
import ColorManager, { colorThemes } from "../Library/ColorManager";
const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const Focus = props => {
  return (
    <Container
      maxWidth={false}
      style={{
        textAlign: "center",
        alignContent: "center",
        marginTop: 54,
        paddingTop: isMobile ? 70 : 100,
        paddingBottom: 75,
        backgroundColor: "white",
        marginLeft: 0,
        paddingLeft: 0
      }}
    >
      <Container maxWidth="xs">
        <Typography
          style={{
            fontSize: isMobile ? 35 : 44,
            fontWeight: "bold",
            color: colors.secondaryBackground,
            textAlign: "center"
          }}
        >
          We are focused on three things
        </Typography>
      </Container>
      <Container>
        <Grid
          container
          spacing={6}
          alignItems="center"
          justifyContent="center"
          direction="row"
          style={{ marginTop: 24 }}
        >
          <Grid item xs>
            <img src={speedIcon} alt="Simple Log Logo" />
            <Typography
              variant="h6"
              style={{
                fontSize: 30,
                color: colors.primaryBackground,
                fontWeight: "500"
              }}
            >
              Speed
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 18,
                color: colors.primaryBackground,
                fontWeight: "normal"
              }}
            >
              SimpleLog3 is fast. You can add entries quickly, from your desktop
              or mobile phone. Updates are displayed across devics real-time.
            </Typography>
          </Grid>
          <Grid item xs>
            <img src={simplicityIcon} alt="Simple Log Logo" />
            <Typography
              variant="h6"
              style={{
                fontSize: 30,
                color: colors.primaryBackground,
                fontWeight: "500"
              }}
            >
              Simplicity
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 18,
                color: colors.primaryBackground,
                fontWeight: "normal"
              }}
            >
              SimpleLog3 is easy to use. A very simple design and concise
              feature set lets you stay focused on adding entries and reviewing
              your logs.
            </Typography>
          </Grid>
          <Grid item xs>
            <img src={utilityIcon} alt="Simple Log Logo" />
            <Typography
              variant="h6"
              style={{
                fontSize: 30,
                color: colors.primaryBackground,
                fontWeight: "500"
              }}
            >
              Utility
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 18,
                color: colors.primaryBackground,
                fontWeight: "normal"
              }}
            >
              SimpleLog3 has just what you need to stay productive. Create
              different logs for tracking all the events that are important to
              you.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="sm" style={{ paddingTop: 24 }}>
        <Typography
          style={{
            fontSize: isMobile ? 24 : 24,
            fontWeight: "bold",
            color: colors.secondaryBackground,
            textAlign: "center"
          }}
        >
          Customers are always #1. Contact us anytime for help, issues, or
          questions
        </Typography>
        <Typography
          style={{
            paddingTop: 24,
            fontSize: isMobile ? 24 : 24,
            fontWeight: "bold",
            color: colors.secondaryBackground,
            textAlign: "center"
          }}
        >
          <Link style={{ color: colors.secondaryBackground }} to="/contact">
            Contact us now
          </Link>
        </Typography>
      </Container>
    </Container>
  );
};

export default Focus;
