import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  Grid,
  Select,
  MenuItem
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import uuid from "react-native-uuid";

import { MuiThemeProvider } from "@material-ui/core/styles";
import YellowButton from "../Library/YellowButton";
import dialogTheme from "../Library/DialogTheme";
import colors from "../Library/Colors";
import { showFullSize } from "../Library/Helper";

// const underscore = require("underscore");

const QuickEntryEdit = props => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [fields, setFields] = useState([]);
  const [logs, setLogs] = useState([]);
  const [log, setLog] = useState("default");

  useEffect(() => {
    setOpen(props.open);
    setName(props.name);
    setTitle(props.title);
    setBody(props.body);
    setFields(props.fields);
    setLogs(props.logs);
    setLog(props.log);
  }, [
    props.open,
    props.name,
    props.title,
    props.body,
    props.fields,
    props.logs,
    props.log
  ]);

  const handleClose = () => {
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  // const addFieldWithName = name => {
  //   const newfields = fields;

  //   if (
  //     !underscore.find(newfields, f => {
  //       return f.fieldName === name;
  //     })
  //   ) {
  //     newfields.push({
  //       id: uuid.v4(),
  //       fieldName: name,
  //       fieldType: "string",
  //       friendlyName: name
  //     });
  //   }

  //   setFields([].concat(newfields));
  // };

  function isSmall() {
    return !showFullSize();
  }

  const handleAdd = () => {
    console.log("ADDING FIELD", fields);
    const newfields = fields;
    newfields.push({
      id: uuid.v4(),
      fieldName: `field${newfields.length + 1}`,
      fieldType: "string",
      friendlyName: ""
    });
    console.log("NEW FIELD", newfields);
    setFields([].concat(newfields));
  };

  const removeField = id => {
    console.log("REMOVING ==>", id);
    const newfields = fields;
    const filtered = newfields.filter(item => item.id !== id);
    setFields(filtered);
  };

  const handleSave = () => {
    if (props.didSave) {
      props.didSave({
        name: name,
        title: title,
        body: body,
        fields: fields,
        log: log
      });
    }
    setOpen(false);
  };

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        fullWidth={true}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.isEdit ? "Edit Template" : "Add Template"}
        </DialogTitle>
        <DialogContent>
          <Box style={{ marginBottom: 12 }}>
            <Typography variant="body1">Name your Template</Typography>
            <TextField
              size="small"
              fullWidth={true}
              variant="outlined"
              name="name"
              defaultValue={name}
              onChange={event => {
                setName(event.target.value);
              }}
              type="text"
              placeholder="Name your template"
            />
          </Box>

          <Box style={{ marginBottom: 12 }}>
            <Typography variant="body1">
              Enter the title for new entries
            </Typography>
            <TextField
              size="small"
              fullWidth={true}
              variant="outlined"
              name="title"
              defaultValue={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
              type="text"
              placeholder="New entries will have this title."
            />
          </Box>
          <Box style={{ marginBottom: 12 }}>
            <Typography variant="body1" style={{ marginBottom: 0 }}>
              Enter the body for new entries
            </Typography>
            <Typography
              variant="body2"
              style={{ fontSize: 12, marginTop: 0, marginBottom: 8 }}
            >
              Using field special formatting (e.g. {`{{field1}}`}) will add that
              field for you automatically.
            </Typography>
            <TextField
              variant="outlined"
              fullWidth={true}
              multiline={true}
              minRows={5}
              maxRows={5}
              name="body"
              type="text"
              placeholder="New entries will have this text."
              defaultValue={body}
              onChange={event => {
                // const bodyText = event.target.value;
                // const fields = bodyText.match(/{{[\w\s]+}}/gim);

                // const fieldNames = fields
                //   ? underscore.without(
                //       fields.map(f => {
                //         return f.replace("{{", "").replace("}}", "");
                //       }),
                //       "title",
                //       "date",
                //       "time",
                //       "datetime",
                //       "from_now"
                //     )
                //   : [];

                // console.log("fields: ", fieldNames, bodyText);

                // fieldNames.map(f => {
                //   addFieldWithName(f);
                //   return true;
                // });

                setBody(event.target.value);
              }}
            />
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <Typography variant="body1" style={{ marginBottom: 0 }}>
              Choose a log for new entries
            </Typography>
            <Select
              fullWidth={true}
              variant="outlined"
              value={log}
              onChange={event => {
                setLog(event.target.value);
              }}
            >
              <MenuItem value="default">My Log</MenuItem>
              {logs.map(l => {
                const data = l.data();
                if (!data.isVaulted) {
                  return (
                    <MenuItem key={data.id} value={data.id}>
                      {data.title}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </Select>
          </Box>
          <Box style={{ marginBottom: 12 }}>
            <Typography variant="body1" style={{ marginBottom: 0 }}>
              Fields
            </Typography>
            <Typography variant="body2" style={{ fontSize: 12, marginTop: 0 }}>
              Create field variables and use them anywhere in your template
              body. Use the field name in the body surrounded by braces. For
              example: {`{{field1}}`}. A field dialog will appear when you use
              this template. {`  `}
              <Link
                style={{ color: colors.entryPrimaryHighlight }}
                to={"./help/templates"}
              >
                See more about this feature.
              </Link>
            </Typography>
          </Box>
          <Box>
            <Grid container>
              <Grid
                container
                item
                style={{ marginBottom: 12 }}
                alignItems="center"
              >
                <Typography
                  style={{
                    fontSize: 14,
                    marginRight: isSmall() ? 5 : 12,
                    marginLeft: 4,
                    width: isSmall() ? 75 : 100
                  }}
                >
                  Field Name
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    marginRight: isSmall() ? 5 : 12,
                    width: isSmall() ? 100 : 250
                  }}
                >
                  Friendly Name
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    marginRight: isSmall() ? 5 : 12,
                    width: isSmall() ? 75 : 120
                  }}
                >
                  Field Type
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    marginRight: isSmall() ? 5 : 12
                  }}
                ></Typography>
              </Grid>
              {fields &&
                fields.map(f => {
                  return (
                    <Grid
                      container
                      key={f.id}
                      item
                      style={{ marginBottom: 8 }}
                      alignItems="center"
                    >
                      <TextField
                        style={{
                          marginRight: isSmall() ? 5 : 12,
                          width: isSmall() ? 75 : 100
                        }}
                        size="small"
                        fullWidth={false}
                        variant="outlined"
                        name={f.fieldName}
                        defaultValue={f.fieldName}
                        onChange={event => {
                          f.fieldName = event.target.value;
                        }}
                        type="text"
                        placeholder={f.fieldName}
                      />
                      <TextField
                        style={{
                          marginRight: isSmall() ? 5 : 12,
                          width: isSmall() ? 100 : 250
                        }}
                        size="small"
                        fullWidth={false}
                        variant="outlined"
                        name={f.friendlyName}
                        defaultValue={f.friendlyName}
                        onChange={event => {
                          f.friendlyName = event.target.value;
                        }}
                        type="text"
                        placeholder="Friendly name"
                      />
                      <Select
                        className="dataSelector"
                        defaultValue="string"
                        style={{
                          marginRight: isSmall() ? 5 : 12,
                          width: isSmall() ? 75 : 120
                        }}
                        fullWidth={false}
                        variant="outlined"
                        value={f.fieldType ? f.fieldType : "string"}
                        onChange={event => {
                          f.fieldType = event.target.value;

                          const newFields = [].concat(fields);
                          setFields(newFields);
                        }}
                      >
                        <MenuItem value="string">String</MenuItem>
                        <MenuItem value="number">Number</MenuItem>
                        <MenuItem value="multiline">Multiline</MenuItem>
                      </Select>

                      <CancelIcon
                        fontSize="medium"
                        style={{ marginLeft: isSmall() ? 5 : 12, marginTop: 8 }}
                        onClick={() => {
                          removeField(f.id);
                        }}
                      />
                    </Grid>
                  );
                })}

              <Grid container>
                <Button onClick={handleAdd} color="primary">
                  {fields.length > 0 ? `Add Another Field` : `Add a Field`}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions
          style={{
            marginRight: 24,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 16
          }}
        >
          <YellowButton
            style={{ width: isSmall() ? 120 : 200 }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </YellowButton>
          <YellowButton
            style={{ width: isSmall() ? 120 : 200 }}
            onClick={handleSave}
            color="primary"
          >
            Save
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default QuickEntryEdit;
