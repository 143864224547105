import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import colors from "../Library/Colors";

const YellowButton = withStyles(theme => ({
  root: {
    color: colors.yellowButtonBackground,
    backgroundColor: colors.yellowButtonBackground,
    fontWeight: "600",
    fontSize: colors.buttonFontSize,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: colors.yellowButtonBackgroundHighlight,
      color: colors.yellowButtonColorHighlight
    }
  },
  label: {
    color: colors.dialogBackground,
    justifyContent: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: colors.yellowButtonBackgroundHighlight,
      color: colors.yellowButtonColorHighlight
    }
  }
}))(Button);

export default YellowButton;
