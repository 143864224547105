import React from "react";
import {
  Container,
  Box,
  Typography,
  Snackbar,
  TextField,
  Grid
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Helmet } from "react-helmet";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { isMobile, isTablet } from "react-device-detect";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as ROUTES from "../../constants/routes";
import YellowButton from "../Library/LandingYellowButton";
import logo from "../../assets/signup_image.png";
import CookieConsent from "react-cookie-consent";
import firebaseApp from "../Firebase/firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

import ColorManager, { colorThemes } from "../Library/ColorManager";
import { showFullSize } from "../Library/Helper";
const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const simplelib = require("@bvelasquez/simple-log-lib");

const theme = createTheme({
  typography: {
    fontFamily: [colors.font].join(","),
    button: {
      textTransform: "none"
    }
  },
  palette: {
    primary: {
      main: colors.secondaryBackground
    },

    background: {
      default: colors.secondaryBackground
    }
  }
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  errorOpen: false,
  errorMessage: ""
};

export default class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    localStorage.removeItem("currentUserId");
    localStorage.removeItem("logId");
    firebaseApp.auth().signOut();
  }

  componentDidMount() {
    console.log("Mount - register");

    firebaseApp.analytics().logEvent("page_view", {
      page_location: "register",
      page_path: "/register",
      page_title: "Register"
    });
  }

  validateEmail = text => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      return false;
    }

    return true;
  };

  onSubmit = event => {
    const { email, password } = this.state;

    const history = this.props.history;
    const current = this;

    firebaseApp
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function() {
        return firebaseApp
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then(credentials => {
            firebaseApp.analytics().setUserId(credentials.user.uid);
            firebaseApp
              .analytics()
              .logEvent("sign_up", { method: "email address" });

            console.log("Registration successful", credentials);
            localStorage.setItem("currentUserId", credentials.user.uid);
            localStorage.setItem("email", credentials.user.email);

            simplelib.SetUserId(credentials.user.uid);
            simplelib.SetFirebase(firebaseApp);

            simplelib.FirebaseFunctions.saveAccount({
              updatedAt: new Date(),
              allowMarketing: true,
              allowCommunication: true
            });

            firebaseApp.auth().currentUser.sendEmailVerification();

            history.push(ROUTES.HOME);
          })
          .catch(reason => {
            console.log("Error registering", reason);
            current.setState({ errorOpen: true, errorMessage: reason.message });
          });
      })
      .catch(function(error) {
        // Handle Errors here.
        console.log("Error setting persistence.");
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = (event, reason) => {
    this.setState({ errorOpen: false });
  };

  getWidth = () => {
    let size = "md";
    if (!showFullSize()) {
      size = "xs";
    }
    return size;
  };

  render() {
    const { email, password } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>SimpleLog3 - Sign up for an account for free</title>
          <meta
            name="description"
            content="Need a journal, log, or diary? SimpleLog3, the last log you'll ever need. Journal, Diary, Logging made simple. Sign up now for free."
          />
        </Helmet>
        <CssBaseline />
        <Container
          maxWidth={this.getWidth()}
          style={{ marginTop: 54, marginBottom: 12, paddingBottom: 24 }}
        >
          <Container maxWidth={this.getWidth()}>
            <Grid container>
              {showFullSize() && (
                <Grid
                  item
                  style={{
                    backgroundColor: colors.primaryBackground,
                    padding: isMobile ? 24 : 60,
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12
                  }}
                  sm={6}
                >
                  <img
                    style={{ width: isTablet ? 300 : 350 }}
                    src={logo}
                    alt="Simple Log Logo"
                  />
                </Grid>
              )}

              <Grid
                item
                style={{
                  backgroundColor: "white",
                  padding: isMobile ? 24 : 60,
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                  borderTopLeftRadius: isMobile ? 12 : 0,
                  borderBottomLeftRadius: isMobile ? 12 : 0
                }}
                sm={6}
              >
                <Typography
                  style={{
                    color: colors.secondaryBackground,
                    fontSize: 60,
                    fontWeight: "bold",
                    marginBottom: isMobile ? 42 : 113
                  }}
                  align="center"
                >
                  Sign up
                </Typography>
                <form onSubmit={this.onSubmit}>
                  <Box style={{ marginBottom: 20 }}>
                    <TextField
                      required={true}
                      fullWidth={true}
                      variant="outlined"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      type="email"
                      placeholder="Email address"
                      autoComplete="username"
                    />
                  </Box>
                  <Box style={{ marginBottom: 20 }}>
                    <TextField
                      required={true}
                      fullWidth={true}
                      variant="outlined"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                    />
                  </Box>

                  <Box
                    style={{
                      width: "100%",
                      marginTop: 20,
                      marginBottom: 20,
                      textAlign: "center"
                    }}
                  >
                    <YellowButton
                      variant="contained"
                      type="submit"
                      style={{ width: 220 }}
                    >
                      Sign up
                    </YellowButton>
                  </Box>
                </form>

                <Box
                  style={{
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 20,
                    textAlign: "center"
                  }}
                >
                  <Typography
                    style={{ color: colors.secondaryBackground, fontSize: 14 }}
                  >
                    By signing up, you agree to our{" "}
                    <Link
                      style={{
                        color: colors.secondaryBackground,
                        fontSize: isMobile ? 12 : 14
                      }}
                      to="/help/terms"
                    >
                      Terms of Service
                    </Link>
                  </Typography>
                </Box>

                <Box
                  style={{
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 20,
                    textAlign: "center"
                  }}
                >
                  <Link
                    style={{
                      color: colors.secondaryBackground,
                      fontSize: isMobile ? 12 : 14,
                      fontWeight: "500"
                    }}
                    to={ROUTES.SIGN_IN}
                  >
                    Already have an account? Sign in.
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>

          <Snackbar
            autoHideDuration={5000}
            open={this.state.errorOpen}
            onClose={this.handleErrorClose}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.errorMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />

          <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="simpleLogCookieAgree"
            style={{ background: colors.secondaryBackgroundBackground }}
            buttonStyle={{
              color: colors.secondaryBackground,
              fontSize: "13px",
              backgroundColor: colors.primaryHighlight,
              borderRadius: 8
            }}
            expires={150}
          >
            This website uses cookies to enhance the user experience. You can
            read our{" "}
            <a style={{ color: "white" }} href="./help/privacy">
              privacy policy
            </a>{" "}
            and{" "}
            <a style={{ color: "white" }} href="./help/terms">
              terms of service
            </a>{" "}
            here. Tap OK if you agree.
          </CookieConsent>
        </Container>
      </MuiThemeProvider>
    );
  }
}
