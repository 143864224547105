import React from "react";
import { Typography, Box, Container } from "@material-ui/core";

const LogHelp = props => {
  return (
    <Container maxWidth="md" style={{ height: "80%", marginTop: 25 }}>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">Logs</Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          What are logs?
        </Typography>
        <Typography gutterBottom variant="body1">
          With a premium account, you may have any number of Logs to organize
          your entries. The default log "My Log" is free to all users. Need to
          track your workouts? Make a "Fitness" log. Need to keep track of
          customer visits? Make a "Customer" log.
        </Typography>
        <Typography gutterBottom variant="body1">
          Logs keep all of their entries organized. You can still search all of
          your logs using the search bar and find exactly what you are looking
          for. Logs can also be made public and shared with others. People you
          share with will see updates to your log instantly and in real-time.
        </Typography>
        <Typography gutterBottom variant="h5" style={{ marginTop: 54 }}>
          Ideas for Logs
        </Typography>
        <Container>
          <Typography variant="h6">Daily Reflections</Typography>
          <Typography variant="body1" style={{ marginBottom: 24 }}>
            Consider what you did that day. Write it down and then go back often
            and reflect and learn from your own experienes.
          </Typography>

          <Typography variant="h6">Mileage</Typography>
          <Typography variant="body1" style={{ marginBottom: 24 }}>
            Tracking milage for your business made easy. Use a Template to
            easily enter the miles travelled for a trip and then see a chart of
            those miles over time.
          </Typography>

          <Typography variant="h6">Health and Fitness</Typography>
          <Typography variant="body1" style={{ marginBottom: 24 }}>
            Keep track of all your health related events. Workouts, medications
            taken, well-being. Review your log monthly to see trends or changes.
          </Typography>

          <Typography variant="h6">Vacations and Trips</Typography>
          <Typography variant="body1" style={{ marginBottom: 24 }}>
            Make a unique log when you go on vacation to keep track of those
            special events.
          </Typography>

          <Typography variant="h6">Client Calls</Typography>
          <Typography variant="body1" style={{ marginBottom: 24 }}>
            Keep track of customer calls. Use a template to record the time,
            reason for the call, and any other notes. Easily retrieve them
            later.
          </Typography>
        </Container>
      </Box>
    </Container>
  );
};

export default LogHelp;
