import { createTheme } from "@material-ui/core/styles";
import colors from "../Library/Colors";

const filterDialogTheme = createTheme({
  typography: {
    fontFamily: [colors.font].join(","),
    body1: {
      fontSize: colors.dialogMainTitleFontSize,
      color: colors.dialogTitle,
      marginBottom: 2
    },
    subtitle1: {
      fontSize: 24,
      color: colors.dialogTitle,
      fontWeight: "500"
    },
    h6: {
      fontSize: colors.dialogTitleFontSize,
      fontWeight: "700"
    }
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: colors.primaryText
      }
    },
    MuiList: {
      root: {
        backgroundColor: colors.dialogBackground
      }
    },
    MuiListItem: {
      root: {
        color: colors.primaryText
      }
    },
    MuiListSubheader: {
      root: {
        color: colors.primaryText
      }
    },

    MuiMenuItem: {
      root: {
        color: colors.primaryText
      }
    },

    MuiButton: {
      root: {
        padding: 12
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: 16
      }
    },
    MuiDialogTitle: {
      root: {
        color: colors.dialogTitle,
        fontSize: colors.dialogTitleFontSize,
        fontWeight: "700",
        marginBottom: 0,
        paddingBottom: 5
      }
    },
    MuiSelect: {
      outlined: {
        borderRadius: 12
      },
      select: {
        "&:focus": {
          background: "$labelcolor"
        }
      },
      selectMenu: {
        paddingTop: 20,
        paddingBottom: 20
      }
    },
    MuiInputBase: {
      root: {
        background: colors.dialogTitle
      },
      input: {
        color: colors.primaryText,
        background: colors.dialogTitle
      }
    },
    MuiOutlinedInput: {
      root: {
        border: 1,
        borderColor: colors.primaryText,
        borderRadius: 12,
        background: colors.dialogTitle
      },
      multiline: {
        padding: 8
      },
      inputMultiline: {
        padding: 8
      },
      input: {
        border: 1,
        borderColor: colors.primaryText,
        borderRadius: 12,
        fontSize: 22
      }
    }
  }
});

export default filterDialogTheme;
