import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  TextField
} from "@material-ui/core";

import { MuiThemeProvider } from "@material-ui/core/styles";

import dialogTheme from "../Library/DialogTheme";
import YellowButton from "../Library/YellowButton";

import { getCurrentUserId } from "../Library/Helper";

var crypto = require("crypto");

const PasswordEntry = props => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setPassword("");
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  const handleGo = async () => {
    const holdPassword = password;
    setPassword("");
    setOpen(false);

    var currentUserId = getCurrentUserId();

    var hash;

    hash = crypto
      .createHash("sha256")
      .update(holdPassword + "_" + currentUserId)
      .digest("base64");

    props.didSelect(hash);
  };

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        fullWidth={false}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEnforceFocus={true}
      >
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <Box style={{ marginBottom: 0 }}>
            <TextField
              autoComplete="off"
              size="small"
              fullWidth={true}
              variant="outlined"
              name="password"
              defaultValue={password}
              onChange={event => {
                setPassword(event.target.value);
              }}
              type="password"
              placeholder=""
            />
          </Box>
        </DialogContent>

        <DialogActions
          style={{
            marginTop: 24,
            marginBottom: 24
          }}
        >
          <YellowButton
            style={{ width: 150 }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </YellowButton>
          <YellowButton
            style={{ width: 150 }}
            onClick={handleGo}
            color="primary"
          >
            OK
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default PasswordEntry;
