import { createTheme } from "@material-ui/core/styles";
import colors from "../Library/Colors";

const dialogTheme = createTheme({
  typography: {
    fontFamily: [colors.font].join(","),
    body1: {
      fontSize: colors.dialogMainTitleFontSize,
      color: colors.dialogTitle,
      marginBottom: 2,
      whiteSpace: "pre-wrap",
      lineBreak: "normal",
      "& a": {
        lineBreak: "anywhere",
        color: colors.primaryHighlight
      }
    },
    body2: {
      color: colors.dialogTitle,
      primary: {
        color: colors.dialogTitle
      }
    },
    subtitle1: {
      fontSize: 24,
      color: colors.dialogTitle,
      fontWeight: "500"
    },
    h1: {
      color: colors.primaryHighlight,
      fontWeight: 700
    },
    h2: {
      color: colors.primaryHighlight,
      fontWeight: 700
    },
    h3: {
      color: colors.primaryHighlight,
      fontWeight: 700
    },
    h4: {
      color: colors.primaryHighlight,
      fontWeight: 700
    },
    h5: {
      color: colors.primaryHighlight,
      fontWeight: 700
    },
    h6: {
      color: colors.primaryHighlight,
      fontWeight: 700,
      fontSize: colors.primaryH6FontSize
    }
    // h6: {
    //   fontSize: colors.dialogTitleFontSize,
    //   fontWeight: "700"
    // }
  },
  overrides: {
    MuiTypography: {
      root: {
        "& a": {
          color: colors.dialogTitle
        }
      }
    },
    MuiPaper: {
      root: {
        "& .react-datepicker-wrapper": {
          backgroundColor: colors.dialogBackground
        },
        "& .react-datepicker__input-container": {
          backgroundColor: colors.dialogBackground
        },
        "& .react-datepicker__input-container input": {
          fontSize: colors.dialogEditFontSize,
          fontFamily: colors.font,
          padding: 10,
          width: "100%",
          backgroundColor: colors.secondaryBackground,
          color: colors.dialogPrimaryText,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "gray",
          borderRadius: 12
        },
        "& .react-datepicker__input-container input:focus": {
          outline: "none",
          outlineColor: "black",
          outlineWidth: 1,
          fontSize: colors.dialogEditFontSize,
          fontFamily: colors.font,
          padding: 10,
          width: "100%",
          backgroundColor: colors.secondaryBackground,
          color: colors.dialogPrimaryText,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "gray",
          borderRadius: 12
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fill: colors.dialogPrimaryText
      }
    },
    MuiList: {
      root: {
        backgroundColor: colors.secondaryBackground
      }
    },

    MuiListSubheader: {
      root: {
        color: colors.primaryText
      }
    },

    MuiMenuItem: {
      root: {
        color: colors.dialogPrimaryText
      }
    },

    MuiButton: {
      root: {
        padding: 12
      },
      label: {
        color: colors.primaryHighlight
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: 16,
        backgroundColor: colors.dialogBackground,
        "@media print": {
          overflow: "hidden",
          height: "100%",
          pageBreakAfter: "always",
          pageBreakBefore: "avoid !important"
        }
      }
    },
    MuiDialogTitle: {
      root: {
        color: colors.dialogTitle,
        fontSize: colors.dialogTitleFontSize,
        fontWeight: "700",
        marginBottom: 0,
        paddingBottom: 5
      }
    },
    MuiSelect: {
      outlined: {
        borderRadius: 12
      },
      select: {
        "&:focus": {
          background: "$labelcolor"
        }
      },
      selectMenu: {
        paddingTop: 8,
        paddingBottom: 8
      }
    },
    MuiInputBase: {
      root: {
        background: colors.secondaryBackground
      },
      input: {
        color: colors.dialogPrimaryText,
        background: colors.secondaryBackground,
        fontSize: colors.dialogEditFontSize
      }
    },
    MuiOutlinedInput: {
      root: {
        border: 1,
        borderColor: colors.primaryText,
        borderRadius: 12,
        background: colors.secondaryBackground
      },
      multiline: {
        padding: 7
      },
      inputMultiline: {
        padding: 7
      },
      input: {
        border: 1,
        borderColor: colors.primaryText,
        borderRadius: 12,
        fontSize: colors.dialogEditFontSize
      }
    }
  }
});

export default dialogTheme;
