const StripeConstants = {
  productLiveKey: "prod_Fmqk6e2dl6en9M",
  productTestKey: "prod_Gn63z1BoAnZ4Z0",
  apiTestKey: "pk_test_WZnF4Y9IWoZ1aDH7HitM44ch00gqSgotQQ",
  apiLiveKey: "pk_live_7IN4eZg4oMKfkw5fMVOgyIaR00h82KweSl"
};

const currentKeys = {
  currentProductKey: StripeConstants.productLiveKey,
  currentAPIKey: StripeConstants.apiLiveKey
};

export default currentKeys;
