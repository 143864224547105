export const colorThemes = {
  midnightFireTheme: "midnightFireTheme",
  daylightFireTheme: "daylightFireTheme",
  midnightFlameTheme: "midnightFlameTheme",
  blueIceTheme: "blueIceTheme",
  purpleRainTheme: "purpleRainTheme",
  yellowMellowTheme: "yellowMellowTheme"
};

const themeDefinitions = {
  midnightFireTheme: {
    primaryBackground: "#141414",
    secondaryBackground: "#232323",

    primaryHighlight: "#FFBE6B",
    primaryText: "#D6D6D6",
    secondaryText: "#C9C9C9",
    landingAccentColor: "#FFF9F0",

    appHeaderColor: "#232323",
    appHeaderTitleColor: "#D6D6D6",

    primaryBody1FontSize: 16,
    primaryBody2FontSize: 14,
    primaryH6FontSize: 18,

    logSelectionFontSize: 16,
    searchBarFontSize: 16,
    dateRangeFontSize: 16,
    primaryIconSize: 40,
    secondaryIconSize: 32,

    entryPrimaryHighlight: "#FFBE6B",
    entryPrimaryText: "#D6D6D6",
    entrySecondaryText: "#C9C9C9",
    entryPrimaryFontSize: 14,
    entryTitleFontSize: 20,
    entryLogNameFontSize: 16,

    dialogTitle: "#232323",
    dialogBackground: "#FFFFFF",
    dialogPrimaryText: "#D6D6D6",
    dialogTitleFontSize: 24,
    dialogMainTitleFontSize: 16,
    dialogEditFontSize: 16,

    yellowButtonBackground: "#232323",
    yellowButtonColor: "#D6D6D6",
    yellowButtonBackgroundHighlight: "#232323",
    yellowButtonColorHighlight: "#FFBE6B",

    featureDescriptionFontSize: 12,
    menuFontSize: 16,
    buttonFontSize: 16,

    font: "Poppins"
  },
  daylightFireTheme: {
    name: "Daylight Fire",

    primaryBackground: "#141414",
    secondaryBackground: "#fcfcfc",

    primaryHighlight: "#f8a600",
    entryPrimaryHighlight: "#f8a600",

    primaryText: "#fcfcfc",
    entryPrimaryText: "#232323",

    secondaryText: "#232323",
    entrySecondaryText: "#232323",

    landingAccentColor: "#FFF9F0",
    logNameColor: "#fcfcfc",
    dateRangeColor: "#fcfcfc",

    appHeaderSize: 20,
    primaryHeaderColor: "#0A0A0A",
    appHeaderColor: "#232323",
    appHeaderTitleColor: "#fcfcfc",

    primaryBody1FontSize: 16,
    primaryBody2FontSize: 14,
    primaryH6FontSize: 18,

    logSelectionFontSize: 18,
    searchBarFontSize: 16,
    dateRangeFontSize: 16,
    primaryIconSize: 40,
    secondaryIconSize: 28,

    entryPrimaryFontSize: 16,
    entryTitleFontSize: 24,
    entryLogNameFontSize: 18,
    entryIconFontSize: 18,

    dialogTitle: "#232323",
    dialogMenu: "#fcfcfc",
    dialogBackground: "#e8e8e8",
    dialogPrimaryText: "#232323",
    dialogTitleFontSize: 24,
    dialogMainTitleFontSize: 18,
    dialogEditFontSize: 16,
    dialogButtonFontSize: 12,
    dialogInformationFontSize: 12,

    yellowButtonBackground: "#232323",
    yellowButtonColor: "#D6D6D6",
    yellowButtonBackgroundHighlight: "#232323",
    yellowButtonColorHighlight: "#f8a600",

    featureDescriptionFontSize: 12,
    menuFontSize: 16,
    buttonFontSize: 16,

    FABColor: "#000000",
    FABItemBackground: "#fcfcfc",
    FABItemForeground: "#000000",

    font: "Poppins"
  },

  midnightFlameTheme: {
    primaryBackground: "#141414",
    secondaryBackground: "#232323",

    primaryHighlight: "#F2312E",
    entryPrimaryHighlight: "#F2312E",

    primaryText: "#D6D6D6",
    entryPrimaryText: "#D6D6D6",

    secondaryText: "#C9C9C9",
    entrySecondaryText: "#C9C9C9",

    dialogTitle: "#232323",
    dialogBackground: "#FFFFFF",
    dialogPrimaryText: "#D6D6D6",

    landingAccentColor: "#FFF9F0",

    appHeaderColor: "#232323",
    appHeaderTitleColor: "#D6D6D6",

    primaryBody1FontSize: 16,
    primaryBody2FontSize: 14,
    primaryH6FontSize: 18,

    logSelectionFontSize: 16,
    searchBarFontSize: 16,
    dateRangeFontSize: 16,
    primaryIconSize: 40,
    secondaryIconSize: 32,

    entryPrimaryFontSize: 16,
    entryTitleFontSize: 24,
    entryLogNameFontSize: 18,

    dialogTitleFontSize: 24,
    dialogMainTitleFontSize: 16,
    dialogEditFontSize: 16,

    yellowButtonBackground: "#232323",
    yellowButtonColor: "#D6D6D6",
    yellowButtonBackgroundHighlight: "#232323",
    yellowButtonColorHighlight: "#F2312E",

    featureDescriptionFontSize: 12,
    menuFontSize: 16,
    buttonFontSize: 16,

    font: "Roboto"
  },

  blueIceTheme: {
    primaryBackground: "#D6D6D6",
    secondaryBackground: "#232323",

    primaryHighlight: "#0077b6",
    entryPrimaryHighlight: "#0077b6",

    primaryText: "#232323",
    entryPrimaryText: "#D6D6D6",

    secondaryText: "#C9C9C9",
    entrySecondaryText: "#D6D6D6",

    dialogTitle: "#232323",
    dialogBackground: "#F5F5F5",
    dialogPrimaryText: "#D6D6D6",

    landingAccentColor: "#FFF9F0",

    appHeaderColor: "#232323",
    appHeaderTitleColor: "#D6D6D6",

    primaryBody1FontSize: 16,
    primaryBody2FontSize: 14,
    primaryH6FontSize: 18,

    logSelectionFontSize: 16,
    searchBarFontSize: 16,
    dateRangeFontSize: 18,
    primaryIconSize: 40,
    secondaryIconSize: 32,

    entryPrimaryFontSize: 16,
    entryTitleFontSize: 24,
    entryLogNameFontSize: 18,

    dialogTitleFontSize: 24,
    dialogMainTitleFontSize: 16,
    dialogEditFontSize: 16,

    yellowButtonBackground: "#0077b6",
    yellowButtonColor: "#D6D6D6",
    yellowButtonBackgroundHighlight: "#232323",
    yellowButtonColorHighlight: "#D6D6D6",

    featureDescriptionFontSize: 12,
    menuFontSize: 16,
    buttonFontSize: 16,

    font: "Helvetica"
  },

  purpleRainTheme: {
    name: "Purple Rain",
    primaryBackground: "#493f78",
    secondaryBackground: "#7d7abc",

    primaryHighlight: "#f9e9ec",
    entryPrimaryHighlight: "#f9e9ec",

    primaryText: "#D6D6D6",
    entryPrimaryText: "#fcfcfc",

    secondaryText: "#fef4eb",
    entrySecondaryText: "#D6D6D6",

    landingAccentColor: "#FFF9F0",

    appHeaderSize: 20,
    primaryHeaderColor: "#493f78",
    appHeaderColor: "#312a50",
    appHeaderTitleColor: "#fcfcfc",

    primaryBody1FontSize: 16,
    primaryBody2FontSize: 14,
    primaryH6FontSize: 18,

    logSelectionFontSize: 20,
    searchBarFontSize: 16,
    dateRangeFontSize: 18,
    primaryIconSize: 40,
    secondaryIconSize: 28,

    entryPrimaryFontSize: 18,
    entryTitleFontSize: 24,
    entryLogNameFontSize: 18,
    entryIconFontSize: 20,

    dialogTitle: "#7d7abc",
    dialogBackground: "#FFFFFF",
    dialogPrimaryText: "#fcfcfc",
    dialogTitleFontSize: 24,
    dialogMainTitleFontSize: 18,
    dialogEditFontSize: 18,
    dialogButtonFontSize: 12,
    dialogInformationFontSize: 13,

    yellowButtonBackground: "#7d7abc",
    yellowButtonColor: "#D6D6D6",
    yellowButtonBackgroundHighlight: "#7d7abc",
    yellowButtonColorHighlight: "#493f78",

    featureDescriptionFontSize: 12,
    menuFontSize: 16,
    buttonFontSize: 16,

    FABColor: "#000000",
    FABItemBackground: "#FFFFFF",
    FABItemForeground: "#000000",

    font: "Times New Roman"
  },

  yellowMellowTheme: {
    name: "Yellow Mellow",
    primaryBackground: "#f2f2f4",
    secondaryBackground: "#fffeff",

    primaryHighlight: "#ffcd2a",
    primaryText: "#171715",
    secondaryText: "#171715",
    landingAccentColor: "#FFF9F0",
    logNameColor: "#171715",
    dateRangeColor: "#171715",

    appHeaderSize: 20,
    appHeaderTitleColor: "#f2f2f4",
    primaryHeaderColor: "#f2f2f4",
    appHeaderColor: "#ffcd2a",

    primaryBody1FontSize: 18,
    primaryBody2FontSize: 16,
    primaryH6FontSize: 18,

    logSelectionFontSize: 20,
    searchBarFontSize: 16,
    dateRangeFontSize: 15,
    primaryIconSize: 40,
    secondaryIconSize: 28,

    entryPrimaryText: "#171715",
    entrySecondaryText: "#171715",
    entryPrimaryHighlight: "#f8a600",
    entryPrimaryFontSize: 20,
    entryTitleFontSize: 24,
    entryLogNameFontSize: 18,
    entryIconFontSize: 22,

    dialogMenu: "#171715",
    dialogBackground: "#f2f2f4",
    dialogPrimaryText: "#171715",
    dialogTitleFontSize: 24,
    dialogMainTitleFontSize: 18,
    dialogEditFontSize: 18,
    dialogButtonFontSize: 12,
    dialogInformationFontSize: 13,

    yellowButtonBackground: "#ffcd2a",
    yellowButtonColor: "#D6D6D6",
    yellowButtonBackgroundHighlight: "#ffcd2a",
    yellowButtonColorHighlight: "#171715",

    featureDescriptionFontSize: 12,

    menuFontSize: 16,
    buttonFontSize: 16,

    FABColor: "#000000",
    FABItemBackground: "#FFFFFF",
    FABItemForeground: "#000000",

    font: "Poppins"
  }
};

export const ColorThemeNames = [
  { theme: colorThemes.midnightFireTheme, name: "Midnight Fire" },
  { theme: colorThemes.daylightFireTheme, name: "Daylight Fire" },
  { theme: colorThemes.midnightFlameTheme, name: "Midnight Flame" },
  { theme: colorThemes.blueIceTheme, name: "Blue Ice" },
  { theme: colorThemes.purpleRainTheme, name: "Purple Rain" },
  { theme: colorThemes.yellowMellowTheme, name: "Yellow Mellow" }
];

const getColorTheme = theme => {
  const foundTheme = themeDefinitions[theme];
  if (foundTheme) {
    return foundTheme;
  }
  return themeDefinitions.midnightFireTheme;
};

const ColorManager = {
  setColorTheme: theme => {
    localStorage.setItem("theme", theme);
  },
  getSetColorTheme: () => {
    if (!localStorage) {
      return getColorTheme(colorThemes.midnightFireTheme);
    }
    try {
      const theme = localStorage.getItem("theme");
      if (theme) {
        return getColorTheme(theme);
      } else {
        return getColorTheme(colorThemes.midnightFireTheme);
      }
    } catch (e) {
      return getColorTheme(colorThemes.midnightFireTheme);
    }
  },
  getColorTheme: theme => {
    return getColorTheme(theme);
  }
};

export default ColorManager;
