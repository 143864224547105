import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { CSVLink } from "react-csv";
import moment from "moment";

const CSVExportDialog = props => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [headers, setHeaders] = React.useState([]);

  React.useEffect(() => {
    setOpen(props.open);
    setData(props.data);
    setHeaders(props.headers);
  }, [props.open, props.data, props.headers]);

  const handleClose = () => {
    props.handleClose();
    setOpen(false);
  };

  return (
    <Dialog open={open} disableBackdropClick={true} onClose={handleClose}>
      <DialogTitle>CSV Export</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Click the link below to download your CSV file.
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <DialogContentText>
          <CSVLink
            data={data}
            headers={headers}
            filename={`simplelog_${moment().format()}.csv`}
          >
            Click here to Download
          </CSVLink>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CSVExportDialog;
