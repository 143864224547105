import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { MuiThemeProvider } from "@material-ui/core/styles";
import YellowButton from "../Library/YellowButton";
import dialogTheme from "../Library/DialogTheme";

import { getCurrentUserId } from "../Library/Helper";

import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 24);

var crypto = require("crypto");
// const nanoid = require("nanoid");

const LogEdit = props => {
  const [open, setOpen] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [vaultPassword, setVaultPassword] = useState("");
  const [allowWriteAPIAccess, setAllowWriteAPIAcces] = useState(false);
  const [allowReadAPIAccess, setAllowReadAPIAcces] = useState(false);
  const [enableWebhook, setEnableWebhook] = useState(false);
  const [webhookToken, setWebhookToken] = useState("");
  const [webhookPasscode, setWebhookPasscode] = useState("");
  const [isVaulted, setIsVaulted] = useState(false);
  const [isAdd, setIsAdd] = useState(true);

  useEffect(() => {
    setOpen(props.open);
    setTitle(props.editLog.title);
    setBody(props.editLog.body);
    setAllowWriteAPIAcces(props.editLog.allowWriteAPIAccess);
    setAllowReadAPIAcces(props.editLog.allowReadAPIAccess);
    setEnableWebhook(props.editLog.enableWebhook);
    setWebhookPasscode(props.editLog.webhookPasscode);

    var currentUserId = getCurrentUserId();
    if (props.editLog.enableWebhook) {
      const hash = `${props.editLog.id}_${currentUserId}_${props.editLog.webhookPasscode}`;
      setWebhookToken(hash);
    } else {
      setWebhookToken("");
      setWebhookPasscode("");
    }

    setIsVaulted(props.editLog.isVaulted);
    setIsAdd(props.isAdd);
  }, [props.open, props.editLog, props.isAdd]);

  const handleClose = () => {
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  const handleSave = () => {
    if (title.length > 0 || body.length > 0) {
      if (props.isAdd && isVaulted && vaultPassword.length < 10) {
        setTitleError(true);
        return;
      }

      var currentUserId = getCurrentUserId();

      var hash;
      if (isVaulted && vaultPassword) {
        hash = crypto
          .createHash("sha256")
          .update(vaultPassword + "_" + currentUserId)
          .digest("base64");
      }

      if (props.didSave) {
        props.didSave({
          id: props.editLog.id,
          title: title,
          body: body,
          allowWriteAPIAccess: allowWriteAPIAccess,
          allowReadAPIAccess: allowReadAPIAccess,
          enableWebhook: enableWebhook,
          webhookPasscode: webhookPasscode,
          isVaulted: isVaulted,
          vaultPassword: isVaulted ? hash : null
        });
      }
      setOpen(false);
    } else {
      setTitleError(true);
    }
  };

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        fullWidth={true}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.isAdd ? "Add Log" : "Edit Log"}
        </DialogTitle>
        <DialogContent>
          <Box style={{ marginBottom: 20 }}>
            <Typography variant="body1">Name your log</Typography>
            <TextField
              disabled={props.editLog.id === "default" ? true : false}
              autoComplete="off"
              error={titleError}
              size="small"
              fullWidth={true}
              variant="outlined"
              name="title"
              defaultValue={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
              type="text"
              placeholder="Give your new log a title."
            />
          </Box>
          <Box style={{ marginBottom: 12 }}>
            <TextField
              disabled={props.editLog.id === "default" ? true : false}
              autoComplete="off"
              variant="outlined"
              fullWidth={true}
              multiline={true}
              minRows={5}
              maxRows={5}
              name="body"
              type="text"
              placeholder="Describe what this log will be used for."
              defaultValue={body}
              onChange={event => {
                setBody(event.target.value);
              }}
            />
          </Box>
        </DialogContent>

        {isAdd && props.editLog.id !== "default" && (
          <DialogContent>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Security settings</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Typography style={{ fontSize: 12 }}>
                  A vaulted log provides an additional level of security in
                  addition to SSL. Logs are encrypted end-to-end on the client
                  and the password is never stored by SimpleLog3. Entries cannot
                  be decrypted by us and if you forget the password, entries
                  cannot be recovered.
                </Typography>
              </AccordionDetails>
              <AccordionDetails style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Typography>
                  Turn on Vault
                  <Checkbox
                    color="secondary"
                    checked={isVaulted}
                    onChange={event => {
                      //
                      if (event.target.checked) {
                        setAllowReadAPIAcces(false);
                        setAllowWriteAPIAcces(false);
                      }
                      setIsVaulted(event.target.checked);
                    }}
                  />
                </Typography>
              </AccordionDetails>
              <AccordionDetails style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Typography variant="body1">
                  Password (10 character min.)
                </Typography>
              </AccordionDetails>
              <AccordionDetails style={{}}>
                <TextField
                  disabled={!isVaulted}
                  autoComplete="off"
                  error={titleError}
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  name="title"
                  defaultValue={title}
                  onChange={event => {
                    setVaultPassword(event.target.value);
                  }}
                  type="text"
                  placeholder=""
                />
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        )}

        {!isVaulted && (
          <DialogContent>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>API settings</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Typography>
                  Write API Access
                  <Checkbox
                    disabled={isVaulted}
                    color="secondary"
                    checked={allowWriteAPIAccess}
                    onChange={event => {
                      //
                      setAllowWriteAPIAcces(event.target.checked);
                    }}
                  />
                </Typography>
                <Typography style={{ marginLeft: 12 }}>
                  Read API Access
                  <Checkbox
                    disabled={isVaulted}
                    color="secondary"
                    checked={allowReadAPIAccess}
                    onChange={event => {
                      //
                      setAllowReadAPIAcces(event.target.checked);
                    }}
                  />
                </Typography>
                <Typography style={{ marginLeft: 12 }}>
                  Enable Webhook
                  <Checkbox
                    disabled={isVaulted}
                    color="secondary"
                    checked={enableWebhook}
                    onChange={event => {
                      //
                      setEnableWebhook(event.target.checked);

                      var currentUserId = getCurrentUserId();
                      var webhookPasscode = nanoid();
                      if (event.target.checked) {
                        const hash = `${props.editLog.id}_${currentUserId}_${webhookPasscode}`;
                        setWebhookToken(hash);
                        setWebhookPasscode(webhookPasscode);
                      } else {
                        setWebhookToken("");
                        setWebhookPasscode(null);
                      }
                    }}
                  />
                </Typography>
              </AccordionDetails>
              {enableWebhook && (
                <AccordionDetails style={{ marginTop: 0, paddingTop: 0 }}>
                  <Box>
                    <Typography>Webhook URL</Typography>

                    <Typography style={{ fontSize: 13 }}>
                      Webhooks provide a simple way to integrate with your log.
                      No authentication is required, but if you share this URL,
                      anyone can post to your log.
                    </Typography>

                    <Typography
                      style={{
                        fontSize: 12,
                        fontWeight: "500",
                        marginTop: 8,
                        lineBreak: "anywhere"
                      }}
                    >{`${window.location.origin.toString()}/api/v1/webhooks/${webhookToken}`}</Typography>
                  </Box>
                </AccordionDetails>
              )}

              <AccordionDetails style={{ marginTop: 0, paddingTop: 0 }}>
                <Typography style={{ fontSize: 12 }}>
                  <Link to="/help/api">API Documentation</Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        )}

        <DialogActions
          style={{
            marginRight: 24,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 45
          }}
        >
          <YellowButton
            style={{ width: isMobile ? 120 : 200 }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </YellowButton>
          <YellowButton
            style={{ width: isMobile ? 120 : 200 }}
            onClick={handleSave}
            color="primary"
          >
            Save
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default LogEdit;
