import React from "react";
import { Typography, Box, Container } from "@material-ui/core";

const APIHelp = props => {
  return (
    <Container maxWidth="md" style={{ height: "80%", marginTop: 25 }}>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">API Documentation</Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "left" }}>
        <Typography variant="body1" style={{ marginBottom: 12 }}>
          The SimpleLog3 API allows you to post one entry every 30 seconds (by
          default) to a secondary log (any log you create that is not the
          default log). The API is ONLY available to subscribers and will be
          expanded in the future to include more options.
        </Typography>

        <Typography variant="body1" style={{ marginBottom: 24 }}>
          The API methods are shown below. Replace the log_id with the ID of the
          log you want to post an entry into. This cannot be the 'default' log.
          The log_id is visible for each Log within the Manage Logs page.
        </Typography>
        <hr />
        <Typography variant="h5" style={{ marginBottom: 12 }}>
          Retrieve Entries from a Log
        </Typography>
        <Typography variant="h6" style={{ marginBottom: 12 }}>
          API Path
        </Typography>
        <code>{`GET : https://simplelog3.com/api/v1/log/<log_id>?keyword={search_word}`}</code>
        <Typography variant="h6" style={{ marginBottom: 12, marginTop: 12 }}>
          API Headers
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
          You must pass specific headers with your GET request.
        </Typography>
        <code>{`x-api-token: <token provided from account and settings>`}</code>
        <br />
        <code>{`x-api-passphrase: <passphrase provided when you enable API access.>`}</code>
        <br />

        <Typography variant="h6" style={{ marginBottom: 12, marginTop: 12 }}>
          JSON Body Response
        </Typography>
        <pre>
          <code>
            {`
[
  {
    "id": "xyz",
    "log": "xyz",
    "templateId": "xyz",
    "title": "Show",
    "body": "Title: {{title}} Notes: {{notes}}",
    "fieldData": [
      {
        "fieldValue": "value 1",
        "friendlyName": "Title",
        "fieldType": "string",
        "fieldName": "title"
      },
      {
        "fieldValue": "value 2",
        "friendlyName": "notes",
        "fieldType": "multiline",
        "fieldName": "notes"
      }
    ],
    "createdAt": "2020-06-13T22:47:04+00:00",
    "updatedAt": "2020-06-13T22:47:04+00:00",
    "latitude": null,
    "longitude": null
  },
  ...
]
            `}
          </code>
        </pre>

        <hr />
        <Typography variant="h5" style={{ marginBottom: 12, marginTop: 12 }}>
          Add an entry to a Log
        </Typography>

        <Typography variant="h6" style={{ marginBottom: 12 }}>
          API Path
        </Typography>
        <code>{`POST : https://simplelog3.com/api/v1/log/<log_id>`}</code>
        <Typography variant="h6" style={{ marginBottom: 12, marginTop: 12 }}>
          API Headers
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
          You must pass specific headers with your POST request.
        </Typography>
        <code>{`x-api-token: <token provided from account and settings>`}</code>
        <br />
        <code>{`x-api-passphrase: <passphrase provided when you enable API access.>`}</code>
        <br />
        <code>{`Content-Type: application/json`}</code>
        <br />
        <Typography variant="h6" style={{ marginBottom: 12, marginTop: 12 }}>
          JSON Body Request
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
          You must pass a JSON body with the POST. The following is an example.
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
          Only the 'body' field is required.
        </Typography>
        <pre>
          <code>
            {`
{
    "title": "API Test Title",
    "body": "Here is an API Test Body. {{field1}} - {{date}} - {{datetime}}",
    "createdAt": "2020-06-10T17:03:46+00:00",
    "updatedAt": "2020-06-10T17:03:46+00:00",
    "latitude": 34.0000,
    "longitude": -118.0,
    "templateId": xyz,
    "fieldData": [
        {
            "fieldName": "field1",
            "fieldType": "string",
            "fieldValue": "test",
            "friendlyName": "Test"
        }
    ],
    "addedKeywords": [
      "keyword1",
      "keyword2"
    ],
    "additionalData": [
      {
        "name": "Barry",
        "age": 24
      }
    ]
}
`}
          </code>
        </pre>
      </Box>
      <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
        The fieldData property allows you to pass data fields that will be used
        to replace field variables in the body with the fieldValue associated
        with that fieldName. These are then edited in SimpleLog3 in the same way
        Templated entries are edited.
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
        The fieldType supports the following types: "string" and "multiline".
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
        The templateId field is used if you want to associate this entry with an
        existing Template in SimpleLog3.
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
        The addedKeywords property is an array of strings, that you can add to
        the entry. This allows you to find those entries by the specific
        keywords you add.
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
        The additionalData property is an array of objects you can attach to the
        entry. These values are available to you by using handlebars notation
        (see https://handlebarsjs.com). For example, in your entry, using the
        POST example above, the following will print 'Barry' in your entry:
      </Typography>
      <pre>
        <code>{`{{additionalData.[1].name}}`}</code>
      </pre>

      <Typography variant="h6" style={{ marginBottom: 12, marginTop: 12 }}>
        JSON Body Response
      </Typography>
      <pre>
        <code>
          {`
{
    "status":"OK",
    "logId":"xyz",
    "entryId":"xyz"
}
            `}
        </code>
      </pre>
      <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
        The API will respond with 'OK' if the entry was added successfully.
        Otherwise, an appropriate 4xx error returned.
      </Typography>

      <Typography variant="h6" style={{ marginBottom: 12, marginTop: 12 }}>
        Webhook
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
        Enabling the webhook on a Log provides a simple way to add entries
        without authentication. This is the least secure method and sharing the
        webhook URL will allow anyone to post to that log.
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 12, marginTop: 12 }}>
        The webhook URL can be found on the Log Edit form, after enabling
        webhooks. It takes the same format as the POST shown above.
      </Typography>
    </Container>
  );
};

export default APIHelp;
