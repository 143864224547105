export const LANDING = "/";
export const SIGN_IN = "/signin";
export const REGISTER = "/register";
export const HOME = "/entries";
export const QUICK_ENTRY = "/templates";
export const LOGS = "/logs";
export const FILTERS = "/filters";
export const MAP = "/map";
export const EDIT_ENTRY = "/editentry";
export const ACCOUNT = "/account";
