import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import { MuiThemeProvider } from "@material-ui/core/styles";
import EntryImage from "../Library/EntryImage";
import { Typography, Container } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { getBodyText, getCurrentUserId } from "../Library/Helper";
import colors from "../Library/Colors";
import pageTheme from "../Library/PageTheme";
const ReactMarkdown = require("react-markdown");
const simplelib = require("@bvelasquez/simple-log-lib");

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.3)
  },
  chip: {
    margin: theme.spacing(0.3),
    paddingTop: 3
  }
}));

const rendererParagraph = props => {
  return (
    <Typography paragraph variant="body1">
      {props.children}
    </Typography>
  );
};

const rendererHeading = props => {
  return (
    <Typography paragraph variant={`h${props.level}`}>
      {props.children}
    </Typography>
  );
};

const LogName = props => {
  let logName = "My Log";

  if (props.value && props.logs) {
    const logData = props.logs.find(l => {
      const data = l.data();
      return data.id === props.value.log;
    });

    if (logData) {
      logName = logData.data().title;
    }
  }

  return (
    <Typography
      style={{
        marginTop: "10px",
        fontSize: 12,
        color: colors.primaryHighlight,
        fontWeight: "600"
      }}
      variant="body2"
    >
      {`${logName}`}
    </Typography>
  );
};

const EntryImageComponent = props => {
  const classes = useStyles();
  const currentUserId = getCurrentUserId();

  if (props.value.images && currentUserId) {
    return (
      <CardContent>
        <EntryImage
          images={props.value.images}
          currentUserId={currentUserId}
          value={props.value}
        />
        {props.value.image_label_tags &&
          props.value.image_label_tags.length > 0 &&
          props.value.image_label_tags.map(t => {
            if (t) {
              return (
                <Chip
                  style={{ marginTop: 10 }}
                  size="small"
                  color="primary"
                  key={t}
                  label={t}
                  className={classes.chip}
                />
              );
            }
            return null;
          })}
      </CardContent>
    );
  }
  return null;
};

const EntryTitle = props => {
  if (props.value.title && props.value.title.length > 0) {
    return (
      <CardHeader
        style={{ color: colors.primaryHighlight }}
        title={props.value.title}
      ></CardHeader>
    );
  }
  return null;
};

class EntryPage extends React.Component {
  constructor(props) {
    super(props);

    this.history = props.history;

    this.state = {};
  }

  componentWillMount() {
    let match = this.props.match.params;

    let id = match.id;

    if (id) {
      simplelib.FirebaseSnapshots.entry(this.observer, id, data => {
        console.log("SNAPSHOT ENTRY ==>", data);
        this.setState({ entry: data });
      });
    }
  }

  render() {
    const { entry, logs } = this.state;

    if (!entry) {
      return null;
    }

    const createdAt = entry.createdAt ? entry.createdAt.toDate() : new Date();
    const updatedAt = entry.updatedAt ? entry.updatedAt.toDate() : new Date();

    const appendedBody = getBodyText(entry);

    const dateString = `${createdAt.toLocaleDateString()} ${createdAt.toLocaleString(
      "en-US",
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      }
    )}`;

    const updatedDateString = `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleString(
      "en-US",
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      }
    )}`;

    return (
      <MuiThemeProvider theme={pageTheme}>
        <Container maxWidth="md" style={{ height: "80%", marginTop: 20 }}>
          <Helmet>
            <title>{`SimpleLog3 - ${
              entry.title ? entry.title : "Entry"
            }`}</title>
            <meta name="description" content="SimpleLog3 ." />
            <meta name="robots" content="noindex" />
          </Helmet>
          <Card
            style={{
              marginBottom: 20,
              width: "100%"
            }}
          >
            <EntryTitle value={entry} />
            <EntryImageComponent value={entry} />

            <CardContent>
              <ReactMarkdown
                linkTarget="_blank"
                source={appendedBody}
                renderers={{
                  paragraph: rendererParagraph,
                  heading: rendererHeading
                }}
              ></ReactMarkdown>

              <Typography style={{ marginTop: "12px" }} variant="body2">
                {dateString}
              </Typography>
              <Typography
                style={{ marginTop: "5px", fontSize: 10 }}
                variant="body2"
              >
                {`last modified: ${updatedDateString}`}
              </Typography>
              <LogName value={entry} logs={logs} />
            </CardContent>
          </Card>
        </Container>
      </MuiThemeProvider>
    );
  }
}

export default EntryPage;
