import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { isMobile } from "react-device-detect";
import colors from "../Library/Colors";

const NavigationButton = withStyles(theme => ({
  root: {
    color: colors.primaryBackground,
    backgroundColor: colors.primaryHighlight,
    fontWeight: "400",
    fontSize: isMobile ? 12 : 20,
    paddingTop: 0,
    paddingBottom: 0,
    width: isMobile ? 60 : 115,
    "&:hover": {
      backgroundColor: colors.secondaryBackground
    }
  },
  label: {
    color: colors.primaryBackground,
    "&:hover": {
      color: colors.entryPrimaryText
    }
  },
  outlined: {
    color: colors.primaryBackground,
    borderSize: 1,
    borderColor: colors.secondaryBackground,
    borderRadius: 12
  }
}))(Button);

export default NavigationButton;
