import React, { useState, useEffect } from "react";
import { DialogTitle, Dialog, DialogContent, Box } from "@material-ui/core";
import QRCode from "react-qr-code";
import { MuiThemeProvider } from "@material-ui/core/styles";

import dialogTheme from "../Library/DialogTheme";

const QRCodeDialog = props => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  //   const handleSave = async () => {
  //     setOpen(false);
  //     if (props.didClose) {
  //       props.didClose();
  //     }

  //     return true;
  //   };

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        maxWidth="sm"
        fullWidth={false}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle>SimpleLog3 Share QR Code</DialogTitle>
        <DialogContent>
          <Box style={{ marginBottom: 20 }}>
            <QRCode value={props.shareURL} />
            <Box style={{ height: 20 }}></Box>
            <QRCode value={props.shareURL} size={190} />
            <Box style={{ height: 20 }}></Box>
            <QRCode value={props.shareURL} size={120} />
          </Box>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default QRCodeDialog;
