import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import ColorManager, { colorThemes } from "../Library/ColorManager";
const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const Subscriptions = props => {
  const Progress = props => {
    return (
      <Box
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <CircularProgress size={40} color="secondary" variant="indeterminate" />
      </Box>
    );
  };

  return (
    <Box
      style={{
        borderRadius: 18,
        padding: 20,
        textAlign: "center",
        backgroundColor: colors.secondaryBackground
      }}
    >
      <Typography variant="body1" style={{ fontSize: 36, fontWeight: "500" }}>
        SimpleLog<sup>3</sup> Free Account
      </Typography>
      <Typography
        style={{ marginTop: 12, marginBottom: 25, fontSize: 22 }}
        variant="body1"
      >
        Free account allows one Log with unlimited entries, 2.0 MB Image
        Attachment, Two Filters and Two Template. *
      </Typography>

      <Typography
        style={{
          fontSize: 36,
          fontWeight: "500",
          color: colors.primaryHighlight
        }}
      >
        SimpleLog<sup>3</sup> Subscription
      </Typography>
      <Box style={{ margin: 24 }}>
        <Typography variant="body1" style={{ fontSize: 22 }}>
          {`Unlimited Logs, Filters and Templates`}
        </Typography>

        <Typography style={{ marginTop: 12, fontSize: 22 }} variant="body1">
          Share your entries with others, upload larger images (8 MB), plus
          access all current and future features.
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        {props.plans ? (
          props.plans.map(p => {
            return (
              <Grid item xs key={p.id}>
                <Typography variant="h6" style={{ fontSize: 20 }}>
                  {p.nickname}
                </Typography>
                <Typography variant="h6" style={{ marginBottom: 10 }}>{`$${(
                  p.amount / 100
                ).toFixed(2)}`}</Typography>
              </Grid>
            );
          })
        ) : (
          <Progress />
        )}
      </Grid>
      <Typography style={{ marginTop: 12, fontSize: 22 }} variant="body1">
        <Link to="/register">Sign Up</Link> or <Link to="/signin">Sign In</Link>{" "}
        and Go to your Account Page to subscribe.
      </Typography>
      <Typography
        style={{ marginTop: 12, marginBottom: 25, fontSize: 14 }}
        variant="body1"
      >
        * For free accounts we reserve the right to change the provided features
        at any time.
      </Typography>
    </Box>
  );
};

export default Subscriptions;
