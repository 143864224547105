import React from "react";
import { Typography, Box, Container } from "@material-ui/core";
import { Link } from "react-router-dom";

const EntriesHelp = props => {
  return (
    <Container maxWidth="md" style={{ height: "80%", marginTop: 25 }}>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">Entries</Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          What are Entries?
        </Typography>
        <Typography gutterBottom variant="body1">
          Entries are the meat of SimpleLog<sup>3</sup>. They are your log
          entries that you make on a daily basis. Entries can be simple one
          liners, or lengthy with formatting using Markdown. Entries can also
          have an image attached that will display in the Log feed along with
          your text.
        </Typography>
        <Typography gutterBottom variant="h5">
          Simple Entries
        </Typography>
        <Typography gutterBottom variant="body1">
          Simple entries allow you to enter the Title and Body manually. You
          create a simple entry by tapping the plus button at the top of the
          Entries screen. Enter the Title, Body, and optionally change the
          date/time. You can also select the Log you want the entry to be added
          to keeping them organized.
        </Typography>
        <Typography gutterBottom variant="h5">
          Template Entries
        </Typography>
        <Typography gutterBottom variant="body1">
          Template entries are created by using a template using the Template
          feature and allow you to create entries that follow the same format
          every time. You can read more about{" "}
          <Link to="./templates">Templates in the template section</Link>. Once
          an entry is created using a template, is remembers the formatting and
          data used so you can modify the data later to update the entry.
        </Typography>
      </Box>
    </Container>
  );
};

export default EntriesHelp;
