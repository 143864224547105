import React from "react";
import uuid from "react-native-uuid";
import FlatList from "flatlist-react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { MuiThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Helmet } from "react-helmet";
import * as ROUTES from "../../constants/routes";
import Search from "../Searches/Search";
import { Container, Typography, Grid, IconButton } from "@material-ui/core";

import firebaseApp from "../Firebase/firebase";
import { checkPlan, getCurrentUserId } from "../Library/Helper";
import SearchEdit from "./SearchEdit";
import YellowButton from "../Library/YellowButton";
import AlertDialog from "../Library/AlertDialog";
import pageTheme from "../Library/PageTheme";
import colors from "../Library/Colors";

const simplelib = require("@bvelasquez/simple-log-lib");

const styles = {
  dialogPaper: {
    minHeight: "600",
    maxHeight: "80vh"
  }
};

const EmptyData = props => {
  return (
    <Container maxWidth="sm">
      <Box>
        <Typography
          align="center"
          variant="h5"
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          You have no filters
        </Typography>

        <Typography align="center" variant="h6">
          To get started, click the + button at the upper right and add your
          first filter.
        </Typography>

        <Box
          component="div"
          maxWidth="xs"
          style={{ textAlign: "center", marginTop: 24 }}
        >
          <YellowButton
            variant="contained"
            color="primary"
            align="center"
            onClick={() => {
              props.addClick();
            }}
          >
            Add a Filter Now
          </YellowButton>
        </Box>
      </Box>
    </Container>
  );
};

class Searches extends React.Component {
  constructor(props) {
    super(props);

    this.selectedValue = null;

    this.state = {
      quickData: null,
      open: false,
      fabOpen: false,
      selectedTitle: "",
      selectedBody: "",
      selectedName: "",
      alertOpen: false,
      alertTitle: "",
      alertMessage: ""
    };

    this.actions = [
      {
        id: 0,
        icon: <AddIcon />,
        name: "Add Filter",
        action: this.handleAddEntryButton
      }
    ];
  }

  setupQuickData = () => {
    const plan = checkPlan();

    simplelib.FirebaseSnapshots.filters(this.quickObserver, docs => {
      this.setState({ quickData: plan ? docs : docs.slice(0, 2) });
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    firebaseApp.analytics().logEvent("page_view", {
      page_location: "filters",
      page_path: "/filters",
      page_title: "Filters"
    });

    this.setupQuickData();

    const currentUserId = getCurrentUserId();
    simplelib.SetUserId(currentUserId);
    simplelib.SetFirebase(firebaseApp);

    this.authSubscription = firebaseApp.auth().onAuthStateChanged(user => {
      if (!user) {
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("logId");
        this.props.history.push(ROUTES.LANDING);
      } else {
        localStorage.setItem("currentUserId", user.uid);
      }
    });
  }

  componentWillUnmount() {
    if (this.quickObserver) {
      this.quickObserver();
    }
    if (this.authSubscription) {
      this.authSubscription();
    }
  }

  onChangeTitle = event => {
    //this.setState({ selectedTitle: event.target.value });
    this.selectedTitle = event.target.value;
  };

  onChangeName = event => {
    //this.setState({ selectedTitle: event.target.value });
    this.selectedName = event.target.value;
  };

  onChangeBody = event => {
    //this.setState({ selectedBody: event.target.value });
    this.selectedBody = event.target.value;
  };

  showAlert = (title, message) => {
    this.setState({
      alertOpen: true,
      alertTitle: title,
      alertMessage: message
    });
  };

  handleClose = () => {
    this.setState({ open: false, fabOpen: false });
  };

  handleSave = () => {
    this.onSubmit(() => {
      this.setState({ open: false, fabOpen: false });
    });
  };

  onSubmit = completion => {
    let id = uuid.v4();
    if (this.selectedValue) {
      id = this.selectedValue.id;
    }

    simplelib.FirebaseFunctions.saveFilter(id, {
      id: id,
      name: this.selectedName,
      title: this.selectedTitle,
      body: this.selectedBody,
      dateRange: this.selectedDateRange,
      expression: this.selectedExpression,
      yField: this.selectedYField,
      updatedAt: new Date()
    })
      .then(() => {
        completion();
      })
      .catch(e => {
        completion();
      });
  };

  handleAddEntryButton = () => {
    const plan = checkPlan();

    if (!plan) {
      if (!this.state.quickData) {
        return;
      }

      const freeValue = firebaseApp.remoteConfig().getValue("free_filters");
      console.log("free filters", freeValue.asNumber());
      if (
        this.state.quickData &&
        this.state.quickData.length >= freeValue.asNumber()
      ) {
        firebaseApp.analytics().logEvent("need_subscription_alert");
        this.showAlert(
          "Subscription",
          "You need a paid subscription to add more Filters. Filters are a great way to find specific entries with a single click. Sign up, and create as many filters as you like."
        );
        return;
      }
    }

    firebaseApp.analytics().logEvent("add_filter");

    this.selectedValue = null;
    this.selectedTitle = "";
    this.selectedBody = "";
    this.selectedName = "";
    this.selectedDateRange = "no_time";
    this.selectedExpression = "";
    this.selectedYField = "";
    this.setState({
      open: true,
      fabOpen: false,
      selectedTitle: this.selectedTitle,
      selectedBody: this.selectedBody,
      selectedName: this.selectedName,
      selectedDateRange: this.selectedDateRange,
      selectedExpression: this.selectedExpression,
      selectedYField: this.selectedYField
    });
  };

  didEditEntry = value => {
    this.selectedValue = value;
    this.selectedTitle = "";
    this.selectedBody = "";
    this.selectedName = "";
    this.selectedDateRange = "no_time";
    this.selectedExpression = "";
    this.selectedYField = "";
    if (value) {
      this.selectedTitle = value.title;
      this.selectedBody = value.body;
      this.selectedName = value.name;
      this.selectedDateRange = value.dateRange ? value.dateRange : "no_time";
      this.selectedExpression = value.expression ? value.expression : "";
      this.selectedYField = value.yField ? value.yField : "";
    }
    this.setState({
      open: true,
      selectedTitle: this.selectedTitle,
      selectedBody: this.selectedBody,
      selectedName: this.selectedName,
      selectedDateRange: this.selectedDateRange,
      selectedExpression: this.selectedExpression,
      selectedYField: this.selectedYField
    });
  };

  handleFabOpen = () => {
    console.log("OPENING FAB.");
    this.setState({ fabOpen: true });
  };

  handleFabClose = () => {
    console.log("CLOSING FAB.");
    this.setState({ fabOpen: false });
  };

  renderTitleArea = () => {
    return (
      <Grid container alignItems="center">
        <Grid item md={11}>
          <Typography variant="h4" align="left">
            Filters
          </Typography>
        </Grid>

        <Grid item md={1} style={{ textAlign: "right" }}>
          <IconButton onClick={this.handleAddEntryButton}>
            <AddCircleIcon
              style={{
                fontSize: isMobile ? 24 : colors.primaryIconSize,
                color: colors.primaryHighlight
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { quickData } = this.state;

    if (quickData === null) {
      return null;
    }

    return (
      <MuiThemeProvider theme={pageTheme}>
        <Helmet>
          <title>SimpleLog3 - Filters</title>
          <meta name="description" content="SimpleLog3 filters." />
          <meta name="robots" content="noindex" />
        </Helmet>
        <CssBaseline />
        <Container maxWidth="md" style={{ marginTop: 25, paddingBottom: 25 }}>
          <Container style={{ marginBottom: 24, paddingLeft: 0 }}>
            {this.renderTitleArea()}

            <Typography
              align="left"
              style={{
                width: isMobile ? 400 : 800,
                fontSize: isMobile ? 12 : colors.featureDescriptionFontSize
              }}
            >
              Filters let you quickly search for specific Entries. Use them when
              you routinely search for the same items, making it easy to create
              groups of entries.
            </Typography>
            <Link className="helpImproveLink" to="/contact">
              Help us improve. Give us feedback
            </Link>
          </Container>

          <FlatList
            style={{ height: "100%" }}
            list={quickData}
            renderItem={(item, idx) => {
              const value = item.data();
              return (
                <Search
                  key={value.id}
                  value={value}
                  history={this.props.history}
                  didEdit={this.didEditEntry}
                ></Search>
              );
            }}
            renderWhenEmpty={() => {
              return (
                <EmptyData
                  addClick={() => {
                    this.handleAddEntryButton();
                  }}
                />
              );
            }}
          />

          <SearchEdit
            open={this.state.open}
            name={this.selectedName}
            title={this.selectedTitle}
            body={this.selectedBody}
            dateRange={this.selectedDateRange}
            expression={this.selectedExpression}
            yField={this.selectedYField}
            didSave={data => {
              this.selectedName = data.name;
              this.selectedTitle = data.title;
              this.selectedBody = data.body;
              this.selectedDateRange = data.dateRange;
              this.selectedExpression = data.expression;
              this.selectedYField = data.yField;

              this.setState({ open: false });
              this.handleSave();
            }}
            didClose={() => {
              this.setState({ open: false });
            }}
          />

          <AlertDialog
            open={this.state.alertOpen}
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            handleClose={() => {
              this.setState({ alertOpen: false });
            }}
            handleNavigation={() => {
              // navigate to account.
              console.log("HANDLE NAVIGATION");
              this.props.history.push(ROUTES.ACCOUNT);
            }}
          />
        </Container>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Searches);
