import React from "react";
import { Typography, Box, Container } from "@material-ui/core";

const MembershipHelp = props => {
  return (
    <Container maxWidth="md" style={{ height: "80%", marginTop: 25 }}>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">Membership</Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          Looking for Advanced Features?
        </Typography>
        <Typography gutterBottom variant="body1">
          Membership can be started from your <a href="/account">account</a>{" "}
          page on our website after <a href="/signin">signing in</a>. All
          advanced features will then be available on all your devices after
          that.
        </Typography>
      </Box>
    </Container>
  );
};

export default MembershipHelp;
