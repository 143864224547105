import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import CardHeader from "@material-ui/core/CardHeader";
import ReactPlayer from "react-player";
import Card from "@material-ui/core/Card";
import EntryImage from "../Library/EntryImage";
import { Typography, Box } from "@material-ui/core";
import AlertDialog from "../Library/AlertDialog";
import colors from "../Library/Colors";
import { getBodyText } from "../Library/Helper";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";

import * as ROUTES from "../../constants/routes";

import { getCurrentUserId } from "../Library/Helper";

const ReactMarkdown = require("react-markdown");

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.3)
  },
  chip: {
    margin: theme.spacing(0.3),
    paddingTop: 3
  }
}));

const rendererParagraph = props => {
  return (
    <Typography
      paragraph
      variant="body1"
      style={{ marginBottom: 12, color: colors.entryPrimaryText }}
    >
      {props.children}
    </Typography>
  );
};

const rendererHeading = props => {
  return (
    <Typography paragraph variant={`h${props.level}`}>
      {props.children}
    </Typography>
  );
};

const rendererCode = props => {
  return (
    <SyntaxHighlighter
      showLineNumbers={true}
      style={dark}
      language={props.language}
      children={props.value}
    />
  );
};

const rendererList = props => {
  return (
    <Typography
      component="span"
      variant="body1"
      style={{ marginBottom: 12, color: colors.entryPrimaryText }}
    >
      {props.children}
    </Typography>
  );
};

const LogName = props => {
  let logName = props.logName;

  return (
    <Typography
      style={{
        marginTop: "10px",
        color: colors.entryPrimaryHighlight
      }}
      variant="subtitle2"
    >
      {`${logName}`}
    </Typography>
  );
};

const EntryImageComponent = props => {
  const classes = useStyles();

  if (props.value.images && props.userId && props.logId) {
    return (
      <CardContent>
        <EntryImage
          images={props.value.images}
          currentUserId={props.userId}
          value={props.value}
          isShared={true}
        />
        {props.value.image_label_tags &&
          props.value.image_label_tags.map(t => {
            return (
              <Chip
                style={{ marginTop: 10 }}
                size="small"
                color="primary"
                key={t}
                label={t}
                // avatar={<Avatar>{t[0].toUpperCase()}</Avatar>}
                className={classes.chip}
              />
            );
          })}
      </CardContent>
    );
  }
  return null;
};

const EntryTitle = props => {
  if (props.value.title && props.value.title.length > 0) {
    return (
      <CardHeader
        style={{ color: colors.primaryHighlight }}
        title={props.value.title}
      ></CardHeader>
    );
  }
  return null;
};

class SharedEntry extends React.Component {
  constructor(props) {
    super(props);

    this.history = props.history;

    this.state = {
      menuOpen: false,
      anchorEl: null,

      alertOpen: false,
      alertTitle: "",
      alertMessage: ""
    };
  }

  showAlert = (title, message) => {
    this.setState({
      alertOpen: true,
      alertTitle: title,
      alertMessage: message
    });
  };

  renderAudio = () => {
    const { value } = this.props;
    if (value.audioId) {
      return (
        <Box style={{ marginTop: 0, marginBottom: 12 }}>
          <ReactPlayer
            controls
            playsinline
            url={value.audioUrl}
            width="100%"
            height={40}
          />
        </Box>
      );
    }
    return null;
  };

  render() {
    const { value, logName, logId, userId } = this.props;

    if (!value) {
      return null;
    }

    const createdAt = value.createdAt ? value.createdAt.toDate() : new Date();

    const appendedBody = getBodyText(value);

    const dateString = `${createdAt.toLocaleDateString()} ${createdAt.toLocaleString(
      "en-US",
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      }
    )}`;

    const loggedInUserId = getCurrentUserId();

    return (
      <Card
        style={{
          marginBottom: 40,
          width: "100%"
        }}
      >
        <EntryTitle value={value} />
        <EntryImageComponent value={value} userId={userId} logId={logId} />

        <CardContent>
          {this.renderAudio()}
          <ReactMarkdown
            linkTarget="_blank"
            source={appendedBody}
            renderers={{
              paragraph: rendererParagraph,
              heading: rendererHeading,
              list: rendererList,
              code: rendererCode
            }}
          ></ReactMarkdown>

          <Typography
            style={{ marginTop: "5px", color: colors.secondaryText }}
            variant="body2"
          >
            {dateString}
          </Typography>

          <LogName logName={logName} />
        </CardContent>

        {!loggedInUserId && !this.props.hideAd && (
          <CardContent>
            <Typography
              style={{ marginTop: "12px", textAlign: "center" }}
              variant="h6"
            >
              Shared via SimpleLog<sup>3</sup>
            </Typography>
            <Typography
              style={{ marginTop: "12px", textAlign: "center" }}
              variant="h6"
            >
              <a href={`${window.location.origin.toString()}/register`}>
                Sign up
              </a>{" "}
              for a free account today, and start logging.
            </Typography>
          </CardContent>
        )}

        <AlertDialog
          open={this.state.alertOpen}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          handleClose={() => {
            this.setState({ alertOpen: false });
          }}
          handleNavigation={() => {
            // navigate to account.
            console.log("HANDLE NAVIGATION");
            this.props.history.push(ROUTES.ACCOUNT);
          }}
        />
      </Card>
    );
  }
}

export default SharedEntry;
