import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Select, MenuItem, Divider } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import colors from "../Library/Colors";

const underscore = require("underscore");

const theme1 = createTheme({
  typography: {
    fontFamily: [colors.font].join(",")
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: colors.secondaryText
      }
    },
    MuiList: {
      root: {
        backgroundColor: colors.secondaryBackground,
        maxHeight: 500,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "block",
          backgroundColor: colors.secondaryBackground
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.primaryText
        }
      }
    },
    MuiListItem: {
      root: {
        height: 50,
        color: colors.secondaryText
      }
    },
    MuiOutlinedInput: {
      root: {
        border: 0,
        borderRadius: 12,
        background: colors.secondaryBackground
      }
    },
    MuiDivider: {
      root: {
        color: colors.secondaryBackground,
        backgroundColor: colors.secondaryBackground,
        marginLeft: 5,
        marginRight: 5
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          background: "$labelcolor"
        }
      }
    }
  }
});

const DarkOutlinedSelect = withStyles(theme => ({
  root: {
    color: colors.secondaryText,
    backgroundColor: colors.secondaryBackground,
    fontWeight: "500",
    fontSize: isMobile ? 12 : colors.logSelectionFontSize,
    borderRadius: 12
  }
}))(Select);

const DarkMenuItem = withStyles(theme => ({
  root: {
    color: colors.secondaryText,
    backgroundColor: colors.secondaryBackground,
    fontWeight: "500",
    fontSize: isMobile ? 12 : colors.logSelectionFontSize
  }
}))(MenuItem);

const ColoredMenuItem = withStyles(theme => ({
  root: {
    color: colors.primaryHighlight,
    backgroundColor: colors.secondaryBackground,
    fontWeight: "500",
    fontSize: isMobile ? 12 : colors.logSelectionFontSize
  }
}))(MenuItem);

const LogSelection = props => {
  const [logs, setLogs] = useState([]);
  const [log, setLog] = useState("default");

  useEffect(() => {
    setLogs(props.logs);
    setLog(props.log);
  }, [props.logs, props.log, props.update]);

  const handleChange = function(event) {
    if (event.target.value === "header") {
      return;
    }
    setLog(event.target.value);
    if (props.selected) {
      const log = underscore.find(logs, function(i) {
        return i.id === event.target.value;
      });
      props.selected(event.target.value, log ? log.data() : null);
    }
  };

  return (
    <MuiThemeProvider theme={theme1}>
      <DarkOutlinedSelect
        className="logSelection"
        variant="outlined"
        value={log}
        onChange={handleChange}
        style={{
          width: isMobile ? 135 : 300
        }}
      >
        <DarkMenuItem style={{ fontSize: 20 }} key="header" value="header">
          Choose a log below:
        </DarkMenuItem>
        <Divider style={{ marginTop: 12 }} />

        <DarkMenuItem key="default" value="default">
          My Log
        </DarkMenuItem>
        {logs.map(l => {
          return (
            <DarkMenuItem key={l.data().id} value={l.data().id}>
              {l.data().title}
            </DarkMenuItem>
          );
        })}
        <Divider style={{ marginTop: 12 }} />
        <ColoredMenuItem style={{ fontSize: 20 }} key="create" value="create">
          Create a new log
        </ColoredMenuItem>
      </DarkOutlinedSelect>
    </MuiThemeProvider>
  );
};

export default LogSelection;
