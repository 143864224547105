import React, { useRef, useEffect, useState } from "react";
import { Box, TextField, InputAdornment } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { isMobile } from "react-device-detect";
import colors from "../Library/Colors";

const SearchBar = props => {
  let textInput = useRef(null);

  const [search, setSearch] = useState(
    props.defaultValue ? props.defaultValue : ""
  );

  useEffect(() => {
    // textInput.current.value = props.defaultValue;
    setSearch(props.defaultValue ? props.defaultValue : "");
  }, [props.defaultValue, props.update]);

  return (
    <Box style={{ textAlign: "center", marginTop: 24 }}>
      <TextField
        inputRef={textInput}
        autoComplete="off"
        // defaultValue={props.defaultValue}
        value={search}
        style={{ width: isMobile ? 340 : 747 }}
        id="standard-search"
        margin="normal"
        variant="outlined"
        placeholder={isMobile ? "Search..." : "Search all logs and entries"}
        onChange={event => {
          if (props.searchChanged) {
            props.searchChanged(event);
            setSearch(event.target.value);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CancelIcon
                style={{
                  marginLeft: 20,
                  color: colors.primaryHighlight,
                  fontSize: isMobile ? 24 : colors.secondaryIconSize
                }}
                onClick={() => {
                  textInput.current.value = "";
                  setSearch("");
                  if (props.searchCancelled) {
                    props.searchCancelled();
                  }
                }}
              />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default SearchBar;
