import React from "react";
import { Router, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  AppBar,
  Typography,
  Toolbar,
  Container,
  Button,
  Box,
  Grid,
  Drawer,
  List,
  ListItem,
  Divider
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import MenuIcon from "@material-ui/icons/Menu";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import firebaseApp from "../Firebase/firebase";

import LandingPage from "../Landing";
import SignInPage from "../SignIn";
import HomePage from "../Home";
import MapView from "../Map";
import QuickEntry from "../QuickEntries";
import Searches from "../Searches";
import Registration from "../Registration";
import Account from "../Account";
import Logs from "../Logs";
import Help from "../Help/Help";
import EntryPage from "../EntryPage";
import SharedEntryPage from "../SharedEntryPage";
import SharedLogPage from "../SharedLogPage";
import Contact from "../Contact";
import Handler from "../Handler";

import currentKeys from "../Library/StripeConstants";

import { isMobile } from "react-device-detect";

import * as ROUTES from "../../constants/routes";

import TitleDarkOutlineButton from "../Library/TitleDarkOutlineButton";

import ColorManager, { colorThemes } from "../Library/ColorManager";
import loginColors from "../Library/Colors";
import { getCurrentUserId } from "../Library/Helper";

const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const simplelib = require("@bvelasquez/simple-log-lib");

const history = createBrowserHistory();
const queryString = require("query-string");

const theme = createTheme({
  overrides: {
    MuiToolbar: {
      regular: {
        fontWeight: "bold",
        minHeight: 42
      }
    },
    MuiDivider: {
      root: {
        color: loginColors.primaryText,
        backgroundColor: loginColors.primaryText,
        marginLeft: 5,
        marginRight: 5
      }
    },
    MuiButton: {
      root: {
        padding: 12
      },
      label: {
        "& .HelpButton": {
          fontSize: 24,
          textDecoration: "none",
          color: colors.primaryText
        }
      }
    },
    MuiAppBar: {
      colorPrimary: colors.dialogBackground
    },
    MuiCard: {
      root: {
        backgroundColor: colors.secondaryBackground
      }
    },
    MuiDialogTitle: {
      root: {
        color: colors.secondaryBackground
      }
    },
    MuiInputBase: {
      input: {
        background: colors.dialogBackground,
        border: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        border: 0,
        borderRadius: 0,
        background: colors.dialogBackground
      }
    },
    MuiChip: {
      sizeSmall: {
        fontSize: 10,
        color: colors.dialogBackground
      }
    },
    MuiDrawer: {
      paper: {
        background: loginColors.primaryBackground
      }
    },
    MuiGrid: {
      item: {
        "& a": {
          color: colors.primaryHighlight
        }
      }
    },
    MuiTypography: {
      root: {
        "& a": {
          color: colors.primaryHighlight
        }
      }
    }
  },
  typography: {
    fontFamily: [colors.font].join(","),
    button: { textTransform: "none" },
    body1: { color: colors.dialogBackground, whiteSpace: "pre-wrap" },
    body2: { color: colors.dialogBackground },
    h1: { color: colors.primaryHighlight },
    h2: { color: colors.primaryHighlight },
    h3: { color: colors.primaryHighlight },
    h4: { color: colors.primaryHighlight },
    h5: { color: colors.primaryHighlight },
    h6: { color: colors.primaryHighlight },
    code: { color: colors.dialogBackground },
    color: colors.dialogBackground
  },
  palette: {
    primary: {
      main: colors.secondaryBackground
    },
    secondary: {
      main: colors.dialogBackground,
      contrastText: colors.dialogBackground
    },
    background: {
      default: colors.primaryBackground
    }
  }
});

const MenuDrawer = props => {
  const [open, setOpen] = React.useState(false);
  const [signedIn, setSignedIn] = React.useState(false);

  React.useEffect(() => {
    setOpen(props.open);
    setSignedIn(props.signedIn);
  }, [props.open, props.signedIn]);

  const toggleDrawer = openProperty => {
    console.log("TOGGLING", openProperty);
    setOpen(openProperty);

    if (props.toggled) {
      props.toggled(openProperty);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Drawer
        anchor="right"
        open={open}
        onClick={() => {
          toggleDrawer(false);
        }}
      >
        <Box
          style={{
            width: 260,
            backgroundColor: loginColors.primaryBackground
          }}
          onClick={() => {
            toggleDrawer(false);
          }}
        >
          <List>
            {signedIn && (
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button style={{}} color="inherit">
                  <Link
                    style={{
                      fontWeight: "bold",
                      fontFamily: loginColors.font,
                      fontSize: loginColors.menuFontSize,
                      color: loginColors.primaryText,
                      textDecoration: "none"
                    }}
                    to={ROUTES.HOME}
                  >
                    View Entries
                  </Link>
                </Button>
              </ListItem>
            )}

            <Divider />

            {signedIn && (
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button style={{}} color="inherit">
                  <Link
                    style={{
                      fontWeight: "bold",
                      fontFamily: loginColors.font,
                      fontSize: loginColors.menuFontSize,
                      color: loginColors.primaryText,
                      textDecoration: "none"
                    }}
                    to={ROUTES.LOGS}
                  >
                    Manage Logs
                  </Link>
                </Button>
              </ListItem>
            )}

            {signedIn && (
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button style={{}} color="inherit">
                  <Link
                    style={{
                      fontWeight: "bold",
                      fontFamily: loginColors.font,
                      fontSize: loginColors.menuFontSize,
                      color: loginColors.primaryText,
                      textDecoration: "none"
                    }}
                    to={ROUTES.QUICK_ENTRY}
                  >
                    Edit Templates
                  </Link>
                </Button>
              </ListItem>
            )}
            {signedIn && (
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button style={{}} color="inherit">
                  <Link
                    style={{
                      fontWeight: "bold",
                      fontFamily: loginColors.font,
                      fontSize: loginColors.menuFontSize,
                      color: loginColors.primaryText,
                      textDecoration: "none"
                    }}
                    to={ROUTES.FILTERS}
                  >
                    Edit Filters
                  </Link>
                </Button>
              </ListItem>
            )}

            <Divider />

            <ListItem style={{ justifyContent: "flex-end" }}>
              <Button style={{}} color="inherit">
                <Link
                  style={{
                    fontWeight: "bold",
                    fontFamily: loginColors.font,
                    fontSize: loginColors.menuFontSize,
                    color: loginColors.primaryText,
                    textDecoration: "none"
                  }}
                  to="/help"
                >
                  Get Help
                </Link>
              </Button>
            </ListItem>

            <ListItem style={{ justifyContent: "flex-end" }}>
              <Button style={{}} color="inherit">
                <Link
                  style={{
                    fontWeight: "bold",
                    fontFamily: loginColors.font,
                    fontSize: loginColors.menuFontSize,
                    color: loginColors.primaryText,
                    textDecoration: "none"
                  }}
                  to="/contact"
                >
                  Give Feedback
                </Link>
              </Button>
            </ListItem>

            {signedIn && (
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button style={{}} color="inherit">
                  <Link
                    style={{
                      fontWeight: "bold",
                      fontFamily: loginColors.font,
                      fontSize: loginColors.menuFontSize,
                      color: loginColors.primaryText,
                      textDecoration: "none"
                    }}
                    to={ROUTES.ACCOUNT}
                  >
                    Account and Settings
                  </Link>
                </Button>
              </ListItem>
            )}
          </List>
          <Divider />
          <List>
            {signedIn && (
              <ListItem style={{ justifyContent: "flex-end" }}>
                <Button
                  color="inherit"
                  onClick={() => {
                    toggleDrawer(false);
                  }}
                >
                  <Link
                    onClick={() => {
                      toggleDrawer(false);
                    }}
                    style={{
                      fontWeight: "bold",
                      fontFamily: loginColors.font,
                      fontSize: loginColors.menuFontSize,
                      color: loginColors.primaryText,
                      textDecoration: "none"
                    }}
                    to={ROUTES.SIGN_IN}
                  >
                    Logout
                  </Link>
                </Button>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </MuiThemeProvider>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);

    let currentUserId = null;
    if (localStorage) {
      currentUserId = getCurrentUserId();
    }
    this.state = {
      currentUserId: currentUserId,
      showMenu: true,
      showDrawer: false,
      plans: null
    };
  }

  async componentDidMount() {
    const showMenu = history.location.pathname !== "/";
    this.setState({ showMenu });

    simplelib.SetFirebase(firebaseApp);

    simplelib.APIFunctions.getPlans(currentKeys.currentProductKey).then(r => {
      this.setState({ plans: r });
    });

    firebaseApp.auth().onAuthStateChanged(user => {
      if (!user) {
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("email");
        localStorage.removeItem("logId");
        localStorage.removeItem("plan");
        this.setState({ currentUserId: null });
      } else {
        localStorage.setItem("currentUserId", user.uid);
        localStorage.setItem("email", user.email);
        this.setState({ currentUserId: user.uid });

        simplelib.SetUserId(user.uid);

        simplelib.FirebaseFunctions.saveAccount({
          email: user.email,
          emailVerified: user.emailVerified
        });

        // Do they have a plan?
        simplelib.APIFunctions.getProductPlan(user.uid)
          .then(plan => {
            if (
              plan &&
              (plan.status === "active" || plan.status === "trialing")
            ) {
              localStorage.setItem("plan", plan.plan);
            } else {
              localStorage.removeItem("plan");
            }
          })
          .catch(e => {
            localStorage.removeItem("plan");
          });

        simplelib.FirebaseSnapshots.account(this.observer, data => {
          if (data) {
            if (data.theme && data.theme.length > 0) {
              localStorage.setItem("theme", data.theme);
              ColorManager.setColorTheme(data.theme);
            }
          }
        });
      }
    });
  }

  handleEntriesClick = () => {
    Router.history.push(ROUTES.HOME);
    //this.props.history.push(ROUTES.HOME);
  };

  renderMainMenu = shouldShow => {
    let isShared = false;
    if (
      history.location.pathname.includes("/shared_log/") ||
      history.location.pathname.includes("/shared_entry/") ||
      history.location.pathname.includes("/s/")
    ) {
      isShared = true;
    }

    if (isShared) {
      let url = history.location.search;
      let params = queryString.parse(url);

      if (params && (params.hideMenu || params.hm)) {
        return null;
      }
    }

    let loginText = "Sign In";
    let signedIn = false;
    if (this.state.currentUserId) {
      loginText = "Sign Out";
      signedIn = true;
    }

    return (
      <AppBar
        elevation={0}
        position="sticky"
        style={{
          backgroundColor: this.state.currentUserId
            ? loginColors.appHeaderColor
            : colors.secondaryBackground
        }}
      >
        <Toolbar
          style={{
            backgroundColor: this.state.currentUserId
              ? loginColors.appHeaderColor
              : colors.secondaryBackground,
            marginLeft: 24,
            marginRight: 24,
            marginTop: 12,
            marginBottom: 12
          }}
        >
          <Typography style={{ flexGrow: 1 }}>
            <Link
              style={{
                color: this.state.currentUserId
                  ? loginColors.appHeaderTitleColor
                  : colors.primaryText,
                textDecoration: "none",
                fontSize: isMobile ? 24 : 36,
                marginRight: 12,
                fontWeight: "bold"
              }}
              to={ROUTES.LANDING}
            >
              SimpleLog<sup style={{ fontSize: 20, fontWeight: "bold" }}>3</sup>
            </Link>
            {/* <sub
              style={{
                fontSize: 12,
                fontWeight: "bold",
                marginLeft: -20,
                color: this.state.currentUserId
                  ? loginColors.appHeaderTitleColor
                  : colors.primaryText
              }}
            >
              BETA
            </sub> */}
          </Typography>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {signedIn && (
              <Grid item>
                <MenuIcon
                  style={{
                    fontSize: 35,
                    color: this.state.currentUserId
                      ? loginColors.appHeaderTitleColor
                      : colors.primaryText
                  }}
                  onClick={() => {
                    this.setState({ showDrawer: true });
                  }}
                />
              </Grid>
            )}

            {!signedIn && (
              <Grid item>
                <TitleDarkOutlineButton
                  variant="outlined"
                  style={{ width: isMobile ? 120 : 240 }}
                >
                  <Link
                    style={{
                      color: colors.primaryText,
                      textDecoration: "none"
                    }}
                    to={ROUTES.SIGN_IN}
                  >
                    {loginText}
                  </Link>
                </TitleDarkOutlineButton>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  render() {
    let signedIn = false;
    if (this.state.currentUserId) {
      signedIn = true;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>SimpleLog3 - best journal, diary, and log</title>
          <meta
            name="description"
            content="Need a journal, log, or diary? SimpleLog3, the last log you'll ever need. Journal, Diary, Logging made simple."
          />
        </Helmet>
        <CssBaseline />

        <Router history={history}>
          {this.renderMainMenu()}

          <MenuDrawer
            open={this.state.showDrawer}
            signedIn={signedIn}
            toggled={state => {
              this.setState({ showDrawer: state });
            }}
          />

          <Container
            maxWidth={false}
            style={{
              margin: 0,
              padding: 0
            }}
          >
            <Route
              exact
              path={ROUTES.LANDING}
              render={props => (
                <LandingPage {...props} plans={this.state.plans} />
              )}
            />

            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route
              path={[ROUTES.HOME, "/hashtag/:hashTag", "/search/:search"]}
              component={HomePage}
            />
            <Route path={ROUTES.QUICK_ENTRY} component={QuickEntry} />
            <Route path={ROUTES.LOGS} component={Logs} />
            <Route path={ROUTES.FILTERS} component={Searches} />
            <Route path={ROUTES.MAP} component={MapView} />
            <Route path={ROUTES.REGISTER} component={Registration} />
            <Route path={ROUTES.ACCOUNT} component={Account} />
            <Route path={"/entry/:id"} component={EntryPage} />
            <Route
              path={"/shared_entry/:userId/:entryId"}
              component={SharedEntryPage}
            />
            <Route path={"/s/:shareId"} component={SharedEntryPage} />
            <Route
              path={"/shared_log/:userId/:logId"}
              component={SharedLogPage}
            />

            <Route
              path={"/help"}
              render={props => <Help {...props} plans={this.state.plans} />}
            />

            <Route path={"/contact"} component={Contact} />
            <Route path={"/handler"} component={Handler} />
          </Container>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
