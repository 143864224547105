import React from "react";
import ReactDOM from "react-dom";
import firebaseApp from "./components/Firebase/firebase";
import "./index.css";
import "./fonts/Poppins-Regular.ttf";
import * as serviceWorker from "./serviceWorker";
import App from "./components/App";
const simplelib = require("@bvelasquez/simple-log-lib");

simplelib.SetFirebase(firebaseApp);
ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
