import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import ColorManager, { colorThemes } from "../Library/ColorManager";
import { isMobile } from "react-device-detect";
const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const Features = props => {
  return (
    <Container
      style={{
        textAlign: "center",
        alignContent: "center",
        marginTop: 54,
        paddingBottom: 100
      }}
    >
      <Container maxWidth="xs">
        <Typography
          style={{
            fontSize: isMobile ? 35 : 44,
            fontWeight: "bold",
            color: colors.secondaryBackground,
            textAlign: "center"
          }}
        >
          Our Features
        </Typography>
      </Container>

      <Container maxWidth="md">
        <Grid
          container
          spacing={isMobile ? 6 : 6}
          alignItems="center"
          justifyContent="center"
          direction="row"
          style={{ marginTop: 24 }}
        >
          <Grid
            item
            xs
            style={{
              backgroundColor: colors.primaryHighlight,
              borderRadius: 16,
              margin: 12,
              minHeight: 268
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: 30,
                color: colors.primaryBackground,
                fontWeight: "500"
              }}
            >
              Filters
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 18,
                color: colors.primaryBackground,
                fontWeight: "normal"
              }}
            >
              Filters let you quickly search for specific Entries. Use them to
              create groups of entries by text search, keywords, hashtags, or
              Regular Expressions. Export or Chart your results.
            </Typography>
          </Grid>

          <Grid
            item
            xs
            style={{
              backgroundColor: colors.primaryHighlight,
              borderRadius: 16,
              margin: 12,
              minHeight: 268
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: 30,
                color: colors.primaryBackground,
                fontWeight: "500"
              }}
            >
              Templates
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 18,
                color: colors.primaryBackground,
                fontWeight: "normal"
              }}
            >
              Create templates to get started on a new entry fast and
              effectively. Templates can be quick and automatic or field based
              allowing you to enter new entries in a consistent way.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={isMobile ? 6 : 6}
          alignItems="center"
          justifyContent="center"
          direction="row"
          style={{ marginTop: 24 }}
        >
          <Grid
            item
            sm
            style={{
              backgroundColor: colors.primaryHighlight,
              borderRadius: 16,
              margin: 12,
              minHeight: 268
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: 30,
                color: colors.primaryBackground,
                fontWeight: "500"
              }}
            >
              Logs
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 18,
                color: colors.primaryBackground,
                fontWeight: "normal"
              }}
            >
              Adding additional logs allows you to keep business entries
              separate from personal entries. Into fitness? Create a workout
              log. Business? A customer call log. The possibilities for
              different logs is endless.
            </Typography>
          </Grid>
          <Grid
            item
            xs
            style={{
              backgroundColor: colors.primaryHighlight,
              borderRadius: 16,
              margin: 12,
              minHeight: 268
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: 30,
                color: colors.primaryBackground,
                fontWeight: "500"
              }}
            >
              Sharing
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: 18,
                color: colors.primaryBackground,
                fontWeight: "normal"
              }}
            >
              Share your log entries with others. Share entire logs with others.
              Shared logs are updated real-time so others will see your changes
              immediately.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Features;
