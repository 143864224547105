import { createTheme } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import colors from "../Library/Colors";

const entryTheme = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        "& .helpImproveLink": {
          color: colors.entryPrimaryHighlight,
          fontSize: 14
        }
      }
    },
    MuiTypography: {
      root: {
        "& .resetPasswordButton": {
          textDecoration: "underline"
        },
        "& a": {
          color: colors.entryPrimaryHighlight
        },
        "& code": {
          fontSize: colors.entryPrimaryFontSize,
          fontFamily: "courier",
          overflow: "scroll",
          color: colors.entryPrimaryText
        }
      }
    },
    MuiToolbar: {
      regular: {
        fontWeight: "bold"
      }
    },
    MuiCheckbox: {
      root: {
        color: colors.primaryText
      },
      colorPrimary: {
        color: colors.entryPrimaryHighlight
      }
    },
    MuiButton: {
      root: {
        padding: 12
      },
      label: {
        color: colors.primaryText,
        justifyContent: "center",
        textAlign: "center"
      }
    },
    MuiCard: {
      root: {
        backgroundColor: colors.secondaryBackground,
        borderRadius: 16
      }
    },
    MuiCardHeader: {
      title: {
        fontSize: colors.entryTitleFontSize,
        fontWeight: 700,
        color: colors.entryPrimaryHighlight
      }
    },
    MuiCardContent: {
      root: {
        "& li": {
          fontSize: colors.entryPrimaryFontSize,
          fontFamily: colors.font,
          color: colors.entryPrimaryText
        },
        "& td": {
          fontSize: colors.entryPrimaryFontSize,
          fontFamily: colors.font,
          color: colors.entryPrimaryText
        },
        "& th": {
          fontSize: colors.entryPrimaryFontSize,
          fontFamily: colors.font,
          color: colors.entryPrimaryText
        }
      }
    },
    MuiList: {
      root: {
        backgroundColor: colors.secondaryBackground
      }
    },
    MuiMenuItem: {
      root: {
        color: colors.entryPrimaryText
      }
    },
    MuiInputBase: {
      root: {
        background: colors.primaryBackground
      },
      input: {
        color: colors.entryPrimaryText,
        background: colors.secondaryBackground
      }
    },
    MuiOutlinedInput: {
      root: {
        border: 1,
        borderColor: colors.entryPrimaryText,
        borderRadius: 12,
        background: colors.primaryBackground
      },
      multiline: {
        padding: 0
      },
      inputMultiline: {
        padding: 8
      },
      input: {
        border: 1,
        borderColor: colors.entryPrimaryText,
        borderRadius: 12,
        fontSize: isMobile ? 12 : 24
      }
    },
    MuiChip: {
      sizeSmall: {
        fontSize: 12,
        color: colors.secondaryBackground,
        paddingTop: 0
      },
      colorPrimary: {
        color: colors.secondaryBackground,
        backgroundColor: colors.entryPrimaryHighlight
      }
    },
    MuiGrid: {
      root: {
        "& .menuLink": {
          textDecoration: "underline",
          color: colors.entryPrimaryHighlight
        }
      }
    }
  },
  typography: {
    fontFamily: [colors.font].join(","),
    button: { textTransform: "none" },
    body1: {
      fontSize: isMobile
        ? colors.entryPrimaryFontSize - colors.entryPrimaryFontSize > 16
          ? 16
          : colors.entryPrimaryFontSize
        : colors.entryPrimaryFontSize,
      fontWeight: 400,
      color: colors.entryPrimaryText,
      whiteSpace: "pre-wrap",
      lineBreak: "normal",
      "& a": {
        lineBreak: "anywhere"
      },
      "& code": {
        fontSize: colors.entryPrimaryFontSize,
        fontFamily: "courier",
        overflow: "scroll",
        color: colors.entryPrimaryText
      }
    },
    body2: {
      color: colors.entryPrimaryText,
      fontSize: isMobile
        ? colors.entryPrimaryFontSize - colors.entryPrimaryFontSize > 16
          ? 16
          : colors.entryPrimaryFontSize
        : colors.entryPrimaryFontSize,
      fontWeight: 400
    },
    h1: { color: colors.entryPrimaryHighlight, fontWeight: 700 },
    h2: { color: colors.entryPrimaryHighlight, fontWeight: 700 },
    h3: { color: colors.entryPrimaryHighlight, fontWeight: 700 },
    h4: { color: colors.entryPrimaryHighlight, fontWeight: 700 },
    h5: { color: colors.entryPrimaryHighlight, fontWeight: 700 },
    h6: {
      fontWeight: 700,
      color: colors.entryPrimaryHighlight,
      fontSize: isMobile ? 14 : colors.entryLogNameFontSize
    },
    subtitle1: {
      fontSize: isMobile ? 12 : 26,
      color: colors.entryPrimaryText
    },
    subtitle2: {
      color: colors.entryPrimaryHighlight,
      fontSize: isMobile ? 14 : colors.entryLogNameFontSize,
      fontWeight: 400
    },
    code: { color: colors.entryPrimaryText },
    color: colors.entryPrimaryText
  },
  palette: {
    primary: {
      main: colors.secondaryBackground
    },
    secondary: {
      main: colors.entryPrimaryText,
      contrastText: colors.entryPrimaryText
    },
    background: {
      default: colors.primaryBackground
    }
  }
});

export default entryTheme;
