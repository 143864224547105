import React from "react";
import { Container, Box, Typography, AppBar, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import logo from "../../assets/macbook_air.png";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";
import { isMobile } from "react-device-detect";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

import YellowButton from "../Library/LandingYellowButton";
import Features from "./Features";
import Focus from "./Focus";
import Subscriptions from "../Library/Subscriptions";

import ColorManager, { colorThemes } from "../Library/ColorManager";

import { getCurrentUserId } from "../Library/Helper";
import MobileButton from "./MobileButton";

const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

export default class Landing extends React.Component {
  componentDidMount() {
    const currentUserId = getCurrentUserId();

    if (currentUserId) {
      this.props.history.push(ROUTES.HOME);
    }
  }

  render() {
    return (
      <Container
        maxWidth={false}
        style={{
          padding: 0,
          margin: 0
        }}
      >
        <Helmet>
          <title>SimpleLog3 - best journal, diary, and log</title>
          <meta
            name="description"
            content="Need a journal, log, or diary? SimpleLog3, the last log you'll ever need. Journal, Diary, Logging made simple."
          />
        </Helmet>

        <Container
          maxWidth={false}
          style={{
            height: isMobile ? 820 : 900,
            padding: 0,
            margin: 0,
            textAlign: "center",
            backgroundColor: colors.secondaryBackground,
            clipPath: "polygon(0 0, 100% 0, 100% 80%, 0 100%)"
          }}
        >
          <MobileButton color={colors.dialogBackground} />
          <Typography
            variant="h1"
            style={{
              color: colors.dialogBackground,
              paddingTop: 32,
              fontSize: isMobile ? 45 : 60,
              fontWeight: "700",
              paddingLeft: 24,
              paddingRight: 24
            }}
          >
            The Simple Journal, Diary, and Log
          </Typography>
          <Typography
            style={{
              fontSize: isMobile ? 26 : 36,
              marginTop: 35,
              fontWeight: "500",
              color: colors.dialogBackground,
              paddingLeft: 24,
              paddingRight: 24
            }}
          >
            SimpleLog<sup>3</sup> is a logging and journal application focused
            on speed, simplicity, and utility. Track it with SimpleLog.
          </Typography>

          <YellowButton
            variant="contained"
            style={{ marginTop: 24, width: 240 }}
            color="inherit"
          >
            <Link
              style={{
                color: colors.secondaryBackground,
                textDecoration: "none"
              }}
              to={ROUTES.REGISTER}
            >
              Try it free
            </Link>
          </YellowButton>
        </Container>
        <Box
          style={{
            left: "50%",
            marginLeft: isMobile ? -190 : -500,
            top: isMobile ? 720 : 450,
            position: "absolute",
            textAlign: "center"
          }}
        >
          <img
            style={{ width: isMobile ? 400 : 1000 }}
            src={logo}
            alt="Simple Log Logo"
          />
        </Box>
        <Container
          maxWidth={false}
          style={{
            width: "100%",
            marginTop: 54,
            paddingTop: 24,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
            textAlign: "center",
            backgroundColor: colors.landingAccentColor
          }}
        >
          <Typography
            style={{
              fontSize: isMobile ? 40 : 52,
              fontWeight: "700",
              color: colors.secondaryBackground
            }}
          >
            Keep your entries organized
          </Typography>
          <Typography
            style={{
              paddingTop: 24,
              fontSize: isMobile ? 24 : 32,
              fontWeight: "500",
              color: colors.secondaryBackground
            }}
          >
            Fitness logs, travel logs, daily diaries, or customer calls.
            SimpleLog is great for tracking it all. Simple interface, search,
            templates and form entry all make it easy to keep track of daily
            events.
          </Typography>
          <Typography
            style={{
              paddingTop: 24,
              fontSize: isMobile ? 24 : 32,
              fontWeight: "700",
              color: colors.secondaryBackground
            }}
          >
            Keep track of it all
          </Typography>

          <YellowButton
            variant="contained"
            style={{ marginTop: 48, width: 240 }}
            color="inherit"
          >
            <Link
              style={{
                color: colors.secondaryBackground,
                textDecoration: "none"
              }}
              to={ROUTES.REGISTER}
            >
              Register Now
            </Link>
          </YellowButton>

          <MobileButton />

          <Focus />
          <Features />

          <Container maxWidth="md" style={{ paddingBottom: 54 }}>
            <Container maxWidth="xs">
              <Typography
                style={{
                  fontSize: 44,
                  fontWeight: "bold",
                  color: colors.secondaryBackground,
                  textAlign: "center",
                  marginBottom: 24
                }}
              >
                Our Plans
              </Typography>
            </Container>
            <Subscriptions plans={this.props.plans} />
          </Container>
        </Container>

        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="simpleLogCookieAgree"
          style={{ background: colors.secondaryBackground }}
          buttonStyle={{
            color: colors.secondaryBackground,
            fontSize: "13px",
            backgroundColor: colors.primaryHighlight,
            borderRadius: 8
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience. You can read
          our{" "}
          <a style={{ color: "white" }} href="./help/privacy">
            privacy policy
          </a>{" "}
          and{" "}
          <a style={{ color: "white" }} href="./help/terms">
            terms of service
          </a>{" "}
          here. Tap OK if you agree.
        </CookieConsent>

        <AppBar
          position="sticky"
          style={{
            height: 140,
            backgroundColor: colors.secondaryBackgroundBackground,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Grid
            style={{ paddingTop: 8, paddingBottom: 8 }}
            spacing={isMobile ? 1 : 6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Link to="/signin">Sign In</Link>
            </Grid>
            <Grid item>
              <Link to="/register">Register</Link>
            </Grid>
            <Grid item>
              <Link to="/help/pricing">Pricing</Link>
            </Grid>
            <Grid item>
              <Link to="/help/about">About</Link>
            </Grid>
            <Grid item>
              <Link to="/help">Help</Link>
            </Grid>
            <Grid item>
              <Link to="/contact">Contact us</Link>
            </Grid>
            <Grid item>
              <Link to="/help/terms">Terms of Service</Link>
            </Grid>
            <Grid item>
              <Link to="/help/privacy">Privacy</Link>
            </Grid>
          </Grid>

          <Box style={{ marginTop: 12 }}>
            <FacebookShareButton
              style={{ marginRight: 8 }}
              quote="Check out SimpleLog3 - online journal/diary."
              url="https://simplelog3.com"
            >
              <FacebookIcon size={42} round />
            </FacebookShareButton>
            <TwitterShareButton
              style={{ marginRight: 8 }}
              title="Check out SimpleLog3 - a cool online journal/diary."
              url="https://simplelog3.com"
            >
              <TwitterIcon size={42} round />
            </TwitterShareButton>
          </Box>
          <Box style={{ marginTop: 4, paddingTop: 8, paddingBottom: 8 }}>
            <Typography
              style={{
                marginLeft: 24,
                marginRight: 24,
                fontSize: isMobile ? 8 : 14
              }}
            >
              <Link to="/">SimpleLog3</Link> &copy; Copyright 2020 - Barry
              Velasquez. All rights reserved.
            </Typography>
          </Box>
        </AppBar>
      </Container>
    );
  }
}
