import React from "react";
import uuid from "react-native-uuid";
import FlatList from "flatlist-react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import * as ROUTES from "../../constants/routes";
import QuickEntry from "../QuickEntries/QuickEntry";
import { Container, Typography, Grid, IconButton } from "@material-ui/core";
import YellowButton from "../Library/YellowButton";
import QuickEntryEdit from "./QuickEntryEdit";
import AlertDialog from "../Library/AlertDialog";
import pageTheme from "../Library/PageTheme";
import colors from "../Library/Colors";
import { checkPlan, getCurrentUserId } from "../Library/Helper";
import firebaseApp from "../Firebase/firebase";

const simplelib = require("@bvelasquez/simple-log-lib");

const styles = {
  dialogPaper: {
    minHeight: "600",
    maxHeight: "80vh"
  }
};

const EmptyData = props => {
  return (
    <Container maxWidth="sm">
      <Box>
        <Typography
          align="center"
          variant="h5"
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          You have no templates
        </Typography>

        <Typography align="center" variant="h6">
          To get started, click the + button at the upper right and add your
          first template.
        </Typography>

        <Box
          component="div"
          maxWidth="xs"
          style={{ textAlign: "center", marginTop: 24 }}
        >
          <YellowButton
            variant="contained"
            color="primary"
            align="center"
            onClick={() => {
              props.addClick();
            }}
          >
            Add a Template now
          </YellowButton>
        </Box>
      </Box>
    </Container>
  );
};

class QuickEntries extends React.Component {
  constructor(props) {
    super(props);

    this.selectedValue = null;

    this.selectedFields = [];

    this.state = {
      quickData: null,
      logData: [],
      open: false,
      fabOpen: false,
      alertOpen: false,
      alertTitle: "",
      alertMessage: ""
    };

    this.actions = [
      {
        id: 0,
        icon: <AddIcon />,
        name: "Add Template",
        action: this.handleAddEntryButton
      }
    ];
  }

  setupLogData = () => {
    simplelib.FirebaseSnapshots.logs(this.logObserver, docs => {
      this.setState({ logData: docs });
    });
  };

  setupQuickData = () => {
    const plan = checkPlan();

    simplelib.FirebaseSnapshots.quickEntries(this.quickObserver, docs => {
      this.setState({ quickData: plan ? docs : docs.slice(0, 2) });
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    firebaseApp.analytics().logEvent("page_view", {
      page_location: "templates",
      page_path: "/templates",
      page_title: "Templates"
    });

    const currentUserId = getCurrentUserId();
    simplelib.SetUserId(currentUserId);
    simplelib.SetFirebase(firebaseApp);

    this.setupQuickData();
    this.setupLogData();

    this.authSubscription = firebaseApp.auth().onAuthStateChanged(user => {
      if (!user) {
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("logId");
        this.props.history.push(ROUTES.LANDING);
      } else {
        localStorage.setItem("currentUserId", user.uid);
      }
    });
  }

  componentWillUnmount() {
    if (this.quickObserver) {
      this.quickObserver();
    }
    if (this.logObserver) {
      this.logObserver();
    }
    if (this.authSubscription) {
      this.authSubscription();
    }
  }

  showAlert = (title, message) => {
    this.setState({
      alertOpen: true,
      alertTitle: title,
      alertMessage: message
    });
  };

  handleSave = () => {
    this.onSubmit(() => {
      this.setState({ open: false, fabOpen: false });
    });
  };

  onSubmit = completion => {
    let id = uuid.v4();
    if (this.selectedValue) {
      id = this.selectedValue.id;
    }

    simplelib.FirebaseFunctions.saveQuickEntry(id, {
      id: id,
      name: this.selectedName,
      title: this.selectedTitle,
      body: this.selectedBody,
      log: this.selectedLog,
      updatedAt: new Date(),
      fields: this.selectedFields
    })
      .then(() => {
        completion();
      })
      .catch(e => {
        completion();
      });
  };

  handleAddEntryButton = () => {
    const plan = checkPlan();
    if (!plan) {
      if (!this.state.quickData) {
        return;
      }

      const freeValue = firebaseApp.remoteConfig().getValue("free_templates");
      console.log("free templates", freeValue.asNumber());
      if (
        this.state.quickData &&
        this.state.quickData.length >= freeValue.asNumber()
      ) {
        firebaseApp.analytics().logEvent("need_subscription_alert");
        this.showAlert(
          "Subscription",
          "You need a paid subscription to add more Templates. Templates are a great way to create new entries with a specific format fast. Sign up, and create as many templates as you like."
        );
        return;
      }
    }

    firebaseApp.analytics().logEvent("add_template");

    this.selectedValue = null;
    this.selectedTitle = "";
    this.selectedBody = "";
    this.selectedName = "";
    this.selectedLog = "default";
    this.selectedFields = [];
    this.setState({
      open: true,
      fabOpen: false
    });
  };

  didEditEntry = value => {
    this.selectedValue = value;
    this.selectedTitle = "";
    this.selectedBody = "";
    this.selectedName = "";
    this.selectedLog = "default";
    this.selectedFields = [];
    if (value) {
      this.selectedTitle = value.title;
      this.selectedBody = value.body;
      this.selectedName = value.name;
      this.selectedLog = value.log ? value.log : "default";
      this.selectedFields = value.fields ? value.fields : [];
    }
    this.setState({
      open: true
    });
  };

  handleFabOpen = () => {
    this.setState({ fabOpen: true });
  };

  handleFabClose = () => {
    this.setState({ fabOpen: false });
  };

  renderTitleArea = () => {
    return (
      <Grid container alignItems="center">
        <Grid item md={11}>
          <Typography variant="h4" align="left">
            Templates
          </Typography>
        </Grid>

        <Grid item md={1} style={{ textAlign: "right" }}>
          <IconButton onClick={this.handleAddEntryButton}>
            <AddCircleIcon
              style={{
                fontSize: isMobile ? 24 : colors.primaryIconSize,
                color: colors.primaryHighlight
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { quickData, open } = this.state;

    if (quickData === null) {
      return null;
    }

    return (
      <MuiThemeProvider theme={pageTheme}>
        <Helmet>
          <title>SimpleLog3 - Templates</title>
          <meta name="description" content="SimpleLog3 templates." />
          <meta name="robots" content="noindex" />
        </Helmet>
        <CssBaseline />
        <Container
          maxWidth="md"
          style={{ height: "80%", marginTop: 25, paddingBottom: 25 }}
        >
          <Container style={{ marginBottom: 24, paddingLeft: 0 }}>
            {this.renderTitleArea()}
            <Typography
              align="left"
              style={{
                width: isMobile ? 400 : 800,
                fontSize: isMobile ? 12 : colors.featureDescriptionFontSize
              }}
            >
              Use templates to get started on a new entry fast and effectively.
              Use the + button to create a new template. Then, use the template
              when creating new entries from the Entries page.
            </Typography>
            <Link className="helpImproveLink" to="/contact">
              Help us improve. Give us feedback
            </Link>
          </Container>

          <FlatList
            style={{ height: "100%" }}
            list={quickData}
            renderItem={(item, idx) => {
              const value = item.data();
              return (
                <QuickEntry
                  key={value.id}
                  value={value}
                  history={this.props.history}
                  logs={this.state.logData}
                  didEdit={this.didEditEntry}
                ></QuickEntry>
              );
            }}
            renderWhenEmpty={() => {
              return (
                <EmptyData
                  addClick={() => {
                    this.handleAddEntryButton();
                  }}
                />
              );
            }}
          />

          <QuickEntryEdit
            open={open}
            name={this.selectedName}
            title={this.selectedTitle}
            body={this.selectedBody}
            log={this.selectedLog}
            logs={this.state.logData}
            fields={this.selectedFields}
            isEdit={this.selectedValue ? true : false}
            didSave={data => {
              this.selectedName = data.name;
              this.selectedTitle = data.title;
              this.selectedBody = data.body;
              this.selectedFields = data.fields;
              this.selectedLog = data.log;
              this.handleSave();
            }}
            didClose={() => {
              this.setState({ open: false });
            }}
          />

          <AlertDialog
            open={this.state.alertOpen}
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            handleClose={() => {
              this.setState({ alertOpen: false });
            }}
            handleNavigation={() => {
              // navigate to account.
              console.log("HANDLE NAVIGATION");
              this.props.history.push(ROUTES.ACCOUNT);
            }}
          />
        </Container>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(QuickEntries);
