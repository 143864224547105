import React from "react";

import Box from "@material-ui/core/Box";
import LogSelection from "./LogSelection";

const LogTitle = props => {
  return (
    <Box>
      <Box style={{ textAlign: "left" }}>
        <LogSelection
          log={props.logId}
          logs={props.logs}
          update={props.update}
          selected={props.selected}
        />
      </Box>
    </Box>
  );
};

export default LogTitle;
