import React from "react";
import { Typography, Box, Container } from "@material-ui/core";

const FilterHelp = props => {
  return (
    <Container maxWidth="md" style={{ height: "80%", marginTop: 25 }}>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">Filters</Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          What are Filters?
        </Typography>
        <Typography gutterBottom variant="body1">
          Filters let you create searches for specific entries by keyword,
          hashtag, or using Regular Expressions. You can then quickly use these
          filters to find entries.
        </Typography>
        <Typography gutterBottom variant="body1">
          Filters consist of the name, title, body, regular expression, and
          template field. The search works by first finding all entries matching
          the title and body you enter for the filter. It then filters those
          results using the regular expression you provide, narrowing the list
          down further.
        </Typography>
        <Typography gutterBottom variant="h5">
          Charts
        </Typography>
        <Typography gutterBottom variant="body1">
          After you run a filter, you can also see a chart representing key data
          within the entries returned by the filter. The chart will either show
          a count of entries by date, or if you select a "Template Field" when
          creating the filter, will use the data in that field as the value
          charted.
        </Typography>
        <Typography gutterBottom variant="h5">
          Exports
        </Typography>
        <Typography gutterBottom variant="body1">
          The filtered list of entries can be exported into CSV and downloaded.
          The title, body, date of creation, and any template data is included
          in the export.
        </Typography>
      </Box>
    </Container>
  );
};

export default FilterHelp;
