import React from "react";
import { Box, Tooltip, Grid, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RestoreIcon from "@material-ui/icons/Restore";
import NavigationButton from "../Library/NavigationButton";
import colors from "../Library/Colors";

const LogNavigation = props => {
  return (
    <Box style={{ marginBottom: isMobile ? 14 : 24 }}>
      <Grid spacing={1} container alignItems="center">
        <Grid item sm="auto">
          <Tooltip title="Select the date range to view. Entries will be limited by this range.">
            <DateRangeIcon
              style={{
                fontSize: isMobile ? 24 : colors.secondaryIconSize,
                marginRight: 8,
                color: colors.primaryHighlight
              }}
              onClick={() => {
                if (props.handleDateSelector) {
                  props.handleDateSelector();
                }
              }}
            ></DateRangeIcon>
          </Tooltip>
          <Tooltip title="Show today with default date range of 14 days.">
            <RestoreIcon
              style={{
                fontSize: isMobile ? 24 : colors.secondaryIconSize,

                color: colors.primaryHighlight
              }}
              onClick={() => {
                if (props.handleRestore) {
                  props.handleRestore();
                }
              }}
            ></RestoreIcon>
          </Tooltip>
        </Grid>

        <Grid item sm={7}>
          <Typography
            variant="subtitle1"
            style={{
              fontSize: isMobile ? 14 : colors.dateRangeFontSize,
              verticalAlign: "center",
              paddingTop: 0,
              marginLeft: 0,
              textAlign: "left",
              marginRight: 4
            }}
          >
            {`Showing ${props.timeWindowStrings.start} to ${props.timeWindowStrings.end}`}
          </Typography>
        </Grid>
        <Grid item sm="auto">
          <NavigationButton
            style={{ marginRight: 12 }}
            variant="outlined"
            onClick={() => {
              if (props.handleNewer) {
                props.handleOlder();
              }
            }}
          >
            {isMobile ? "Prev" : "Previous"}
          </NavigationButton>
          <NavigationButton
            variant="outlined"
            onClick={() => {
              if (props.handleNewer) {
                props.handleNewer();
              }
            }}
          >
            Next
          </NavigationButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogNavigation;
