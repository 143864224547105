import { isTablet, isBrowser } from "react-device-detect";

const moment = require("moment");
const simplelib = require("@bvelasquez/simple-log-lib");
const Handlebars = require("handlebars");

simplelib.SetHandlebars(Handlebars);

export const getCurrentUserId = () => {
  let currentUserId = null;
  if (localStorage) {
    try {
      currentUserId = localStorage.getItem("currentUserId");
    } catch {
      currentUserId = null;
    }
  }
  return currentUserId;
};

export const checkPlan = () => {
  const plan = localStorage.getItem("plan");

  if (plan) {
    return true;
  }
  return false;
};

export const dateToFromNowDaily = (date, precise = false, showDate = false) => {
  // get from-now for this date

  if (!precise) {
    // ensure the date is displayed with today and yesterday
    return moment(date).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: "dddd",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      // when the date is further away, use from-now functionality
      sameElse: "llll"
    });
  }
  const fromNow = moment(date).fromNow();
  // console.log("From Now", fromNow);
  // ensure the date is displayed with today and yesterday
  const dateString = moment(date).format("llll");

  return `${showDate ? `${dateString} - ` : ``}${fromNow}`;
};

export const getBodyText = entry => {
  const reHash = /(?:|^)?#[A-Za-z0-9\-._]+(?:|$)/g;

  const references = [];

  let finalBody = entry.body;
  try {
    finalBody = simplelib.buildBody(entry);
  } catch (e) {
    console.log("Error building body", e, entry.id);
  }

  finalBody = finalBody.replace(reHash, hash => {
    const strippedHash = hash.replace("#", "").trim();
    const finalString = `[${hash.trim()}][${strippedHash}]`;
    references.push(`[${strippedHash}]: ./entries?hashtag=${strippedHash}`);
    return finalString;
  });

  const appendedBody = finalBody.concat("\n\n").concat(references.join("\n"));

  return appendedBody;
};

export const showFullSize = () => {
  if (isBrowser || isTablet) {
    return true;
  }
  return false;
};
