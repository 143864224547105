import React from "react";
import uuid from "react-native-uuid";
import Hotkeys from "react-hot-keys";
import FlatList from "flatlist-react";
import Snackbar from "@material-ui/core/Snackbar";
import { Helmet } from "react-helmet";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddIcon from "@material-ui/icons/Add";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Tooltip from "@material-ui/core/Tooltip";
import IdleTimer from "react-idle-timer";
import { filter } from "underscore";

import * as ROUTES from "../../constants/routes";
import Entry from "../Home/Entry";
import {
  Container,
  Button,
  Grid,
  Typography,
  IconButton
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import firebaseApp from "../Firebase/firebase";

import FilterDialog from "./FilterDialog";
import QuickEntryFields from "./QuickEntryFields";
import EntryEdit from "./EntryEdit";
import AlertDialog from "../Library/AlertDialog";
import NewDateSelector from "./NewDateSelector";
import CSVExportDialog from "../Library/CSVExportDialog";
import pageTheme from "../Library/PageTheme";
import colors from "../Library/Colors";
import LogTitle from "./LogTitle";
import PasswordEntry from "./PasswordEntry";
import FilterChartsV2 from "./FilterChartsV2";
import { checkPlan, getCurrentUserId } from "../Library/Helper";
import SearchBar from "./SearchBar";
import LogNavigation from "./LogNavigation";
import EmptyData from "./EmptyData";
import QRCodeDialog from "./QRCodeDialog";

import Fuse from "fuse.js";

const queryString = require("query-string");
const simplelib = require("@bvelasquez/simple-log-lib");
const moment = require("moment");
const underscore = require("underscore");
const crypto = require("crypto");
// const nanoid = require("nanoid");

const styles = {
  dialogPaper: {
    minHeight: "600",
    maxHeight: "80vh"
  }
};

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const defaultDateRange = 30; // days

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);

    let url = props.location.search;
    let params = queryString.parse(url);

    this.searchTimer = null;
    this.selectedValue = null;
    this.showBookmarks = false;

    const currentUserId = getCurrentUserId();

    if (!currentUserId) {
      this.props.history.push(ROUTES.LANDING);
      return;
    }
    this.entriesCollectionRef = simplelib.GetEntryCollectionRef(currentUserId);

    let logId = "default";
    localStorage.removeItem("decryptionPassword");
    if (params && params.logId) {
      localStorage.setItem("logId", params.logId);
      logId = params.logId;
    } else {
      const storedLogId = localStorage.getItem("logId");
      if (storedLogId) {
        logId = storedLogId;
      }
    }

    this.state = {
      data: null,
      defaultValue: null,
      searchData: [],
      quickData: [],
      logData: [],
      open: false,
      fabOpen: false,
      selectedFields: [],
      advancedSearch: false,
      advancedSearchName: "",
      filterDialogOpen: false,
      quickDialogOpen: false,
      quickFieldsOpen: false,
      logDialogOpen: false,

      selectedLog: logId,

      errorOpen: false,
      errorMessage: "",

      alertOpen: false,
      alertTitle: "",
      alertMessage: "",

      timeWindowStrings: "",
      dateSelectorOpen: false,
      passwordOpen: false,

      chartsOpen: false,

      csvOpen: false,
      csvData: [],

      qrCodeDialogOpen: false
    };

    this.shortcuts = [
      {
        command: "ctrl+n",
        function: this.handleAddEntryButton
      },
      {
        command: "ctrl+q",
        function: this.handleQuickEntry
      },
      {
        command: "ctrl+f",
        function: this.handleQuickFilter
      },
      {
        command: "ctrl+l",
        function: this.handleLogs
      },
      {
        command: "ctrl+b",
        function: this.handleBookmarkSearch
      }
    ];

    this.actions = [
      {
        id: 0,
        icon: <AddIcon />,
        name: "Add new Entry",
        action: this.handleAddEntryButton
      },
      {
        id: 1,
        icon: <BookmarkBorderIcon />,
        name: "Bookmarks",
        action: this.handleBookmarkSearch
      },
      {
        id: 2,
        icon: <FlashOnIcon />,
        name: "Use a Template",
        action: this.handleQuickEntry
      },
      {
        id: 3,
        icon: <SearchIcon />,
        name: "Quick Filter",
        action: this.handleQuickFilter
      }
    ];
  }

  getTopRecord = async (ref, logId, completion) => {
    const results = await ref
      .where("log", "==", logId)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();

    if (completion) {
      if (results.docs.length > 0) {
        completion(results.docs[0].data());
      } else {
        completion(null);
      }
    }
  };

  onKeyUp = (keyName, e, handle) => {
    //
  };

  onKeyDown = (keyName, e, handle) => {
    const command = this.shortcuts.find(f => {
      return f.command === keyName;
    });
    if (command) {
      command.function();
    }
  };

  showError = error => {
    this.setState({ errorOpen: true, errorMessage: error });
  };

  setupSearchData = () => {
    simplelib.FirebaseSnapshots.filters(this.searchObserver, docs => {
      this.setState({ searchData: docs });
    });
  };

  setupQuickData = () => {
    simplelib.FirebaseSnapshots.quickEntries(this.quickObserver, docs => {
      this.setState({ quickData: docs });
    });
  };

  setupLogData = completion => {
    simplelib.FirebaseSnapshots.logs(this.logObserver, docs => {
      const finalDocs = docs.filter(d => {
        const data = d.data();

        return !data.archived && data.id !== "default";
      });

      this.setState({ logData: finalDocs }, () => {
        completion();
      });
    });
  };

  /**
   * This happens whenever we get a subscription update from firebase.
   * @param {} stateUpdate
   */
  handleStateChange = stateUpdate => {
    var newData = underscore.map(stateUpdate.data, d => {
      return d.data();
    });

    stateUpdate.data = newData;

    if (this.search) {
      const fuse = new Fuse(newData, {
        threshold: 0.2,
        distance: 2000,
        minMatchCharLength: 3,
        shouldSort: false,
        useExtendedSearch: true,
        ignoreLocation: true,
        includeScore: true,
        includeMatches: true,
        keys: ["title", "body", "keywords"]
      });
      const fuseResult = fuse.search(this.search);
      console.log("Fuse Result", fuseResult);
      const result = fuseResult.map(i => {
        return i.item;
      });

      newData = result;
      stateUpdate.data = result;
      // this.search = null;
    } else {
      this.search = null;
    }

    this.setState(stateUpdate);
  };

  filterByRegEx = (filterSearch, data) => {
    const expression = filterSearch
      ? filterSearch.expression
        ? filterSearch.expression.trim()
        : undefined
      : undefined;

    let finalData = data;
    if (expression && expression.length > 0) {
      var re = new RegExp(`${expression}`, "gi");

      finalData = filter(data, d => {
        const data = d.data();

        return data.body.match(re) || data.title.match(re);
      });
    }
    return finalData;
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    firebaseApp.analytics().logEvent("page_view", {
      page_location: "entries",
      page_path: "/entries",
      page_title: "Entries"
    });

    let url = this.props.location.search;
    let match = this.props.match.params;
    let params = queryString.parse(url);

    let searchText = null;
    if (match.hashTag) {
      searchText = `#${match.hashTag}`;
    } else if (params.hashtag) {
      searchText = `#${params.hashtag}`;
    }

    if (match.search) {
      searchText = `${match.search}`;
    } else if (params.search) {
      searchText = `${params.search}`;
    }

    if (searchText && searchText.length > 0) {
      this.setState({ defaultValue: searchText });
    }

    let logId = "default";
    if (params && params.logId) {
      localStorage.setItem("logId", params.logId);
      logId = params.logId;
    } else {
      const storedLogId = localStorage.getItem("logId");
      if (storedLogId) {
        logId = storedLogId;
      }
    }

    this.getTopRecord(this.entriesCollectionRef, logId, record => {
      let topDate = new Date();
      if (record) {
        topDate = record.createdAt.toDate();
      }

      const currentUserId = getCurrentUserId();

      this.limiter = new simplelib.FirebaseLimiter();
      this.limiter.getCurrentTimeWindowWithDate(defaultDateRange, topDate);
      this.limiterConfiguration = {
        userId: currentUserId,
        logId: logId,
        limiter: this.limiter,
        ref: this.entriesCollectionRef,
        onStateChange: this.handleStateChange
      };

      this.setState({ timeWindowStrings: this.limiter.getTimeWindowString() });

      this.setupSearchData();
      this.setupQuickData();
      this.setupLogData(() => {
        simplelib.SetupEntryData(this.limiterConfiguration, searchText);
      });
    });

    this.authSubscription = firebaseApp.auth().onAuthStateChanged(user => {
      if (!user) {
        localStorage.removeItem("decryptionPassword");
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("logId");
        this.props.history.push(ROUTES.LANDING);
      } else {
        localStorage.setItem("currentUserId", user.uid);
        localStorage.setItem("logId", logId);
      }
    });
  }

  componentWillUnmount() {
    if (this.snapshotRef) {
      this.snapshotRef();
    }
    if (this.searchObserver) {
      this.searchObserver();
    }
    if (this.quickObserver) {
      this.quickObserver();
    }
    if (this.logObserver) {
      this.logObserver();
    }
    if (this.authSubscription) {
      this.authSubscription();
    }

    localStorage.removeItem("decryptionPassword");
  }

  _onAction(e) {
    // console.log("user did something", e);
  }

  _onActive(e) {
    // console.log("user is active", e);
    // console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  _onIdle(e) {
    const decryptionPassword = localStorage.getItem("decryptionPassword");
    if (decryptionPassword) {
      localStorage.removeItem("decryptionPassword");
      this.setLog("default");
    }
  }

  showAlert = (title, message, hideSubscribeButton = false) => {
    this.setState({
      alertOpen: true,
      alertTitle: title,
      alertMessage: message,
      alertHideSubscribeButton: hideSubscribeButton
    });
  };

  handleSave = async () => {
    const result = await this.onSubmit();
    console.log("Saved entry, result", result);

    this.setState({ open: false, fabOpen: false });
    return true;
  };

  onSubmit = async () => {
    let id = uuid.v4();
    if (this.selectedValue) {
      id = this.selectedValue.id;
    }

    const log = this.selectedLog ? this.selectedLog : "default";

    var title;
    var body;

    if (!this.selectedValue) {
      try {
        console.log("Creating entry.");
        const decryptionPassword = localStorage.getItem("decryptionPassword");
        title = this.selectedTitle;
        body = this.selectedBody;
        if (decryptionPassword) {
          // encrypt data.
          try {
            title = title
              ? simplelib.encrypt(title, decryptionPassword, log, crypto)
              : "";
            body = body
              ? simplelib.encrypt(body, decryptionPassword, log, crypto)
              : "";
          } catch (e) {
            console.log("Exception with encryption", e);
            return;
          }
        }
        await simplelib.FirebaseFunctions.saveEntry(id, {
          id: id,
          title: title,
          body: body,
          createdAt: this.selectedDate,
          updatedAt: this.selectedDate,
          log: log,
          fieldData: this.fieldData ? this.fieldData : [],
          templateId: this.selectedTemplateId ? this.selectedTemplateId : null,
          latitude: 0.0,
          longitude: 0.0,
          image: null,
          archived: false,
          isVaulted: decryptionPassword ? true : false
        });
        this.selectedTemplateId = null;

        console.log("Done Creating entry.");

        this.getTopRecord(this.entriesCollectionRef, log, record => {
          let topDate = new Date();
          if (record) {
            topDate = record.createdAt.toDate();
          }
          this.limiter.getCurrentTimeWindowWithDate(defaultDateRange, topDate);
          if (log !== localStorage.getItem("logId")) {
            localStorage.setItem("logId", log);
            console.log("Changed log, reloading data.");
            this.limiterConfiguration.logId = log;
          }
          simplelib.SetupEntryData(this.limiterConfiguration);
        });
      } catch (error) {
        this.showError("There was a problem saving your changes.");
      }
    } else {
      try {
        const decryptionPassword = localStorage.getItem("decryptionPassword");
        title = this.selectedTitle;
        body = this.selectedBody;
        if (decryptionPassword) {
          // encrypt data.
          try {
            title = title
              ? simplelib.encrypt(title, decryptionPassword, log, crypto)
              : "";
            body = body
              ? simplelib.encrypt(body, decryptionPassword, log, crypto)
              : "";
          } catch (e) {
            console.log("Error encrypting data", e);
            return;
          }
        }

        await simplelib.FirebaseFunctions.saveEntry(id, {
          id: this.selectedValue.id,
          title: title,
          body: body,
          createdAt: this.selectedDate,
          updatedAt: new Date(),
          log: log,
          fieldData: this.fieldData ? this.fieldData : [],
          latitude: this.selectedValue.latitude,
          longitude: this.selectedValue.longitude,
          isVaulted: decryptionPassword ? true : false
        });
      } catch (error) {
        this.showError("There was a problem saving your changes.");
      }
    }
    return true;
  };

  handleLoadMore = () => {
    firebaseApp.analytics().logEvent("load_more_entries");

    this.limiter.moveLaterAuto();
    simplelib.SetupEntryData(this.limiterConfiguration);
  };

  handleLoadPrevious = () => {
    firebaseApp.analytics().logEvent("load_previous_entries");

    this.limiter.moveEarlierAuto();
    simplelib.SetupEntryData(this.limiterConfiguration);
  };

  handleDateSelector = () => {
    firebaseApp.analytics().logEvent("date_selection_dialog");

    this.setState({ dateSelectorOpen: true });
  };

  handlePaging = page => {};

  handleAddEntryButton = () => {
    firebaseApp.analytics().logEvent("add_entry");

    const logId = localStorage.getItem("logId");

    console.log("selected log", logId);

    const plan = checkPlan();
    if (logId !== "default" && !plan) {
      this.showAlert(
        "Subscription",
        "You need a paid subscription to add more entries to an extra log. Logs are a great way to organize your entries by type. Keep a log of travel, or one for your workouts. Sign up today to add entries to this log."
      );
      return;
    }

    this.selectedValue = null;
    this.selectedTitle = "";
    this.selectedBody = "";
    this.selectedDate = new Date();
    this.selectedLog = logId;
    this.fieldData = [];
    this.setState({
      open: true,
      fabOpen: false
    });
  };

  didEditFields = value => {
    // Editing fields.
    const plan = checkPlan();
    if (value.log !== "default" && !plan) {
      this.showAlert(
        "Subscription",
        "You need a paid subscription to edit entries in an extra log. Logs are a great way to organize your entries by type. Keep a log of travel, or one for your workouts. Sign up today to edit entries in this log."
      );
      return;
    }

    console.log("Editing fields", value.fieldData);

    this.selectedTemplateName = "Edit Fields";
    this.selectedValue = value;
    this.selectedFields = value.fieldData;
    this.selectedTitle = value.title;
    this.selectedBody = value.body;
    this.selectedLog = value.log;
    this.selectedDate = value.createdAt;

    this.setState({ quickFieldsOpen: true });
  };

  didEditEntry = value => {
    const plan = checkPlan();
    if (value.log !== "default" && !plan) {
      this.showAlert(
        "Subscription",
        "You need a paid subscription to edit entries in an extra log. Logs are a great way to organize your entries by type. Keep a log of travel, or one for your workouts. Sign up today to edit entries in this log."
      );
      return;
    }

    this.selectedValue = value;
    this.selectedTitle = "";
    this.selectedBody = "";
    this.selectedDate = new Date();
    this.selectedLog = "default";
    this.fieldData = [];
    if (value) {
      const decryptionPassword = localStorage.getItem("decryptionPassword");

      var title = value.title;
      var body = value.body;
      if (decryptionPassword) {
        // encrypt data.
        try {
          title = simplelib.decrypt(
            title,
            decryptionPassword,
            value.log,
            crypto
          );
          body = simplelib.decrypt(body, decryptionPassword, value.log, crypto);
        } catch (e) {
          return;
        }
      }

      this.selectedTitle = title;
      this.selectedBody = body;
      this.selectedDate = value.createdAt.toDate();
      this.selectedLog = value.log;
      this.fieldData = value.fieldData;
    }
    this.setState({
      open: true,
      selectedLog: this.selectedLog
    });
  };

  setBookmarkMenu = () => {
    const action = this.actions.find(e => {
      return e.id === 1;
    });

    if (action) {
      if (this.showBookmarks) {
        action.icon = <BookmarkIcon />;
      } else {
        action.icon = <BookmarkBorderIcon />;
      }
    }
  };

  handleBookmarkSearch = () => {
    this.showBookmarks = !this.showBookmarks;
    this.setBookmarkMenu();
    simplelib.SetupEntryData(
      this.limiterConfiguration,
      null,
      null,
      this.showBookmarks
    );
  };

  handleExportCSV = () => {
    // Export CSV
    console.log("Export CSV");

    const plan = checkPlan();

    if (!plan) {
      this.showAlert(
        "Subscription",
        "You need a paid subscription to export to CSV. Subscribe today to export your entries to CSV."
      );
      return;
    }

    firebaseApp.analytics().logEvent("export_csv");

    const filterData = this.selectedFilterData;

    console.log("Filter Data", filterData);

    let headers = [
      { label: "Title", key: "title" },
      { label: "Body", key: "body" },
      { label: "Created", key: "createdAt" }
    ];

    let yFields;

    // Build data fields
    if (filterData.filter.yField && filterData.filter.yField.length > 0) {
      yFields = filterData.filter.yField.replace(" ", "").split(",");

      const series = yFields.map(function(yField) {
        return { label: yField, key: yField };
      });

      headers = headers.concat(series);
    }

    const data = this.state.data.map(d => {
      const values = d;

      let finalBody = simplelib.buildBody(values);

      const dataResult = {
        title: values.title,
        body: finalBody,
        createdAt: moment(values.createdAt.toDate()).format(
          "YYYY/MM/DD hh:mm:ss"
        )
      };

      if (yFields && yFields.length) {
        yFields.forEach(y => {
          const fieldDataValue = underscore.find(values.fieldData, function(d) {
            return d.fieldName === y;
          });
          dataResult[y] = fieldDataValue ? fieldDataValue.fieldValue : "";
        });
      }

      return dataResult;
    });

    this.setState({ csvOpen: true, csvData: data, csvHeaders: headers });
  };

  handleChartsOpen = () => {
    firebaseApp.analytics().logEvent("open_charts");

    this.setState({ chartsOpen: true });
  };

  handleChartsClosed = () => {
    this.setState({ chartsOpen: false });
  };

  handleQuickFilter = () => {
    firebaseApp.analytics().logEvent("use_quick_filter");
    this.setState({ filterDialogOpen: true });
  };

  handleLogs = () => {
    this.setState({ logDialogOpen: true });
  };

  handleQuickEntry = () => {
    firebaseApp.analytics().logEvent("use_quick_entry");

    this.setState({ quickDialogOpen: true });
  };

  handleDateChange = date => {
    this.selectedDate = date;
    this.setState({ selectedDate: date });
  };

  searchCancelled = () => {
    this.search = null;
    simplelib.SetupEntryData(this.limiterConfiguration);
  };

  searchChanged = event => {
    const value = event.target.value;

    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = underscore.delay(() => {
      if (value.length >= 3) {
        if (!this.originalData) {
          this.originalData = this.state.data;
        }
        const decryptionPassword = localStorage.getItem("decryptionPassword");
        if (decryptionPassword) {
          console.log("Doing decryption search.");
          // Do a memory filter instead.
          const dataArray = this.originalData;
          console.log(dataArray);

          const decryptedDataArray = dataArray.map(d => {
            const decryptedBody = simplelib.decrypt(
              d.body ? d.body : "",
              decryptionPassword,
              d.log,
              crypto
            );
            const decryptedTitle = simplelib.decrypt(
              d.title ? d.title : "",
              decryptionPassword,
              d.log,
              crypto
            );
            const cloned = underscore.clone(d);
            cloned.title = decryptedTitle;
            cloned.body = decryptedBody;
            return cloned;
          });

          const fuse = new Fuse(decryptedDataArray, {
            threshold: 0.2,
            distance: 2000,
            minMatchCharLength: 3,
            shouldSort: false,
            useExtendedSearch: true,
            ignoreLocation: true,
            findAllMatches: true,
            keys: ["title", "body"]
          });
          const result = fuse.search(value).map(i => {
            return i.item;
          });

          this.search = null;
          this.setState({ data: result });
        } else {
          this.originalData = null;
          console.log("Doing normal search.");
          this.search = value;
          simplelib.SetupEntryData(this.limiterConfiguration, value);
        }
      } else {
        this.originalData = null;
        this.search = null;
        simplelib.SetupEntryData(this.limiterConfiguration);
      }
    }, 500);
  };

  handleFabOpen = () => {
    //
    this.setState({ fabOpen: true });
  };

  handleFabClose = () => {
    //
    this.setState({ fabOpen: false });
  };

  handleQRCode = shareURL => {
    //
    console.log("handleQRCode", shareURL);
    this.qrCodeShareURL = shareURL;
    this.setState({ qrCodeDialogOpen: true });
  };

  setLog = logId => {
    console.log("setting log", logId);
    this.setState({ defaultValue: "", searchUpdate: uuid.v4() });

    firebaseApp.analytics().logEvent("change_log", { target: "personal" });
    localStorage.setItem("logId", logId);
    this.selectedLog = logId;
    this.search = null;
    this.limiterConfiguration.logId = logId;

    this.getTopRecord(this.entriesCollectionRef, logId, record => {
      let topDate = new Date();
      if (record) {
        topDate = record.createdAt.toDate();
      }
      this.limiter.getCurrentTimeWindowWithDate(defaultDateRange, topDate);
      console.log("SetupEntryData");
      simplelib.SetupEntryData(this.limiterConfiguration);
    });
  };

  renderSpeedDial = () => {
    const { fabOpen } = this.state;

    return (
      <Box>
        <SpeedDial
          direction="left"
          ref={input => (this.speedDialRef = input)}
          ariaLabel="SpeedDial"
          hidden={false}
          icon={<SpeedDialIcon />}
          onClose={(_event, reason) => {
            if (reason === "toggle" || reason === "escapeKeyDown") {
              this.handleFabClose();
            }
          }}
          onMouseLeave={() => {
            //
            this.handleFabClose();
          }}
          onMouseEnter={() => {
            //
            this.handleFabOpen();
          }}
          onMouseDown={() => {
            this.handleFabOpen();
          }}
          onTouchStart={() => {
            this.handleFabOpen();
          }}
          open={fabOpen}
        >
          {this.actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </SpeedDial>
      </Box>
    );
  };

  renderEntry = item => {
    return (
      <Entry
        key={item.id}
        value={item}
        plan={this.plan}
        decryptionPassword={this.decryptionPassword}
        history={this.props.history}
        logs={this.state.logData}
        didEdit={this.didEditEntry}
        didEditFields={this.didEditFields}
        handleQRCode={this.handleQRCode}
      ></Entry>
    );
  };

  render() {
    const {
      data,
      open,
      advancedSearchName,
      advancedSearch,
      logData,
      defaultValue,
      searchUpdate
    } = this.state;

    if (data === null) {
      return null;
    }

    this.plan = checkPlan();
    this.decryptionPassword = localStorage.getItem("decryptionPassword");

    return (
      <Hotkeys
        keyName={this.shortcuts
          .map(f => {
            return f.command;
          })
          .join(",")}
        onKeyDown={this.onKeyDown.bind(this)}
        onKeyUp={this.onKeyUp.bind(this)}
      >
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={simplelib.getIdleTimeout()}
        />

        <MuiThemeProvider theme={pageTheme}>
          <Helmet>
            <title>SimpleLog3 - Entries</title>
            <meta
              name="description"
              content="SimpleLog3 entries. The last log you'll ever need. Journal, Diary, logging made simple."
            />
            <meta name="robots" content="noindex" />
          </Helmet>

          <CssBaseline />
          <Container maxWidth="lg">
            <Container
              maxWidth="lg"
              style={{ marginBottom: 24, paddingLeft: 0 }}
            >
              {!advancedSearch && (
                <SearchBar
                  update={searchUpdate}
                  defaultValue={defaultValue}
                  searchChanged={this.searchChanged}
                  searchCancelled={this.searchCancelled}
                />
              )}
              {advancedSearch && (
                <Box
                  style={{
                    paddingLeft: 0,
                    textAlign: "center",
                    marginTop: 16,
                    marginBottom: 8
                  }}
                >
                  <Grid container direction="row" alignItems="center">
                    <Typography variant="h3">{advancedSearchName}</Typography>
                    <CancelIcon
                      style={{
                        marginLeft: 20,
                        color: colors.primaryHighlight,
                        fontSize: isMobile ? 24 : colors.secondaryIconSize
                      }}
                      onClick={() => {
                        this.showBookmarks = false;
                        this.setBookmarkMenu();
                        simplelib.SetupEntryData(this.limiterConfiguration);
                      }}
                    />
                    <AssessmentIcon
                      fontSize="large"
                      style={{
                        marginLeft: 20,
                        color: colors.primaryHighlight,
                        fontSize: isMobile ? 24 : colors.secondaryIconSize
                      }}
                      onClick={() => {
                        this.handleChartsOpen();
                      }}
                    />
                    <SaveAltIcon
                      fontSize="large"
                      style={{
                        marginLeft: 20,
                        color: colors.primaryHighlight,
                        fontSize: isMobile ? 24 : colors.secondaryIconSize
                      }}
                      onClick={() => {
                        this.handleExportCSV();
                      }}
                    />
                  </Grid>
                </Box>
              )}
            </Container>

            {!advancedSearch && (
              <Grid container alignItems="center" style={{ marginBottom: 24 }}>
                <Grid item md={8}>
                  <LogTitle
                    update={uuid.v4()}
                    logId={localStorage.getItem("logId")}
                    logs={logData}
                    selected={(log, logObject) => {
                      localStorage.removeItem("decryptionPassword");

                      window.scrollTo(0, 0);

                      if (log === "header") {
                        firebaseApp
                          .analytics()
                          .logEvent("change_log", { target: "header" });
                        return;
                      }
                      if (log === "create") {
                        firebaseApp
                          .analytics()
                          .logEvent("change_log", { target: "create" });
                        this.props.history.push(ROUTES.LOGS);
                      } else {
                        if (
                          log !== "default" &&
                          logObject &&
                          logObject.isVaulted
                        ) {
                          // Get password
                          this.setState({ passwordOpen: true });
                          this.potentialLog = logObject;
                        } else {
                          this.setLog(log);
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid item md={1}>
                  <IconButton onClick={this.handleBookmarkSearch}>
                    {this.showBookmarks && (
                      <Tooltip title="Hide Bookmarks">
                        <BookmarkIcon
                          style={{
                            fontSize: isMobile ? 24 : colors.secondaryIconSize,
                            color: colors.primaryHighlight
                          }}
                        />
                      </Tooltip>
                    )}
                    {!this.showBookmarks && (
                      <Tooltip title="Show Bookmarks">
                        <BookmarkBorderIcon
                          style={{
                            fontSize: isMobile ? 24 : colors.secondaryIconSize,
                            color: colors.primaryHighlight
                          }}
                        />
                      </Tooltip>
                    )}
                  </IconButton>
                </Grid>
                <Grid item md={1}>
                  <IconButton onClick={this.handleQuickFilter}>
                    <Tooltip title="Filters">
                      <SearchIcon
                        style={{
                          fontSize: isMobile ? 24 : colors.secondaryIconSize,
                          color: colors.primaryHighlight
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
                <Grid item md={1}>
                  <IconButton onClick={this.handleQuickEntry}>
                    <Tooltip title="Templates">
                      <FlashOnIcon
                        style={{
                          fontSize: isMobile ? 24 : colors.secondaryIconSize,
                          color: colors.primaryHighlight
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
                <Grid item md={1}>
                  <IconButton onClick={this.handleAddEntryButton}>
                    <Tooltip title="Add Entry">
                      <AddCircleIcon
                        style={{
                          fontSize: isMobile ? 24 : colors.primaryIconSize,
                          color: colors.primaryHighlight
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {!advancedSearch && (
              <LogNavigation
                showSearchTitle={advancedSearch}
                log={localStorage.getItem("logId")}
                timeWindowStrings={this.state.timeWindowStrings}
                logs={logData}
                titleClicked={() => {
                  this.handleLogs();
                }}
                handleOlder={() => {
                  this.setState({ defaultValue: "", searchUpdate: uuid.v4() });
                  this.handleLoadPrevious();
                }}
                handleNewer={() => {
                  this.setState({ defaultValue: "", searchUpdate: uuid.v4() });
                  this.handleLoadMore();
                }}
                handleDateSelector={() => {
                  this.handleDateSelector();
                }}
                handleRestore={() => {
                  //
                  this.setState({ defaultValue: "", searchUpdate: uuid.v4() });
                  firebaseApp.analytics().logEvent("reset_log_to_current");
                  this.limiter.getCurrentTimeWindow(defaultDateRange);
                  simplelib.SetupEntryData(this.limiterConfiguration);
                }}
              />
            )}

            <Box style={{ marginBottom: 12, marginLeft: 12 }}>
              <Link className="helpImproveLink" to="/contact">
                Help us improve. Give us feedback
              </Link>
            </Box>

            <FlatList
              list={data}
              renderOnScroll
              renderItem={this.renderEntry}
              renderWhenEmpty={() => {
                return (
                  <EmptyData
                    advancedSearch={this.state.advancedSearch}
                    timeWindowStrings={this.state.timeWindowStrings}
                    addClick={() => {
                      this.handleAddEntryButton();
                    }}
                    changeDate={() => {
                      this.handleDateSelector();
                    }}
                  />
                );
              }}
            />

            {this.state.data.length > 0 && (
              <Grid
                container
                alignItems="stretch"
                justifyContent={
                  !this.state.advancedSearch ? "space-between" : "center"
                }
                style={{
                  marginBottom: 24,
                  paddingBottom: 24
                }}
              >
                {!this.state.advancedSearch && (
                  <Grid item>
                    <Button
                      variant="text"
                      onClick={() => {
                        //
                        this.handleLoadPrevious();
                      }}
                    >
                      Previous
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Back to Top
                  </Button>
                </Grid>

                {!this.state.advancedSearch && (
                  <Grid item>
                    <Button
                      variant="text"
                      onClick={() => {
                        //
                        this.handleLoadMore();
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}

            <FilterDialog
              title="Select a Filter"
              pluralTitle="Filters"
              open={this.state.filterDialogOpen}
              searchData={
                this.plan
                  ? this.state.searchData
                  : this.state.searchData.slice(0, 2)
              }
              handleClose={() => {
                this.setState({ filterDialogOpen: false });
              }}
              manageButtonText="Manage Filters"
              handleManageButton={() => {
                this.props.history.push(ROUTES.FILTERS);
                return;
              }}
              itemSelected={data => {
                const titleArray = data.title
                  .toLocaleLowerCase()
                  .replace("\n", " ")
                  .split(" ");
                const bodyArray = data.body
                  .toLocaleLowerCase()
                  .replace("\n", " ")
                  .split(" ");

                const searchArray = titleArray.concat(bodyArray).filter(f => {
                  return f.trim().length > 0;
                });

                this.selectedFilterData = {
                  name: data.name,
                  filter: data,
                  searchArray: searchArray,
                  dateRange: data.dateRange ? data.dateRange : "no_time",
                  expression: data.expression
                };

                simplelib.SetupEntryData(
                  this.limiterConfiguration,
                  null,
                  this.selectedFilterData
                );
              }}
            />

            <FilterDialog
              title="Select a Template"
              pluralTitle="Templates"
              open={this.state.quickDialogOpen}
              searchData={
                this.plan
                  ? this.state.quickData
                  : this.state.quickData.slice(0, 2)
              }
              handleClose={() => {
                this.setState({ quickDialogOpen: false });
              }}
              manageButtonText="Manage Templates"
              handleManageButton={() => {
                this.props.history.push(ROUTES.QUICK_ENTRY);
                return;
              }}
              itemSelected={data => {
                const plan = checkPlan();
                if (data.fields && data.fields.length > 0) {
                  // Handle field based quick entry.
                  //

                  this.selectedValue = null;
                  this.selectedFields = data.fields;
                  this.selectedTemplateName = data.name;
                  this.selectedTemplateId = data.id;
                  this.selectedTitle = data.title;
                  this.selectedBody = data.body;
                  this.selectedLog = data.log && plan ? data.log : "default";
                  this.selectedDate = new Date();
                  this.setState({ quickFieldsOpen: true });
                } else {
                  // Startup the add entry dialog.
                  this.selectedValue = null;
                  this.selectedTemplateId = null;
                  this.selectedTitle = data.title;
                  this.selectedBody = data.body;
                  this.selectedLog = data.log && plan ? data.log : "default";
                  this.selectedDate = new Date();
                  this.fieldData = [];
                  this.setState({
                    open: true,
                    fabOpen: false
                  });
                }
              }}
            />

            <QuickEntryFields
              title={this.selectedTemplateName}
              fields={this.selectedFields}
              open={this.state.quickFieldsOpen}
              didSave={async fields => {
                this.fieldData = fields;

                await this.handleSave();
                this.setState({ quickFieldsOpen: false });
                return true;
              }}
              didClose={() => {
                this.setState({ quickFieldsOpen: false });
              }}
            />

            <EntryEdit
              open={open}
              title={this.selectedTitle}
              body={this.selectedBody}
              date={this.selectedDate}
              logs={this.state.logData}
              log={this.selectedLog}
              decryptionPassword={localStorage.getItem("decryptionPassword")}
              didSave={async data => {
                this.selectedTitle = data.title;
                this.selectedBody = data.body;
                this.selectedDate = data.date;
                this.selectedLog = data.selectedLog;
                await this.handleSave();

                console.log("Handled save, closing edit dialog");
                this.setState({ open: false });

                /**
                 * Should get the date of the entry and set the time window
                 * based on that.
                 */
                const endMoment = moment(this.selectedDate).endOf("day");
                const startMoment = moment(this.selectedDate)
                  .startOf("day")
                  .subtract(this.limiter.timeWindowDays, "day");

                // console.log(
                //   "start and end",
                //   startMoment,
                //   endMoment,
                //   this.limiter.timeWindowDays
                // );

                this.limiter.setTimeWindow(startMoment, endMoment);
                console.log("this.search", this.search);
                simplelib.SetupEntryData(
                  this.limiterConfiguration,
                  this.search ? this.search : null,
                  null,
                  this.showBookmarks
                );

                return true;
              }}
              didClose={() => {
                this.setState({ open: false });
              }}
            />

            <PasswordEntry
              open={this.state.passwordOpen}
              didSelect={password => {
                if (this.potentialLog) {
                  try {
                    const tokenValue = simplelib.decrypt(
                      this.potentialLog.tokenString,
                      password,
                      this.potentialLog.id,
                      crypto
                    );
                    const tokenObject = JSON.parse(tokenValue);

                    if (
                      tokenObject &&
                      tokenObject.logId === this.potentialLog.id &&
                      tokenObject.check === "HELLO"
                    ) {
                      // Password works.
                      localStorage.setItem("decryptionPassword", password);
                      this.setState({ passwordOpen: false }, () => {
                        this.setLog(this.potentialLog.id);
                      });
                    }
                  } catch (e) {
                    this.setState({ passwordOpen: false });
                    this.setLog("default");
                    localStorage.removeItem("decryptionPassword");
                    this.showAlert(
                      "Password is Incorrect",
                      "The password provided does not unlock this log.",
                      true
                    );
                  }
                }
              }}
              didClose={() => {
                console.log("Password Cancel");
                this.setState({ passwordOpen: false });
                this.setLog("default");
                localStorage.removeItem("decryptionPassword");
              }}
            />

            <NewDateSelector
              open={this.state.dateSelectorOpen}
              startDate={this.limiter.getTimeWindow().start.toDate()}
              endDate={this.limiter.getTimeWindow().end.toDate()}
              didSelect={selectedDates => {
                this.limiter.setTimeWindow(
                  selectedDates.start,
                  selectedDates.end
                );
                simplelib.SetupEntryData(this.limiterConfiguration);
                this.setState({ dateSelectorOpen: false });
                this.setState({ defaultValue: "", searchUpdate: uuid.v4() });
              }}
              didClose={() => {
                this.setState({ dateSelectorOpen: false });
              }}
            />

            <FilterChartsV2
              open={this.state.chartsOpen}
              filterData={this.state.data}
              filterConfiguration={this.selectedFilterData}
              didClose={this.handleChartsClosed}
            />
          </Container>
        </MuiThemeProvider>
        <Snackbar
          open={this.state.errorOpen}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ errorOpen: false });
          }}
        >
          <Alert
            autoHideDuration={3000}
            onClose={() => {
              this.setState({ errorOpen: false });
            }}
            severity="error"
          >
            {this.state.errorMessage}
          </Alert>
        </Snackbar>

        <AlertDialog
          open={this.state.alertOpen}
          title={this.state.alertTitle}
          hideSubscribeButton={this.state.alertHideSubscribeButton}
          message={this.state.alertMessage}
          handleClose={() => {
            this.setState({ alertOpen: false });
          }}
          handleNavigation={() => {
            // navigate to account.

            this.props.history.push(ROUTES.ACCOUNT);
          }}
        />

        <QRCodeDialog
          open={this.state.qrCodeDialogOpen}
          shareURL={this.qrCodeShareURL}
          didClose={() => {
            this.setState({ qrCodeDialogOpen: false });
          }}
        />

        <CSVExportDialog
          data={this.state.csvData}
          headers={this.state.csvHeaders}
          open={this.state.csvOpen}
          handleClose={() => {
            this.setState({ csvOpen: false });
          }}
        />
      </Hotkeys>
    );
  }
}

export default withStyles(styles)(Home);
