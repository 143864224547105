import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import ReactPlayer from "react-player";
import MetaTags from "react-meta-tags";
import Chip from "@material-ui/core/Chip";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import EntryImage from "../Library/EntryImage";
import { Typography, Container, Box } from "@material-ui/core";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import colors from "../Library/Colors";
import pageTheme from "../Library/PageTheme";
import { getBodyText } from "../Library/Helper";

const ReactMarkdown = require("react-markdown");
const gfm = require("remark-gfm");
const simplelib = require("@bvelasquez/simple-log-lib");

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.3)
  },
  chip: {
    margin: theme.spacing(0.3),
    paddingTop: 3
  }
}));

const rendererParagraph = props => {
  return (
    <Typography
      paragraph
      variant="body1"
      style={{ marginBottom: 12, color: colors.entryPrimaryText }}
    >
      {props.children}
    </Typography>
  );
};

const rendererHeading = props => {
  return (
    <Typography paragraph variant={`h${props.level}`}>
      {props.children}
    </Typography>
  );
};

const rendererCode = props => {
  return (
    <SyntaxHighlighter
      showLineNumbers={true}
      style={dark}
      language={props.language}
      children={props.value}
    />
  );
};

const rendererList = props => {
  return (
    <Typography
      component="span"
      variant="body1"
      style={{ marginBottom: 12, color: colors.entryPrimaryText }}
    >
      {props.children}
    </Typography>
  );
};

const EntryImageComponent = props => {
  const classes = useStyles();

  if (
    props.value.images &&
    Object.keys(props.value.images).length > 0 &&
    props.value.sharedByUserId
  ) {
    return (
      <CardContent>
        <EntryImage
          images={props.value.images}
          currentUserId={props.value.sharedByUserId}
          value={props.value}
          isShared={true}
        />
        {props.value.image_label_tags &&
          props.value.image_label_tags.map(t => {
            return (
              <Chip
                style={{ marginTop: 10 }}
                size="small"
                color="primary"
                key={t}
                label={t}
                // avatar={<Avatar>{t[0].toUpperCase()}</Avatar>}
                className={classes.chip}
              />
            );
          })}
      </CardContent>
    );
  }
  return null;
};

const EntryTitle = props => {
  if (props.value.title && props.value.title.length > 0) {
    return (
      <CardHeader
        style={{ color: colors.primaryHighlight }}
        title={props.value.title}
      ></CardHeader>
    );
  }
  return null;
};

const EmptyData = props => {
  return (
    <MuiThemeProvider theme={pageTheme}>
      <Container maxWidth="sm">
        <Box>
          <Typography
            align="center"
            variant="h5"
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            Oops! Could not find the shared log entry
          </Typography>

          <Typography align="center" variant="h6">
            The entry you are looking for is no longer shared, or was deleted.
          </Typography>
        </Box>
        <Box style={{ marginTop: 52 }}>
          <Typography
            style={{ marginTop: "12px", textAlign: "center" }}
            variant="h6"
          >
            Simple Log
          </Typography>
          <Typography
            style={{ marginTop: "12px", textAlign: "center" }}
            variant="h6"
          >
            <a href={`${window.location.origin.toString()}/register`}>
              Sign up
            </a>{" "}
            for a free account today, and start logging.
          </Typography>
        </Box>
      </Container>
    </MuiThemeProvider>
  );
};

class SharedEntryPage extends React.Component {
  constructor(props) {
    super(props);

    this.history = props.history;

    this.state = {
      loading: true
    };
  }

  componentWillMount() {
    let match = this.props.match.params;

    let entryId = match.entryId;
    let userId = match.userId;
    let shareId = match.shareId;

    if (entryId && userId) {
      simplelib.FirebaseSnapshots.sharedEntry(
        this.observer,
        userId,
        entryId,
        data => {
          this.setState({
            entry: data,
            loading: false,
            currentUserId: userId
          });
        }
      );
    } else if (shareId) {
      simplelib.FirebaseFunctions.getSharedEntryLink(shareId).then(
        shareData => {
          if (shareData) {
            simplelib.FirebaseSnapshots.sharedEntry(
              this.observer,
              shareData.userId,
              shareData.entryId,
              data => {
                this.setState({
                  entry: data,
                  loading: false,
                  currentUserId: shareData.userId
                });
              }
            );
          }
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer();
    }
  }

  renderCredits = () => {
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 12
        }}
      >
        <Box style={{ textAlign: "center", marginTop: 4, marginBottom: 12 }}>
          <Typography style={{ textDecoration: "none", fontSize: 16 }}>
            This entry created with <a href="/">SimpleLog3</a>
          </Typography>
          <Typography style={{ textDecoration: "none", fontSize: 12 }}>
            Get your free log today!
          </Typography>
        </Box>
      </Container>
    );
  };

  renderAudio = () => {
    const { entry } = this.state;
    if (entry.audioId) {
      return (
        <Box style={{ marginTop: 0, marginBottom: 12 }}>
          <ReactPlayer
            controls
            playsinline
            url={entry.audioUrl}
            width="100%"
            height={40}
          />
        </Box>
      );
    }
    return null;
  };

  render() {
    const { entry, loading } = this.state;

    // let loggedInUserId = getCurrentUserId();

    if (!loading && !entry) {
      return <EmptyData />;
    }

    if (!entry) {
      return null;
    }

    const createdAt = entry.createdAt ? entry.createdAt.toDate() : new Date();

    const appendedBody = getBodyText(entry);

    const dateString = `${createdAt.toLocaleDateString()} ${createdAt.toLocaleString(
      "en-US",
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      }
    )}`;

    return (
      <div>
        <MetaTags>
          <title>SimpleLog3 - Shared entry.</title>
          <meta name="description" content={appendedBody} />
          <meta name="robots" content="noindex" />

          <meta
            property="og:url"
            content={window.location.href}
            id="og-url-meta-tag"
          />
          <meta property="og:type" content="website" id="og-type-meta-tag" />
          <meta
            property="og:title"
            id="og-title-meta-tag"
            content={entry.title ? entry.title : "Simple Log Entry"}
          />
          <meta
            property="og:description"
            id="og-description-meta-tag"
            content={appendedBody}
          />
          <meta
            property="og:image"
            id="og-image-meta-tag"
            content={`${window.location.origin.toString()}/open_graph_image.jpg`}
          />
        </MetaTags>
        <MuiThemeProvider theme={pageTheme}>
          <Container maxWidth="md" style={{ height: "80%", marginTop: 20 }}>
            <Card
              style={{
                marginBottom: 20,
                width: "100%"
              }}
            >
              <EntryTitle value={entry} />
              <EntryImageComponent value={entry} />

              <CardContent>
                {this.renderAudio()}
                <ReactMarkdown
                  plugins={[gfm]}
                  linkTarget="_blank"
                  source={appendedBody}
                  renderers={{
                    paragraph: rendererParagraph,
                    heading: rendererHeading,
                    list: rendererList,
                    code: rendererCode
                  }}
                ></ReactMarkdown>

                <Typography
                  style={{ marginTop: "5px", color: colors.secondaryText }}
                  variant="body2"
                >
                  {dateString}
                </Typography>
              </CardContent>
            </Card>
          </Container>
          {this.renderCredits()}
        </MuiThemeProvider>
      </div>
    );
  }
}

export default SharedEntryPage;
