import React, { useEffect, useState } from "react";
import { Box, Grid, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Gallery } from "react-grid-gallery";

const underscore = require("underscore");

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.3)
  }
}));

const GridChip = ({ value }) => {
  const classes = useStyles();
  return (
    <Grid item key={value} style={{ marginTop: 10 }}>
      <Chip
        size="small"
        color="primary"
        key={value}
        label={value}
        className={classes.chip}
      />
    </Grid>
  );
};

const EntryImage = props => {
  const [paths, setPaths] = useState([]);
  const [update, setUpdate] = useState(false);

  async function getImgSize(imgSrc, key) {
    const promise = new Promise((resolve, reject) => {
      var newImg = new Image();

      newImg.onload = function() {
        var height = newImg.height;
        var width = newImg.width;

        resolve({
          key: key,
          src: imgSrc,
          thumbnail: imgSrc,
          thumbnailWidth: width,
          thumbnailHeight: height
        });
      };

      newImg.src = imgSrc;
    });
    return promise;
  }

  useEffect(() => {
    async function fetchPaths() {
      const arrayed = underscore.keys(props.images).map(key => {
        return props.images[key];
      });
      const sorted = underscore.sortBy(arrayed, item => {
        return item.index;
      });

      const promises = underscore.map(sorted, image => {
        return getImgSize(image.url, image.fileId);
      });
      const settings = await Promise.all(promises);
      setPaths(settings);
    }
    fetchPaths();
  }, [props.images]);

  return (
    <Box>
      <Gallery
        rowHeight={350}
        margin={8}
        enableImageSelection={true}
        images={paths}
        onSelectImage={(index, image) => {
          image.isSelected = image.isSelected ? !image.isSelected : true;
          setUpdate(!update);

          if (props.imagesSelected) {
            props.imagesSelected(props.value, props.currentUserId, paths);
          }
        }}
      />

      <Grid container spacing={1}>
        {props.value.image_label_tags &&
          props.value.image_label_tags.map(t => {
            return <GridChip value={t} />;
          })}
      </Grid>
    </Box>
  );
};

export default EntryImage;
