import React from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import { CardActions, Typography, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import colors from "../Library/Colors";
import { isMobile } from "react-device-detect";
import { MuiThemeProvider } from "@material-ui/core/styles";
import entryTheme from "../Library/EntryTheme";

const ReactMarkdown = require("react-markdown");

const simplelib = require("@bvelasquez/simple-log-lib");

const rendererParagraph = props => {
  return (
    <Typography paragraph variant="body1" style={{}}>
      {props.children}
    </Typography>
  );
};

const rendererHeading = props => {
  return (
    <Typography paragraph variant={`h${props.level}`}>
      {props.children}
    </Typography>
  );
};

const EntryName = props => {
  if (props.value.name && props.value.name.length > 0) {
    return (
      <CardHeader
        style={{ color: colors.primaryHighlight }}
        title={props.value.name}
      ></CardHeader>
    );
  }
  return null;
};

const EntryTitle = props => {
  if (props.value.title && props.value.title.length > 0) {
    return (
      <CardContent style={{ color: colors.primaryHighlight }}>
        <Typography variant="h6">{props.value.title}</Typography>
      </CardContent>
    );
  }
  return null;
};

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.history = props.history;

    this.handleEditEntryButton = this.handleEditEntryButton.bind(this);
    this.handleDeleteEntry = this.handleDeleteEntry.bind(this);

    this.state = {
      menuOpen: false,
      anchorEl: null
    };
  }

  handleEditEntryButton = value => {
    this.props.didEdit(value);
  };

  handleDeleteEntry = (value, props) => {
    var r = window.confirm(
      "Delete this Filter? This cannot be undone and all data and images will be lost."
    );
    if (r === true) {
      simplelib.FirebaseFunctions.deleteFilter(value.id);
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  render() {
    const { value } = this.props;

    let updatedDateString = "";
    if (value.updatedAt) {
      const updatedAt = value.updatedAt.toDate();

      updatedDateString = `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleString(
        "en-US",
        {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true
        }
      )}`;
    }

    return (
      <MuiThemeProvider theme={entryTheme}>
        <Card
          style={{
            marginBottom: 40,
            width: "100%"
          }}
        >
          <EntryName value={value} />
          <EntryTitle value={value} />

          <CardContent>
            <ReactMarkdown
              source={value.body}
              renderers={{
                paragraph: rendererParagraph,
                heading: rendererHeading
              }}
            />
            <Typography
              style={{ marginTop: "5px", fontSize: 10 }}
              variant="body2"
            >
              {`last modified: ${updatedDateString}`}
            </Typography>
            <Typography
              style={{ marginTop: "5px", fontSize: 10 }}
              variant="body2"
            >
              {`id: ${value.id}`}
            </Typography>
          </CardContent>

          <CardActions style={{ marginBottom: 12 }}>
            <Grid container direction="row" justifyContent="flex-start">
              <EditIcon
                fontSize={isMobile ? "small" : "medium"}
                style={{ width: 45, color: colors.entryPrimaryText }}
                aria-label="settings"
                onClick={() => {
                  this.handleEditEntryButton(value);
                }}
              ></EditIcon>

              <DeleteIcon
                fontSize={isMobile ? "small" : "medium"}
                style={{ width: 45, color: colors.entryPrimaryText }}
                aria-label="settings"
                onClick={() => {
                  this.handleDeleteEntry(value);
                }}
              ></DeleteIcon>
            </Grid>
          </CardActions>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default Search;
