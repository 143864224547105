import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { CardActions, Typography, Grid, CardHeader } from "@material-ui/core";
import firebaseApp from "../Firebase/firebase";
import { MuiThemeProvider } from "@material-ui/core/styles";
import colors from "../Library/Colors";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import entryTheme from "../Library/EntryTheme";
import { isMobile } from "react-device-detect";

import { getCurrentUserId } from "../Library/Helper";

const ReactMarkdown = require("react-markdown");

const simplelib = require("@bvelasquez/simple-log-lib");

const currentUserId = getCurrentUserId();
simplelib.SetUserId(currentUserId);
simplelib.SetFirebase(firebaseApp);

const rendererParagraph = props => {
  return (
    <Typography paragraph variant="body1" style={{}}>
      {props.children}
    </Typography>
  );
};

const rendererHeading = props => {
  return (
    <Typography paragraph variant={`h${props.level}`}>
      {props.children}
    </Typography>
  );
};

const EntryTitle = props => {
  if (props.title && props.title.length > 0) {
    return (
      <CardHeader
        style={{ color: colors.primaryHighlight }}
        title={props.title}
      ></CardHeader>
    );
  }
  return null;
};

class MyLog extends React.Component {
  handleNavigateToEntries = () => {
    this.props.history.push(`/entries`);
  };

  handleEditEntryButton = () => {
    this.props.didEdit();
  };

  render() {
    return (
      <MuiThemeProvider theme={entryTheme}>
        <Card
          style={{
            marginBottom: 40,
            width: "100%"
          }}
        >
          <EntryTitle title="My Log" />

          <CardContent>
            <ReactMarkdown
              source={`"My Log" is the default place for your entries. You cannot edit or delete this log. New entries will go into this log unless you create or choose a different log.`}
              renderers={{
                paragraph: rendererParagraph,
                heading: rendererHeading
              }}
            />
          </CardContent>

          <CardActions style={{ marginBottom: 12 }}>
            <EditIcon
              fontSize={isMobile ? "small" : "medium"}
              style={{ width: 45, color: colors.entryPrimaryText }}
              aria-label="settings"
              onClick={() => {
                this.handleEditEntryButton();
              }}
            ></EditIcon>
            <Grid container direction="row" justifyContent="flex-end">
              <Tooltip title="Navigate to the logs entries.">
                <ChevronRightIcon
                  fontSize={isMobile ? "small" : "medium"}
                  style={{ color: colors.entryPrimaryText, width: 45 }}
                  onClick={this.handleNavigateToEntries}
                />
              </Tooltip>
            </Grid>
          </CardActions>
        </Card>
      </MuiThemeProvider>
    );
  }
}

export default MyLog;
