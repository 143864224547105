import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { MuiThemeProvider } from "@material-ui/core/styles";
import YellowButton from "../Library/YellowButton";
import dialogTheme from "../Library/DialogTheme";

const simplelib = require("@bvelasquez/simple-log-lib");

const SearchEdit = props => {
  const [open, setOpen] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [dateRange, setDateRange] = useState("no_time");
  const [expression, setExpression] = useState("expression");
  const [yField, setYField] = useState("");

  useEffect(() => {
    setOpen(props.open);
    setName(props.name);
    setTitle(props.title);
    setBody(props.body);
    setDateRange(props.dateRange);
    setExpression(props.expression);
    setYField(props.yField ? props.yField : "");
  }, [
    props.open,
    props.name,
    props.title,
    props.body,
    props.dateRange,
    props.expression,
    props.yField
  ]);

  const handleClose = () => {
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  const handleSave = () => {
    if (name.length > 0) {
      if (props.didSave) {
        props.didSave({
          title: title,
          body: body,
          name: name,
          dateRange: dateRange,
          expression: expression,
          yField: yField
        });
      }
      setOpen(false);
    } else {
      setTitleError(true);
    }
  };

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        fullWidth={true}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.isEdit ? "Edit Filter" : "Add Filter"}
        </DialogTitle>
        <DialogContent>
          <Box style={{ marginBottom: 20 }}>
            <Typography variant="body1">Name your Filter</Typography>
            <TextField
              autoComplete="off"
              error={titleError}
              size="small"
              fullWidth={true}
              variant="outlined"
              name="name"
              defaultValue={name}
              onChange={event => {
                setName(event.target.value);
              }}
              type="text"
              placeholder="Friendly name for your filter"
            />
          </Box>

          <Box style={{ marginBottom: 20 }}>
            <Typography variant="body1">
              You must have a title, body, or expression defined.
            </Typography>
            <TextField
              autoComplete="off"
              size="small"
              fullWidth={true}
              variant="outlined"
              name="title"
              defaultValue={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
              type="text"
              placeholder="Search entries with this text in the title."
            />
          </Box>
          <Box style={{ marginBottom: 12 }}>
            <TextField
              autoComplete="off"
              variant="outlined"
              fullWidth={true}
              multiline={true}
              minRows={3}
              maxRows={3}
              name="body"
              type="text"
              placeholder="Find entries with these keywords in the body."
              defaultValue={body}
              onChange={event => {
                setBody(event.target.value);
              }}
            />
          </Box>

          <Box style={{ marginBottom: 10 }}>
            <Typography variant="body1">Time Range:</Typography>
            <Select
              fullWidth={true}
              variant="outlined"
              value={dateRange}
              onChange={event => {
                setDateRange(event.target.value);
              }}
            >
              {simplelib.DateSelectionsArray.map(ds => {
                return <MenuItem value={ds.value}>{ds.name}</MenuItem>;
              })}
            </Select>
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <Typography variant="body1">Regular Expression</Typography>
            <TextField
              autoComplete="off"
              size="small"
              fullWidth={true}
              variant="outlined"
              name="expression"
              defaultValue={expression}
              onChange={event => {
                setExpression(event.target.value);
              }}
              type="text"
              placeholder="Regular expression, will search after other filters."
            />
            <Typography variant="body2" style={{ fontSize: 12, marginTop: 8 }}>
              <a
                href="https://www.regular-expressions.info/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Regular Expressions
              </a>{" "}
              are powerful. Display any entry matching your regular expression{" "}
              <strong>after</strong> title, body and time range filtering.
            </Typography>
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <Typography variant="body1">Chart Data Field</Typography>
            <TextField
              autoComplete="off"
              size="small"
              fullWidth={true}
              variant="outlined"
              name="yField"
              defaultValue={yField}
              onChange={event => {
                setYField(event.target.value);
              }}
              type="text"
              placeholder="Chart data field."
            />
            <Typography variant="body2" style={{ fontSize: 12, marginTop: 8 }}>
              Enter entry template fields to use for charts. If a template entry
              has more than one field, separate with comma and the chart will
              show multiple values.
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions
          style={{
            marginRight: 24,
            paddingRight: 0,
            marginTop: 10,
            marginBottom: 10
          }}
        >
          <YellowButton
            style={{ width: isMobile ? 120 : 200 }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </YellowButton>
          <YellowButton
            style={{ width: isMobile ? 120 : 200 }}
            onClick={handleSave}
            color="primary"
          >
            Save
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default SearchEdit;
