import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import colors from "../Library/Colors";

const StyledButton = withStyles(theme => ({
  root: {
    color: colors.secondaryBackground,
    backgroundColor: colors.primaryBackground,
    fontWeight: "600",
    fontSize: colors.buttonFontSize,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: colors.primaryHighlight,
      color: colors.secondaryBackground
    }
  },
  label: {
    color: colors.primaryHighlight,
    justifyContent: "center",
    textAlign: "center",
    "&:hover": {
      backgroundColor: colors.primaryHighlight,
      color: colors.appHeaderColor
    }
  }
}))(Button);

export default StyledButton;
