import React from "react";
import { Typography, Box, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import discordIcon from "../../assets/discord.png";

const MainHelp = props => {
  return (
    <Container maxWidth="md" style={{ height: "80%", marginTop: 25 }}>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">Welcome to SimpleLog3 Help</Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          What is SimpleLog3?
        </Typography>
        <Typography gutterBottom variant="body1">
          SimpleLog3 is an easy to use logging application for both web and
          mobile. You can use it as a daily journal, a work log, a place to
          store notes, or just create temporary logs to track a special event.
        </Typography>
        <Typography gutterBottom variant="h5">
          What can you do?
        </Typography>
        <Typography gutterBottom variant="body1">
          With SimpleLog3, you can easily search for any entry you've created.
          You can <Link to="/help/filters">create filters</Link> to quickly find
          related entries and view charts on the data (web only for now). You
          can also <Link to="/help/templates">create templates</Link> so that
          entries are created with the same format, and data entered the same
          way for each one.
        </Typography>
        <Typography gutterBottom variant="h5">
          Sharing
        </Typography>
        <Typography gutterBottom variant="body1">
          With SimpleLog3, you can also share entries, or entire logs with
          others making them viewable by those you share a link with. Simple
          click the share button on a log or entry and a share URL is created
          for you, which you can send to others. They will then see updates to
          your log or entry in real-time.
        </Typography>

        <Typography id="membership" gutterBottom variant="h5">
          Membership
        </Typography>
        <Typography gutterBottom variant="body1">
          Looking for advanced features? Membership can be started from your
          account page on our website after <a href="/signin">signing in</a>.
          All advanced features will then be available on all your devices.
        </Typography>

        <Box style={{ marginTop: 24 }}>
          <a
            href="https://discord.gg/pNSCAAv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img width="200px" src={discordIcon} alt="Join our Discord" />
          </a>
        </Box>

        <Box style={{ marginTop: 24 }}>
          <Typography gutterBottom variant="h5">
            <Link to="/contact">Give us Feedback</Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default MainHelp;
