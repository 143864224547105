import React from "react";
import { Typography, Box, Container } from "@material-ui/core";
import { Link } from "react-router-dom";

const AboutHelp = props => {
  return (
    <Container maxWidth="md" style={{ height: "80%", marginTop: 25 }}>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">About Us</Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          Who we are
        </Typography>
        <Typography gutterBottom variant="body1" style={{ marginBottom: 54 }}>
          The SimpleLog3 Team is a small group that loves to journal and log
          their daily life events. We are dedicated to making logging easy, fun,
          and useful and want to build a better logging experience.
        </Typography>
        <Typography gutterBottom variant="h5">
          Our Promise
        </Typography>
        <Typography gutterBottom variant="body1" style={{ marginBottom: 54 }}>
          We are dedicated to keeping the logging experience fun, simple, and
          productive. We also want to make our customers happy and see the
          benefits of journalling and logging. Let us know what you think. We
          will always listen.
        </Typography>
        <Typography gutterBottom variant="h5">
          Continuous Improvement
        </Typography>
        <Typography gutterBottom variant="body1" style={{ marginBottom: 54 }}>
          We will always continue to improve the experience but also maintain
          the simplicity and ease of logging. SimpleLog3 is a continually
          evolving product and we look forward to imporving the logging
          experience.
        </Typography>
        <Typography gutterBottom variant="h5">
          Say Hello
        </Typography>
        <Typography gutterBottom variant="body1">
          Have any ideas, suggestions or issues? We would love to hear them. We
          are passionate about serving our customers and hearing their needs. We
          are here to help and listen, so <Link to="/contact">contact us</Link>{" "}
          anytime.
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutHelp;
