import React, { useState, useEffect, useRef } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactMde, { getDefaultToolbarCommands } from "react-mde";
import "../../../src/react-mde-all.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import colors from "../Library/Colors";
import DatePicker from "react-datepicker";
import "../../../src/react-datepicker.css";
import { checkPlan } from "../Library/Helper";
import YellowButton from "../Library/YellowButton";
import dialogTheme from "../Library/DialogTheme";

const gfm = require("remark-gfm");

const rendererParagraph = props => {
  return (
    <Typography
      variant="body1"
      style={{ marginBottom: 12, color: colors.entryPrimaryText }}
    >
      {props.children}
    </Typography>
  );
};

const rendererCode = props => {
  return (
    <SyntaxHighlighter
      showLineNumbers={true}
      style={dark}
      language={props.language}
      children={props.value}
    />
  );
};

const rendererList = props => {
  return (
    <Typography
      component="span"
      variant="body1"
      style={{ marginBottom: 12, color: colors.entryPrimaryText }}
    >
      {props.children}
    </Typography>
  );
};

const rendererHeading = props => {
  return (
    <Typography paragraph variant={`h${props.level}`}>
      {props.children}
    </Typography>
  );
};

const headerOneCommand = {
  name: "header-one-command",
  icon: () => {
    return (
      <Box style={{ height: 32, marginTop: -3 }}>
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>H1</Typography>
      </Box>
    );
  },
  execute: opts => {
    if (opts.initialState.selectedText.length > 0) {
      opts.textApi.replaceSelection(`# ${opts.initialState.selectedText}`);
    }
  }
};

const headerTwoCommand = {
  name: "header-two-command",
  icon: () => {
    return (
      <Box style={{ height: 32, marginTop: -3 }}>
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>H2</Typography>
      </Box>
    );
  },
  execute: opts => {
    if (opts.initialState.selectedText.length > 0) {
      opts.textApi.replaceSelection(`## ${opts.initialState.selectedText}`);
    }
  }
};

const headerThreeCommand = {
  name: "header-three-command",
  icon: () => {
    return (
      <Box style={{ height: 32, marginTop: -3 }}>
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>H3</Typography>
      </Box>
    );
  },
  execute: opts => {
    if (opts.initialState.selectedText.length > 0) {
      opts.textApi.replaceSelection(`### ${opts.initialState.selectedText}`);
    }
  }
};

const headerFourCommand = {
  name: "header-four-command",
  icon: () => {
    return (
      <Box style={{ height: 32, marginTop: -3 }}>
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>H4</Typography>
      </Box>
    );
  },
  execute: opts => {
    if (opts.initialState.selectedText.length > 0) {
      opts.textApi.replaceSelection(`#### ${opts.initialState.selectedText}`);
    }
  }
};

const headerFiveCommand = {
  name: "header-five-command",
  icon: () => {
    return (
      <Box style={{ height: 32, marginTop: -3 }}>
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>H5</Typography>
      </Box>
    );
  },
  execute: opts => {
    if (opts.initialState.selectedText.length > 0) {
      opts.textApi.replaceSelection(`##### ${opts.initialState.selectedText}`);
    }
  }
};

const headerSixCommand = {
  name: "header-six-command",
  icon: () => {
    return (
      <Box style={{ height: 32, marginTop: -3 }}>
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>H6</Typography>
      </Box>
    );
  },
  execute: opts => {
    if (opts.initialState.selectedText.length > 0) {
      opts.textApi.replaceSelection(`###### ${opts.initialState.selectedText}`);
    }
  }
};

const divCommand = {
  name: "div-command",
  icon: () => {
    return (
      <Box style={{ height: 32, marginTop: -3 }}>
        <Typography style={{ fontSize: 15, fontWeight: "bold" }}>
          Div
        </Typography>
      </Box>
    );
  },
  execute: opts => {
    opts.textApi.replaceSelection(`\n-----`);
  }
};

const bodyTextHeight = 350;

const EntryEdit = props => {
  const [selectedTab, setSelectedTab] = React.useState("write");
  const [open, setOpen] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [date, setDate] = useState(new Date());
  const [logs, setLogs] = useState([]);
  const [log, setLog] = useState("default");

  const originalTitle = useRef(props.title);
  const originalBody = useRef(props.body);
  const originalDate = useRef(props.date);
  const originalLog = useRef(props.log);

  const selectedStyle = `.mde-textarea-wrapper textarea.mde-text {
    background-color: ${colors.secondaryBackground};
    color: white;
    font-family: ${colors.font};
    font-size: 16px;
    font-weight: 400;
  }

  .mde-preview .mde-preview-content {
    padding: 10px;
    background-color: ${colors.secondaryBackground};
    min-height: ${bodyTextHeight}px;
    max-height: ${bodyTextHeight}px;
    overflow: scroll;
  }

  .mde-preview .mde-preview-content pre {
    background-color: ${colors.secondaryBackground};
  }
  `;

  useEffect(() => {
    setOpen(props.open);
    setTitle(props.title);
    setBody(props.body);
    setDate(props.date);
    setLogs(props.logs);
    setLog(props.log);

    originalTitle.current = props.title;
    originalBody.current = props.body;
    originalDate.current = props.date;
    originalLog.current = props.log;
  }, [props.open, props.title, props.body, props.date, props.logs, props.log]);

  const handleClose = () => {
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  const handleSave = async () => {
    if (
      title === originalTitle.current &&
      body === originalBody.current &&
      date === originalDate.current &&
      log === originalLog.current
    ) {
      setOpen(false);
      if (props.didClose) {
        props.didClose();
      }
      return true;
    }

    if (title.length > 0 || body.length > 0) {
      if (props.didSave) {
        await props.didSave({
          title: title,
          body: body,
          date: date,
          selectedLog: log
        });
      }

      setOpen(false);
    } else {
      setTitleError(true);
    }
    return true;
  };

  const handleDateChange = date => {
    setDate(date);
  };

  let toolbarCommand = getDefaultToolbarCommands();
  toolbarCommand.push([
    "header-one-command",
    "header-two-command",
    "header-three-command",
    "header-four-command",
    "header-five-command",
    "header-six-command",
    "div-command"
  ]);

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle>{props.isEdit ? "Edit Entry" : "Add Entry"}</DialogTitle>
        <DialogContent>
          <Box style={{ marginBottom: 20 }}>
            <TextField
              autoComplete="off"
              error={titleError}
              size="small"
              fullWidth={true}
              variant="outlined"
              name="title"
              defaultValue={title}
              onChange={event => {
                setTitle(event.target.value);
              }}
              type="text"
              placeholder="Give your entry a title"
            />
          </Box>
          <Box style={{ marginBottom: 10 }}>
            <style>{selectedStyle}</style>
            <ReactMde
              toolbarCommands={toolbarCommand}
              commands={{
                "header-one-command": headerOneCommand,
                "header-two-command": headerTwoCommand,
                "header-three-command": headerThreeCommand,
                "header-four-command": headerFourCommand,
                "header-five-command": headerFiveCommand,
                "header-six-command": headerSixCommand,
                "div-command": divCommand
              }}
              minEditorHeight={bodyTextHeight}
              maxEditorHeight={bodyTextHeight}
              // minPreviewHeight={bodyTextHeight}
              // maxPreviewHeight={bodyTextHeight}
              value={body}
              onChange={value => {
                setBody(value);
              }}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              generateMarkdownPreview={markdown =>
                Promise.resolve(
                  <ReactMarkdown
                    plugins={[gfm]}
                    linkTarget="_blank"
                    renderers={{
                      paragraph: rendererParagraph,
                      heading: rendererHeading,
                      list: rendererList,
                      code: rendererCode
                    }}
                    source={markdown}
                  />
                )
              }
            />
            <Typography
              style={{
                fontSize: colors.dialogMainTitleFontSize,
                marginTop: 8,
                color: colors.secondaryBackground
              }}
            >
              <a
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Styling with Markdown is supported
              </a>
            </Typography>
          </Box>

          {!props.decryptionPassword && (
            <Box style={{ marginBottom: 10 }}>
              <Typography variant="body1">Choose a log:</Typography>
              <Select
                disabled={!checkPlan() || props.decryptionPassword}
                fullWidth={true}
                variant="outlined"
                value={log}
                onChange={event => {
                  setLog(event.target.value);
                }}
              >
                <MenuItem value="default">My Log</MenuItem>

                {logs.map(l => {
                  const data = l.data();
                  if (!data.isVaulted) {
                    return (
                      <MenuItem key={data.id} value={data.id}>
                        {data.title}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </Box>
          )}

          <Box style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Typography variant="body1">Change the date:</Typography>
            <Box className="datePicker">
              <DatePicker
                onFocus={e => (e.target.readOnly = true)}
                withPortal={isMobile ? true : false}
                selected={date}
                showPopperArrow={false}
                onChange={handleDateChange}
                showTimeSelect={isMobile ? false : true}
                showTimeInput={isMobile ? true : true}
                timeFormat="hh:mm aa"
                timeIntervals={isMobile ? 5 : 1}
                timeCaption="time"
                timeInputLabel="Entry Time:"
                dateFormat="MMMM d, yyyy h:mm aa"
                popperPlacement={isMobile ? "auto" : "top-start"}
                popperModifiers={{
                  preventOverflow: {
                    enabled: isMobile ? true : true,
                    escapeWithReference: false,
                    boundariesElement: "viewport"
                  }
                }}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions
          style={{
            marginRight: 24,
            paddingRight: 0,
            marginTop: 35,
            marginBottom: 16
          }}
        >
          <YellowButton style={{ width: 200 }} onClick={handleClose}>
            Cancel
          </YellowButton>
          <YellowButton style={{ width: 200 }} onClick={handleSave}>
            Save
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default EntryEdit;
