import React from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Box,
  List,
  ListItem,
  Typography,
  Container
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { MuiThemeProvider } from "@material-ui/core/styles";
import filterDialogTheme from "../Library/FilterDialogTheme";
import YellowButton from "../Library/YellowButton";
import colors from "../Library/Colors";

const LightListItem = withStyles(theme => ({
  root: {
    color: colors.dialogTitle,
    backgroundColor: colors.dialogBackground,
    fontWeight: "500",
    fontSize: 26
  }
}))(ListItem);

const FilterDialog = props => {
  return (
    <MuiThemeProvider theme={filterDialogTheme}>
      <Dialog
        fullWidth={true}
        scroll="body"
        open={props.open}
        onClose={props.handleClose}
        disableEnforceFocus={true}
      >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Box style={{ paddingLeft: 0, paddingRight: 0 }}>
            {props.searchData.length > 0 && (
              <List>
                {props.searchData.map(s => {
                  const data = s.data();
                  return (
                    <LightListItem
                      key={data.id}
                      button
                      onClick={() => {
                        props.itemSelected(data);
                        props.handleClose();
                      }}
                    >
                      <Typography>{data.name}</Typography>
                    </LightListItem>
                  );
                })}
              </List>
            )}
            {props.searchData.length === 0 && (
              <Container style={{ marginTop: 24, marginBottom: 24 }}>
                <Typography variant="h5" align="center">
                  {`You have no ${props.pluralTitle} yet`}
                </Typography>
                <Typography variant="h6" align="center">
                  {`Go to the ${props.pluralTitle} page and create your first one.`}
                </Typography>
              </Container>
            )}
          </Box>
          <Box
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginTop: 12,
              marginBottom: 12
            }}
          >
            <YellowButton
              variant="contained"
              color="primary"
              align="center"
              style={{ width: 400 }}
              onClick={() => {
                if (props.handleManageButton) {
                  props.handleManageButton();
                }
              }}
            >
              {props.manageButtonText}
            </YellowButton>
          </Box>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default FilterDialog;
