import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Select, MenuItem } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import colors from "./Colors";
import { colorThemes, ColorThemeNames } from "../Library/ColorManager";

const theme1 = createTheme({
  typography: {
    fontFamily: [colors.font].join(",")
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: colors.secondaryText
      }
    },
    MuiList: {
      root: {
        backgroundColor: colors.secondaryBackground,
        maxHeight: 500,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "block",
          backgroundColor: colors.secondaryBackground
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "rgba(0, 0, 0, 0.08)"
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.primaryText
        }
      }
    },
    MuiListItem: {
      root: {
        height: 50,
        color: colors.secondaryText
      }
    },
    MuiOutlinedInput: {
      root: {
        border: 0,
        borderRadius: 12,
        background: colors.secondaryBackground
      }
    },
    MuiDivider: {
      root: {
        color: colors.secondaryBackground,
        backgroundColor: colors.secondaryBackground,
        marginLeft: 5,
        marginRight: 5
      }
    },
    MuiSelect: {
      select: {
        "&:focus": {
          background: "$labelcolor"
        }
      }
    }
  }
});

const DarkOutlinedSelect = withStyles(theme => ({
  root: {
    color: colors.secondaryText,
    backgroundColor: colors.secondaryBackground,
    fontWeight: "500",
    fontSize: isMobile ? 12 : colors.logSelectionFontSize,
    borderRadius: 12
  }
}))(Select);

const DarkMenuItem = withStyles(theme => ({
  root: {
    color: colors.secondaryText,
    backgroundColor: colors.secondaryBackground,
    fontWeight: "500",
    fontSize: isMobile ? 12 : colors.logSelectionFontSize
  }
}))(MenuItem);

const ThemeSelection = props => {
  const [theme, setTheme] = useState(colorThemes.midnightFireTheme);

  useEffect(() => {
    setTheme(props.theme);
  }, [props.theme]);

  const handleChange = function(event) {
    if (event.target.value === "header") {
      return;
    }
    setTheme(event.target.value);
    if (props.selected) {
      props.selected(event.target.value);
    }
  };

  return (
    <MuiThemeProvider theme={theme1}>
      <DarkOutlinedSelect
        className="logSelection"
        variant="outlined"
        value={theme}
        onChange={handleChange}
        style={{
          width: isMobile ? 135 : 300
        }}
      >
        {ColorThemeNames.map(t => {
          return (
            <DarkMenuItem key={t.theme} value={t.theme}>
              {t.name}
            </DarkMenuItem>
          );
        })}
      </DarkOutlinedSelect>
    </MuiThemeProvider>
  );
};

export default ThemeSelection;
