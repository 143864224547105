import React from "react";
import uuid from "react-native-uuid";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import EntryImage from "../Library/EntryImage";
import firebaseApp from "../Firebase/firebase";
import {
  CardActions,
  Typography,
  Menu,
  MenuItem,
  Box,
  Grid
} from "@material-ui/core";
import ReactPlayer from "react-player";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ShareIcon from "@material-ui/icons/Share";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import StorageIcon from "@material-ui/icons/Storage";
import RoomIcon from "@material-ui/icons/Room";
import AlertDialog from "../Library/AlertDialog";
import GoogleMapReact from "google-map-react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import NavigationButton from "../Library/NavigationButton";
import LogName from "../Library/LogName";
import { checkPlan } from "../Library/Helper";
import {
  dateToFromNowDaily,
  getBodyText,
  getCurrentUserId
} from "../Library/Helper";
import { isMobile } from "react-device-detect";
import colors from "../Library/Colors";
import * as ROUTES from "../../constants/routes";

const ReactMarkdown = require("react-markdown");
const gfm = require("remark-gfm");

const simplelib = require("@bvelasquez/simple-log-lib");
const underscore = require("underscore");
const crypto = require("crypto");

let currentUserId = getCurrentUserId();

simplelib.SetUserId(currentUserId);
simplelib.SetFirebase(firebaseApp);

const EntryCircularProgress = withStyles(theme => ({
  root: {
    color: colors.entryPrimaryText
  }
}))(CircularProgress);

const MapMarkerComponent = props => {
  return <RoomIcon fontSize="large" style={{ width: 45, color: "red" }} />;
};

const rendererParagraph = props => {
  return (
    <Typography
      variant="body1"
      style={{ marginBottom: 8, marginTop: 8, color: colors.entryPrimaryText }}
    >
      {props.children}
    </Typography>
  );
};

const rendererCode = props => {
  var newCode = props.value;

  try {
    return (
      <SyntaxHighlighter
        showLineNumbers={true}
        style={dark}
        language={props.language}
        children={newCode || ""}
      />
    );
  } catch {
    return null;
  }
};

const rendererList = props => {
  return (
    <Typography
      component="div"
      variant="body1"
      style={{ marginBottom: 8, marginTop: 8, color: colors.entryPrimaryText }}
    >
      {props.children}
    </Typography>
  );
};

const rendererHeading = props => {
  return (
    <Typography paragraph variant={`h${props.level}`}>
      {props.children}
    </Typography>
  );
};

const EntryImageComponent = props => {
  const currentUserId = getCurrentUserId();

  if (
    props.value.images &&
    Object.keys(props.value.images).length > 0 &&
    currentUserId
  ) {
    return (
      <CardContent>
        <EntryImage
          images={props.value.images}
          currentUserId={currentUserId}
          value={props.value}
          imagesSelected={(entry, userId, images) => {
            const selected = images.filter(i => {
              return i.isSelected;
            });
            if (props.imagesSelected) {
              props.imagesSelected(entry, userId, selected);
            }
          }}
        />
      </CardContent>
    );
  }
  return null;
};

const EntryTitle = props => {
  if (props.title && props.title.length > 0) {
    return <CardHeader title={props.title}></CardHeader>;
  }
  return null;
};

const EntryBookmark = props => {
  if (props.value.isFavorite) {
    return (
      <Tooltip title="Remove bookmark">
        <BookmarkIcon
          style={{ width: 45, color: colors.entryPrimaryText }}
          aria-label="settings"
          onClick={() => {
            props.handleBookmark(props.value);
          }}
        ></BookmarkIcon>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Bookmark the entry">
      <BookmarkBorderIcon
        style={{ width: 45, color: colors.entryPrimaryText }}
        aria-label="settings"
        onClick={() => {
          props.handleBookmark(props.value);
        }}
      ></BookmarkBorderIcon>
    </Tooltip>
  );
};

const EntryShare = props => {
  if (props.value.shared) {
    return (
      <Tooltip title="Unshare entry">
        <ShareIcon
          fontSize={isMobile ? "small" : "medium"}
          style={{ width: isMobile ? 35 : 45, color: colors.entryPrimaryText }}
          aria-label="settings"
          onClick={() => {
            props.handleShared(props.value);
          }}
        ></ShareIcon>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Make entry shareable and create a share link.">
      <ShareOutlinedIcon
        fontSize={isMobile ? "small" : "medium"}
        style={{ width: isMobile ? 35 : 45, color: colors.entryPrimaryText }}
        aria-label="settings"
        onClick={() => {
          props.handleShared(props.value);
        }}
      ></ShareOutlinedIcon>
    </Tooltip>
  );
};

class Entry extends React.PureComponent {
  constructor(props) {
    super(props);

    this.history = props.history;

    this.handleEditEntryButton = this.handleEditEntryButton.bind(this);
    this.handleDeleteEntry = this.handleDeleteEntry.bind(this);

    this.state = {
      menuOpen: false,
      anchorEl: null,

      alertOpen: false,
      alertTitle: "",
      alertMessage: "",
      showDetails: false,

      showImageProgress: false,
      showAttachments: true,
      imageProgress: 0,
      plan: props.plan,
      showMap: false
    };
  }

  handleEditEntryButton = value => {
    this.props.didEdit(value);
  };

  showAlert = (title, message) => {
    this.setState({
      alertOpen: true,
      alertTitle: title,
      alertMessage: message
    });
  };

  handleDeleteEntry = (value, props) => {
    var r = window.confirm(
      "Delete this Entry? This cannot be undone and all data and images will be lost."
    );
    if (r === true) {
      simplelib.FirebaseFunctions.deleteEntry(value.id);
    }
  };

  handleUploadStart = () => {
    console.log("Upload started.");
    this.setState({ showImageProgress: true, imageProgress: 0 });
  };

  handleProgress = progress => {
    console.log("Progress", progress);
    this.setState({ imageProgress: progress });
  };

  handleUploadError = error => {
    console.error(error);
    this.setState({ showImageProgress: false, imageProgress: 0 });
  };

  handleUploadSuccess = filename => {
    console.log("Image Upload success", filename, this.state.imageId);

    const { value } = this.props;
    this.setState({ showImageProgress: false, imageProgress: 0 });

    this.saveUpdate(value, this.state.imageId);
    const currentUserId = getCurrentUserId();
    if (currentUserId) {
      firebaseApp
        .storage()
        .ref(`/${currentUserId}/${this.state.imageId}`)
        .updateMetadata({
          cacheControl: "private, max-age=7200"
        })
        .then(r => {
          // console.log("CACHE SET ==>", r);
        });
    }
    this.setState({ imageId: this.state.imageId });
  };

  checkMenuCanAppear = () => {
    let allowOpen = false;
    if (this.props.value.shared) {
      allowOpen = true;
    }
    if (this.selectedImages && this.selectedImages.length > 0) {
      allowOpen = true;
    }
    return allowOpen;
  };

  handleMenuClick = event => {
    // Check if all requirements met.
    let allowOpen = this.checkMenuCanAppear();

    if (allowOpen) {
      this.setState({ anchorEl: event.currentTarget, menuOpen: true });
    }
  };

  handleBookmark = value => {
    simplelib.FirebaseFunctions.favoriteEntry(value.id, !value.isFavorite);
  };

  handleShare = async value => {
    const shareId = await simplelib.FirebaseFunctions.shareEntry(
      value.id,
      !value.shared
    );

    if (shareId) {
      const shareData = await simplelib.FirebaseFunctions.getSharedEntryLink(
        shareId
      );
      console.log("shareData", shareData);

      navigator.clipboard.writeText(
        `${window.location.origin.toString()}/s/${shareId}`
      );
    }
  };

  handleRemoveImage = async () => {
    // remove images
    const { value } = this.props;
    const currentUserId = getCurrentUserId();

    const imagesToDelete = [].concat(this.selectedImages);
    this.selectedImages = null;

    if (value.images) {
      const keys = underscore.map(imagesToDelete, m => {
        return m.key;
      });

      const leftOverImages = underscore.omit(value.images, keys);

      const r1 = await simplelib.FirebaseFunctions.saveEntrySimple(value.id, {
        updatedAt: new Date(),
        images: {}
      });

      if (r1) {
        const result = await simplelib.FirebaseFunctions.saveEntrySimple(
          value.id,
          {
            updatedAt: new Date(),
            images: leftOverImages
          }
        );

        if (result) {
          const promises = underscore.map(imagesToDelete, m => {
            return firebaseApp
              .storage()
              .ref(`/${currentUserId}/images/${value.id}/${m.key}`)
              .delete();
          });
          await Promise.all(promises);
        }
      }
    }

    this.setState({
      anchorEl: null,
      menuOpen: false,
      selectedImages: this.selectedImages
    });
  };

  handleQRCode = value => {
    this.setState({ menuOpen: false });
    let shareURL;
    if (value.shareId) {
      shareURL = `${window.location.origin.toString()}/s/${value.shareId}`;
    } else {
      const currentUserId = getCurrentUserId();

      shareURL = `${window.location.origin.toString()}/shared_entry/${currentUserId}/${
        value.id
      }`;
    }

    if (this.props.handleQRCode) {
      this.props.handleQRCode(shareURL);
    }
  };

  handleQRCode = value => {
    this.setState({ menuOpen: false });
    let shareURL;
    if (value.shareId) {
      shareURL = `${window.location.origin.toString()}/s/${value.shareId}`;
    } else {
      const currentUserId = getCurrentUserId();

      shareURL = `${window.location.origin.toString()}/shared_entry/${currentUserId}/${
        value.id
      }`;
    }

    if (this.props.handleQRCode) {
      this.props.handleQRCode(shareURL);
    }
  };

  handleGetShareLink = value => {
    if (value.shareId) {
      navigator.clipboard.writeText(
        `${window.location.origin.toString()}/s/${value.shareId}`
      );
    } else {
      const currentUserId = getCurrentUserId();
      navigator.clipboard.writeText(
        `${window.location.origin.toString()}/shared_entry/${currentUserId}/${
          value.id
        }`
      );
    }
  };

  handleGetiFrame = value => {
    navigator.clipboard.writeText(
      `
      <iframe src="${window.location.origin.toString()}/shared_entry/${currentUserId}/${
        value.id
      }?hideMenu=true" height="85%" width="400px" name="${value.id}">
    </iframe>
      `
    );
  };

  handleEditFieldData = value => {
    //
    this.setState({ anchorEl: null, menuOpen: false }, () => {
      if (this.props.didEditFields) {
        this.props.didEditFields(value);
      }
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  handleShowAttachments = value => {
    //
    this.setState({ showAttachments: true });
  };

  handleDeleteAttachment = async (entry, fileId) => {
    if (entry.attachments) {
      const attachments = underscore.clone(entry.attachments);

      console.log("Deleting", fileId, "from", entry.attachments);

      if (fileId in attachments) {
        const currentUserId = getCurrentUserId();

        const path = `/${currentUserId}/attachments/${entry.id}/${fileId}`;

        // Delete  the record of the attachment
        delete attachments[fileId];

        console.log("Attachments", attachments, entry.id);

        try {
          await simplelib.FirebaseFunctions.saveEntrySimple(entry.id, {
            updatedAt: new Date(),
            attachments: {}
          });

          const result = await simplelib.FirebaseFunctions.saveEntrySimple(
            entry.id,
            {
              updatedAt: new Date(),
              attachments: attachments
            }
          );

          if (result) {
            await firebaseApp
              .storage()
              .ref(path)
              .delete();
          }
        } catch (e) {
          //
          console.log("Exception deleting attachment", e);
        }
      }
    }
  };

  getDownloadAttachmentURL = async (fileId, value) => {
    // Download the attachment.

    const currentUserId = getCurrentUserId();

    const path = `/${currentUserId}/attachments/${value.id}/${fileId}`;

    const url = await firebaseApp
      .storage()
      .ref(path)
      .getDownloadURL();

    return url;
  };

  getImageURL = async (fileId, value) => {
    // Download the attachment.

    const currentUserId = getCurrentUserId();

    const path = `/${currentUserId}/images/${value.id}/${fileId}`;

    const url = await firebaseApp
      .storage()
      .ref(path)
      .getDownloadURL();

    return url;
  };

  saveUpdate = (value, imageId) => {
    simplelib.FirebaseFunctions.saveEntrySimple(value.id, {
      updatedAt: new Date(),
      image: imageId
    });
  };

  saveFileUpdate = async (value, fileId) => {
    let attachments = {};
    if (value.attachments) {
      attachments = value.attachments;
    }

    const url = await this.getDownloadAttachmentURL(fileId, value);
    console.log("URL", url);

    if (fileId in attachments) {
      attachments[fileId] = url;
    } else {
      attachments[fileId] = url;
    }

    await simplelib.FirebaseFunctions.saveEntrySimple(value.id, {
      updatedAt: new Date(),
      attachments: attachments
    });
  };

  saveImageUpdate = async (value, fileId) => {
    let images = {};
    if (value.images) {
      images = value.images;
    }

    const url = await this.getImageURL(fileId, value);

    const index = Object.keys(images).length;

    if (fileId in images) {
      images[fileId] = {
        fileId: fileId,
        index: index,
        url: url
      };
    } else {
      images[fileId] = {
        fileId: fileId,
        index: index,
        url: url
      };
    }

    await simplelib.FirebaseFunctions.saveEntrySimple(value.id, {
      updatedAt: new Date(),
      images: images
    });
  };

  handleImageUpload = e => {
    const { value } = this.props;

    if (e.target.files[0]) {
      const image = e.target.files[0];
      const plan = checkPlan();
      const currentUserId = getCurrentUserId();

      console.log("Upload image", image.type);

      if (image.type !== "image/jpeg" && image.type !== "image/png") {
        this.showAlert(
          "Wrong file type",
          "Currently we only support JPEG and PNG. Soon, we are adding support for more file formats."
        );
        return;
      }

      if (!plan && value.logId !== "default") {
        this.showAlert(
          "Subscription",
          "You can add an image to the default log. Please subscribe to add an image to additional logs."
        );
        return;
      }

      if (!plan && image.size > 2000000) {
        const imageSize = image.size / 1000000;
        this.showAlert(
          "Subscription",
          `Image is ${imageSize.toFixed(
            2
          )} MB. You need a paid subscription to upload images larger than 2.0 MB. Subscribe to upload larger images.`
        );
        return;
      }

      if (image.size > 8000000) {
        this.showAlert(
          "Image too large",
          "The image is too large. Please use an image 8 MB or less in size."
        );
        return;
      }

      const fileId = `${uuid.v4()}`;

      console.log("Upload started.", fileId);
      this.setState({ showImageProgress: true, imageProgress: 0 });

      const uploadTask = firebaseApp
        .storage()
        .ref(`/${currentUserId}/images/${this.props.value.id}/${fileId}`)
        .put(image);

      uploadTask.on(
        "state_changed",
        snapshot => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ imageProgress: progress });
        },
        error => {
          console.error(error);
          this.setState({ showImageProgress: false, imageProgress: 0 });
        },
        () => {
          console.log("Image Upload success", image, fileId);

          const { value } = this.props;
          this.setState({ showImageProgress: false, imageProgress: 0 });

          this.saveImageUpdate(value, fileId); // save the file attachement.
          const currentUserId = getCurrentUserId();
          if (currentUserId) {
            firebaseApp
              .storage()
              .ref(`/${currentUserId}/images/${this.props.value.id}/${fileId}`)
              .updateMetadata({
                cacheControl: "private, max-age=7200"
              })
              .then(r => {
                // console.log("CACHE SET ==>", r);
              });
          }
          // this.setState({ imageId: this.state.imageId });
        }
      );
    }
    e.target.value = "";
    e.target.files = null;
  };

  handleFileUpload = e => {
    const { value } = this.props;

    if (e.target.files[0]) {
      const file = e.target.files[0];
      const plan = checkPlan();
      const currentUserId = getCurrentUserId();

      console.log("Upload file", file);

      if (!plan && value.logId !== "default") {
        this.showAlert(
          "Subscription",
          "You can add an image to the default log. Subscribe to add an image to additional logs."
        );
        return;
      }

      if (!plan && file.size > 2000000) {
        const fileSize = file.size / 1000000;
        this.showAlert(
          "Subscription",
          `File is ${fileSize.toFixed(
            2
          )} MB. You need a paid subscription to upload files larger than 2.0 MB. Subscribe to upload larger files.`
        );
        return;
      }

      if (file.size > 8000000) {
        this.showAlert(
          "File too large",
          "The file is too large. Please use an file 8 MB or less in size."
        );
        return;
      }

      const fileId = file.name;

      console.log("Upload started.", fileId, this.props.value);
      this.setState({ showImageProgress: true, imageProgress: 0 });

      const uploadTask = firebaseApp
        .storage()
        .ref(`/${currentUserId}/attachments/${this.props.value.id}/${fileId}`)
        .put(file);

      uploadTask.on(
        "state_changed",
        snapshot => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("Upload progress", progress);
          this.setState({ imageProgress: progress });
        },
        error => {
          console.error(error);
          this.setState({ showImageProgress: false, imageProgress: 0 });
        },
        () => {
          console.log("Image Upload success", file, fileId);

          const { value } = this.props;
          this.setState({ showImageProgress: false, imageProgress: 0 });

          this.saveFileUpdate(value, fileId); // save the file attachement.
          const currentUserId = getCurrentUserId();
          if (currentUserId) {
            firebaseApp
              .storage()
              .ref(
                `/${currentUserId}/attachments/${this.props.value.id}/${fileId}`
              )
              .updateMetadata({
                cacheControl: "private, max-age=7200"
              })
              .then(r => {
                // console.log("CACHE SET ==>", r);
              });
          }

          console.log("Upload success value image", fileId);
          // this.setState({ imageId: this.state.imageId });
        }
      );
    }
    e.target.value = "";
    e.target.files = null;
  };

  renderMap = () => {
    const { value } = this.props;

    if (!value.latitude && !value.longitude) {
      return null;
    }
    if (value.latitude === 0 || value.longitude === 0) {
      return null;
    }

    return (
      <Box>
        {this.state.showMap && (
          <Box style={{ height: "200px", width: "100%" }}>
            <GoogleMapReact
              options={{ mapTypeControl: true }}
              defaultZoom={15}
              defaultCenter={{ lat: value.latitude, lng: value.longitude }}
              bootstrapURLKeys={{
                key: "AIzaSyBFzaSRKiT32QRDb6gm-L199eyTtjzKj74"
              }}
              yesIWantToUseGoogleMapApiInternals
            >
              <MapMarkerComponent lat={value.latitude} lng={value.longitude} />
            </GoogleMapReact>
          </Box>
        )}
        <Typography
          style={{
            textDecoration: "underline",
            color: colors.primaryHighlight
          }}
          onClick={() => {
            this.setState({ showMap: !this.state.showMap });
          }}
        >
          {this.state.showMap ? "hide map" : "show map"}
        </Typography>
      </Box>
    );
  };

  renderEditButtons = value => {
    if (value.fieldData && value.fieldData.length > 0) {
      return (
        <>
          <Tooltip title="Edit the entry">
            <EditIcon
              fontSize={isMobile ? "small" : "medium"}
              style={{
                width: isMobile ? 35 : 45,
                color: colors.entryPrimaryText
              }}
              aria-label="settings"
              onClick={() => {
                this.handleEditFieldData(value);
              }}
            ></EditIcon>
          </Tooltip>

          <Tooltip title="This entry was created with a template. Click here to edit the template layout, entry date or title.">
            <StorageIcon
              fontSize={isMobile ? "small" : "medium"}
              style={{
                width: isMobile ? 35 : 45,
                color: colors.entryPrimaryText
              }}
              aria-label="settings"
              onClick={() => {
                this.handleEditEntryButton(value);
              }}
            ></StorageIcon>
          </Tooltip>
        </>
      );
    }
    return (
      <>
        <Tooltip title="Edit the entry">
          <EditIcon
            fontSize={isMobile ? "small" : "medium"}
            style={{
              width: isMobile ? 35 : 45,
              color: colors.entryPrimaryText
            }}
            aria-label="settings"
            onClick={() => {
              this.handleEditEntryButton(value);
            }}
          ></EditIcon>
        </Tooltip>
      </>
    );
  };

  renderAudio = () => {
    const { value } = this.props;
    if (value.audioId) {
      return (
        <Box style={{ marginTop: 0, marginBottom: 12 }}>
          <ReactPlayer
            controls
            playsinline
            url={value.audioUrl}
            width="100%"
            height={40}
          />
        </Box>
      );
    }
    return null;
  };

  render() {
    // console.log("Rendering entry");
    const { value, logs, decryptionPassword } = this.props;
    const { showDetails, plan } = this.state;

    if (!value) {
      return null;
    }

    var title = value.title;
    if (decryptionPassword && title) {
      try {
        title = simplelib.decrypt(title, decryptionPassword, value.log, crypto);
      } catch (e) {
        title = value.title;
      }
    }

    const createdAt = value.createdAt ? value.createdAt.toDate() : new Date();
    const updatedAt = value.updatedAt ? value.updatedAt.toDate() : new Date();

    var newValue = value;
    if (decryptionPassword) {
      newValue = underscore.clone(value);
      newValue.title = title;

      try {
        newValue.body = simplelib.decrypt(
          value.body,
          decryptionPassword,
          value.log,
          crypto
        );
      } catch (e) {
        newValue.body = value.body;
      }
    }

    const appendedBody = getBodyText(newValue);

    // console.log("appendedBody", appendedBody, value.id);

    const dateString = `${createdAt.toLocaleDateString()} ${createdAt.toLocaleString(
      "en-US",
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      }
    )}`;

    const updatedDateString = `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleString(
      "en-US",
      {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      }
    )}`;

    return (
      // <MuiThemeProvider theme={entryTheme}>
      <Card
        elevation={4}
        style={{
          marginBottom: 40,
          width: "100%"
        }}
      >
        <EntryTitle title={title} />

        <EntryImageComponent
          value={value}
          imagesSelected={(entry, userId, images) => {
            this.selectedImages = images;
            this.setState({ selectedImages: this.selectedImages });
          }}
        />

        {this.selectedImages && this.selectedImages.length > 0 && (
          <CardContent>
            <NavigationButton
              style={{ width: 400 }}
              onClick={this.handleRemoveImage}
            >
              Remove selected images
            </NavigationButton>
          </CardContent>
        )}

        <CardContent>
          {this.renderAudio()}
          <ReactMarkdown
            plugins={[gfm]}
            linkTarget="_blank"
            source={appendedBody}
            renderers={{
              paragraph: rendererParagraph,
              heading: rendererHeading,
              list: rendererList,
              code: rendererCode
            }}
          ></ReactMarkdown>

          {this.renderMap()}
          <Box style={{}}>
            <LogName value={value} logs={logs} />
          </Box>
          <Box>
            <Typography
              style={{
                marginTop: "12px",
                color: colors.entrySecondaryText
              }}
              variant="body1"
              onClick={() => {
                this.setState({ showDetails: !this.state.showDetails });
              }}
            >
              {dateString}
            </Typography>
          </Box>
          {value.attachments && underscore.keys(value.attachments).length > 0 && (
            <Box>
              <Typography
                style={{
                  marginTop: "12px",

                  color: colors.entrySecondaryText
                }}
                variant="body1"
                onClick={() => {
                  this.handleShowAttachments(value);
                }}
              >
                {`${underscore.keys(value.attachments).length} attachments`}
              </Typography>
            </Box>
          )}
          {this.state.showAttachments &&
            value.attachments &&
            underscore.keys(value.attachments).map(key => {
              return (
                <Grid
                  key={key}
                  container
                  direction="row"
                  justifyContent="flex-start"
                >
                  <a
                    style={{ color: colors.primaryHighlight }}
                    href={value.attachments[key]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {key}
                  </a>
                  <DeleteIcon
                    fontSize={isMobile ? "small" : "small"}
                    style={{
                      width: isMobile ? 25 : 35,
                      color: colors.entryPrimaryText
                    }}
                    aria-label="settings"
                    onClick={() => {
                      this.handleDeleteAttachment(value, key);
                    }}
                  ></DeleteIcon>
                </Grid>
              );
            })}

          {showDetails && (
            <Box>
              <Typography
                style={{ marginTop: "5px", color: colors.secondaryText }}
                variant="body2"
              >
                {dateToFromNowDaily(value.createdAt.toDate(), true)}
              </Typography>
              <Typography
                style={{ marginTop: "5px", color: colors.secondaryText }}
                variant="body2"
              >
                {`last modified: ${updatedDateString}`}
              </Typography>
              <Typography
                style={{ marginTop: "5px", color: colors.secondaryText }}
                variant="body2"
              >
                {`id: ${value.id}`}
              </Typography>
            </Box>
          )}
        </CardContent>

        <CardActions style={{ marginBottom: 12 }}>
          <Grid container direction="row" justifyContent="flex-start">
            {this.renderEditButtons(value)}

            {!value.isVaulted && (
              <Box style={{ height: 25 }}>
                <label>
                  <Tooltip
                    title={`Upload an image. Maximum image size is ${
                      plan
                        ? "8 MB with paid account."
                        : "2.0 MB with free account."
                    }`}
                  >
                    <AddAPhotoIcon
                      fontSize={isMobile ? "small" : "medium"}
                      style={{
                        width: isMobile ? 35 : 45,
                        color: colors.entryPrimaryText
                      }}
                      aria-label="settings"
                    ></AddAPhotoIcon>
                  </Tooltip>

                  <input
                    hidden={true}
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={this.handleImageUpload}
                  />
                </label>
              </Box>
            )}

            {!value.isVaulted && (
              <Box style={{ height: 25 }}>
                <label>
                  <Tooltip
                    title={`Attach a file. Maximum file size is ${
                      plan
                        ? "8 MB with paid account."
                        : "2.0 MB with free account."
                    }`}
                  >
                    <AttachFileIcon
                      fontSize={isMobile ? "small" : "medium"}
                      style={{
                        width: isMobile ? 35 : 45,
                        color: colors.entryPrimaryText
                      }}
                      aria-label="settings"
                    ></AttachFileIcon>
                  </Tooltip>

                  <input
                    hidden={true}
                    type="file"
                    accept="*.*"
                    onChange={this.handleFileUpload}
                  />
                </label>
              </Box>
            )}

            <Tooltip title="Delete the entry">
              <DeleteIcon
                fontSize={isMobile ? "small" : "medium"}
                style={{
                  width: isMobile ? 35 : 45,
                  color: colors.entryPrimaryText
                }}
                aria-label="settings"
                onClick={() => {
                  this.handleDeleteEntry(value);
                }}
              ></DeleteIcon>
            </Tooltip>

            {!value.isVaulted && this.checkMenuCanAppear() && (
              <MoreVertIcon
                fontSize={isMobile ? "small" : "medium"}
                style={{
                  color: colors.entryPrimaryText,
                  width: isMobile ? 35 : 45
                }}
                onClick={this.handleMenuClick}
              />
            )}
          </Grid>
          {!value.isVaulted && (
            <Grid container direction="row" justifyContent="flex-end">
              <EntryShare value={value} handleShared={this.handleShare} />
              <EntryBookmark
                value={value}
                handleBookmark={this.handleBookmark}
              />
            </Grid>
          )}
        </CardActions>

        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.menuOpen}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              color: colors.entryPrimaryText,
              backgroundColor: colors.primaryBackground
            }
          }}
        >
          {this.selectedImages && this.selectedImages.length > 0 && (
            <MenuItem key="attachImage" onClick={this.handleRemoveImage}>
              Remove selected images
            </MenuItem>
          )}
          {value.shared && (
            <MenuItem
              key="getShareLink"
              onClick={() => {
                this.handleGetShareLink(value);
              }}
            >
              Get share link
            </MenuItem>
          )}

          {value.shared && (
            <MenuItem
              key="getiFrameLink"
              onClick={() => {
                this.handleGetiFrame(value);
              }}
            >
              Copy iFrame to clipboard
            </MenuItem>
          )}
          {value.shared && (
            <MenuItem
              key="getQRCode"
              onClick={() => {
                this.handleQRCode(value);
              }}
            >
              Get QR code
            </MenuItem>
          )}
        </Menu>

        <AlertDialog
          open={this.state.alertOpen}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          handleClose={() => {
            this.setState({ alertOpen: false });
          }}
          handleNavigation={() => {
            // navigate to account.
            console.log("HANDLE NAVIGATION");
            this.props.history.push(ROUTES.ACCOUNT);
          }}
        />

        {this.state.showImageProgress && (
          <Box
            style={{
              height: 100,
              width: 100,
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: -50,
              marginTop: -50
            }}
          >
            <EntryCircularProgress
              size={50}
              variant="indeterminate"
              value={this.state.imageProgress}
            />
          </Box>
        )}
      </Card>
      // </MuiThemeProvider>
    );
  }
}

export default Entry;
