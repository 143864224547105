import { createTheme } from "@material-ui/core/styles";
import colors from "../Library/Colors";

const helpTheme = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        "& a": {
          color: colors.primaryHighlight
        }
      }
    },
    MuiListItemText: {
      root: {
        "& span": {
          textAlign: "center",
          display: "block"
        }
      }
    },
    // Style sheet name ⚛️
    MuiTypography: {
      // Name of the rule
      gutterBottom: {
        // Some CSS
        marginBottom: 20
      },
      root: {
        "& .menuLink": {
          textDecoration: "underline",
          color: colors.primaryHighlight
        },
        "& .menuLinkHeader": {
          textDecoration: "none",
          color: colors.primaryHighlight
        },
        "& a": {
          color: colors.primaryHighlight
        }
      }
    },
    MuiTableCell: {
      head: {
        color: colors.primaryText
      },
      body: {
        color: colors.primaryText
      }
    }
  },
  typography: {
    fontFamily: [colors.font].join(","),
    body1: { color: colors.primaryText, whiteSpace: "pre-wrap" },
    body2: { color: colors.primaryText },
    h1: { color: colors.primaryHighlight },
    h2: { color: colors.primaryHighlight },
    h3: { color: colors.primaryHighlight },
    h4: { color: colors.primaryHighlight },
    h5: { color: colors.primaryHighlight },
    h6: { color: colors.primaryHighlight },
    color: colors.primaryText
  },
  palette: {
    primary: {
      main: colors.primaryBackground
    },
    secondary: {
      main: colors.primaryText,
      contrastText: colors.primaryText
    },
    background: {
      default: colors.primaryBackground
    }
  }
});

export default helpTheme;
