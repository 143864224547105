import React from "react";
import * as ROUTES from "../../constants/routes";

import firebaseApp from "../Firebase/firebase";

export default class MapView extends React.Component {
  constructor(props) {
    super(props);

    firebaseApp.auth().onAuthStateChanged(user => {
      if (!user) {
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("logId");
        this.props.history.push(ROUTES.LANDING);
      }
    });
  }

  render() {
    return (
      <div>
        <h1>Map</h1>
      </div>
    );
  }
}
