import React from "react";
import { Box, Typography } from "@material-ui/core";
import { isMobile, isIOS, isAndroid } from "react-device-detect";

import appleButtonImage from "../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import googlePlayImage from "../../assets/google-play-badge.png";

import ColorManager, { colorThemes } from "../Library/ColorManager";

const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const MobileButton = props => {
  if (isIOS) {
    return (
      <Box style={props.style}>
        <Typography
          style={{
            paddingTop: 24,
            paddingBottom: 12,
            fontSize: isMobile ? 24 : 32,
            fontWeight: "700",
            color: props.color ? props.color : colors.secondaryBackground
          }}
        >
          SimpleLog3 for iPhone
          <sup
            style={{
              fontSize: 12,
              color: props.color ? props.color : colors.secondaryBackground
            }}
          >
            &reg;
          </sup>
        </Typography>

        <a
          href="https://apps.apple.com/us/app/simple-log-journal-diary-log/id1379867437?ls=1"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: colors.secondaryBackground, fontSize: 20 }}
        >
          <img width="200" src={appleButtonImage} alt="Simple Log Logo" />
        </a>
      </Box>
    );
  } else if (isAndroid) {
    return (
      <Box style={props.style}>
        <Typography
          style={{
            paddingTop: 24,
            paddingBottom: 12,
            fontSize: isMobile ? 24 : 32,
            fontWeight: "700",
            color: props.color ? props.color : colors.secondaryBackground
          }}
        >
          SimpleLog3 for Android
          <sup
            style={{
              fontSize: 12,
              color: props.color ? props.color : colors.secondaryBackground
            }}
          >
            &trade;
          </sup>
        </Typography>
        <a href="https://play.google.com/store/apps/details?id=com.simplelog3&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img width="200" alt="Get it on Google Play" src={googlePlayImage} />
        </a>
      </Box>
    );
  }
  return null;
};

export default MobileButton;
