import React from "react";
import {
  Container,
  Box,
  Typography,
  Snackbar,
  TextField
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { isMobile, deviceDetect } from "react-device-detect";
import { MuiThemeProvider } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Helmet } from "react-helmet";
import "firebase/auth";
import "firebase/analytics";
import firebaseApp from "../Firebase/firebase";
import YellowButton from "../Library/YellowButton";
import pageTheme from "../Library/PageTheme";
import { getCurrentUserId } from "../Library/Helper";

const simplelib = require("@bvelasquez/simple-log-lib");
const underscore = require("underscore");

const ErrorMessage = props => {
  return (
    <Snackbar
      autoHideDuration={5000}
      open={props.open}
      onClose={props.handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{props.errorMessage}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
};

class Contact extends React.Component {
  constructor(props) {
    super(props);

    const email = localStorage.getItem("email");
    const currentUserId = getCurrentUserId();

    this.state = {
      loaded: false,
      errorOpen: false,
      errorMessage: "",
      email: email ? email : "",
      userId: currentUserId ? currentUserId : "anonymous",
      firstName: "",
      lastName: "",
      subject: "",
      body: ""
    };

    this.debounceSubmit = underscore.debounce(this.onSubmit, 5000, true);
  }

  componentDidMount() {
    firebaseApp.analytics().logEvent("page_view", {
      page_location: "contact",
      page_path: "/contact",
      page_title: "Contact"
    });

    const currentUserId = getCurrentUserId();

    if (currentUserId) {
      simplelib.FirebaseSnapshots.account(this.observer, data => {
        if (data) {
          this.setState({ firstName: data.firstName, lastName: data.lastName });
        }
      });
    }
  }

  componentWillUnmount() {}

  onSubmit = () => {
    const { firstName, lastName, email, subject, body, userId } = this.state;

    if (firstName.trim().length === 0) {
      this.setState({
        errorOpen: true,
        errorMessage: "First name please. Let us know who you are."
      });
      this.debounceSubmit.cancel();
      return;
    }

    if (email.trim().length === 0) {
      this.setState({
        errorOpen: true,
        errorMessage: "Email cannot be blank. We'll need to reply."
      });
      this.debounceSubmit.cancel();
      return;
    }

    if (subject.trim().length === 0) {
      this.setState({
        errorOpen: true,
        errorMessage: "Need a subject."
      });
      this.debounceSubmit.cancel();
      return;
    }

    if (body.trim().length === 0) {
      this.setState({
        errorOpen: true,
        errorMessage: "Please provide a message. What's on your mind?"
      });
      this.debounceSubmit.cancel();
      return;
    }

    const deviceInfo = deviceDetect();
    console.log("Device Info", deviceInfo);

    simplelib.FirebaseFunctions.createMessage(userId, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      userId: userId,
      subject: subject,
      body: body,
      createdAt: new Date(),
      responded: false,
      deviceInfo: deviceInfo
    })
      .then(() => {
        this.setState({
          errorOpen: true,
          errorMessage:
            "We'll get back to you soon. You can send another message in 5 seconds.",
          subject: "",
          body: ""
        });
      })
      .catch(e => {
        this.setState({
          errorOpen: true,
          errorMessage: "Error sending message. Please try again."
        });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { firstName, lastName, email, subject, body } = this.state;

    return (
      <MuiThemeProvider theme={pageTheme}>
        <Helmet>
          <title>SimpleLog3 - Contact Us</title>
          <meta name="description" content="SimpleLog3 contact us page." />
          <meta name="robots" content="noindex" />
        </Helmet>
        <CssBaseline />
        <Container maxWidth="md" style={{ paddingBottom: 24 }}>
          <Box style={{ marginTop: 12, marginBottom: 12, textAlign: "center" }}>
            <Typography
              gutterBottom={true}
              align="center"
              variant="body1"
              style={{ fontSize: isMobile ? 24 : 42, fontWeight: "700" }}
            >
              Contact Us
            </Typography>
          </Box>

          <Box style={{ marginBottom: 6, textAlign: "center" }}>
            <Typography variant="h6">
              Our customers are #1. Please don't hesitate to send us a message.
            </Typography>
          </Box>
          <Box style={{ marginBottom: 24, textAlign: "center" }}>
            <Typography
              style={{ fontSize: isMobile ? 14 : 16, textAlign: "center" }}
            >
              Whether it's to report a bug or request a feature. Even to just
              say hi, we are happy to hear from you. SimpleLog3 is new and we
              are eager to make it better every day. Tell us what you think.
            </Typography>
          </Box>

          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <TextField
              size="small"
              style={{ width: isMobile ? 300 : 740 }}
              autoComplete="off"
              required={true}
              variant="outlined"
              name="firstName"
              value={firstName || ""}
              onChange={this.onChange}
              type="text"
              placeholder="First name"
            />
          </Box>
          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <TextField
              size="small"
              style={{ width: isMobile ? 300 : 740 }}
              autoComplete="off"
              required={true}
              variant="outlined"
              name="lastName"
              value={lastName || ""}
              onChange={this.onChange}
              type="text"
              placeholder="Last name"
            />
          </Box>
          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <TextField
              size="small"
              style={{ width: isMobile ? 300 : 740 }}
              autoComplete="off"
              required={true}
              variant="outlined"
              name="email"
              value={email || ""}
              onChange={this.onChange}
              type="text"
              placeholder="How do we contact you?"
            />
          </Box>
          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <TextField
              size="small"
              style={{ width: isMobile ? 300 : 740 }}
              autoComplete="off"
              required={true}
              variant="outlined"
              name="subject"
              value={subject || ""}
              onChange={this.onChange}
              type="text"
              placeholder="Subject of your message"
            />
          </Box>
          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <TextField
              style={{ width: isMobile ? 300 : 740 }}
              autoComplete="off"
              variant="outlined"
              fullWidth={false}
              multiline={true}
              minRows={isMobile ? 4 : 6}
              maxRows={isMobile ? 4 : 6}
              name="body"
              type="text"
              placeholder="What's on your mind?"
              value={body || ""}
              onChange={this.onChange}
            />
          </Box>

          <Box style={{ marginBottom: 20, textAlign: "center" }}>
            <Typography
              style={{ fontSize: isMobile ? 14 : 16, textAlign: "center" }}
            >
              By sending this message, you give us permission to use the email
              address provided to contact you regarding the contents of this
              message.
            </Typography>
          </Box>

          <Box
            style={{
              width: "100%",
              marginTop: 20,
              marginBottom: 50,
              textAlign: "center"
            }}
          >
            <YellowButton
              variant="contained"
              color="primary"
              onClick={() => {
                this.debounceSubmit();
              }}
            >
              Send Message
            </YellowButton>
          </Box>

          <ErrorMessage
            open={this.state.errorOpen}
            errorMessage={this.state.errorMessage}
            handleClose={() => {
              this.setState({ errorOpen: false });
            }}
          />
        </Container>
      </MuiThemeProvider>
    );
  }
}

export default Contact;
