import React from "react";
import { Typography, Box } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

const TemplateHelp = props => {
  return (
    <Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">Templates</Typography>
      </Box>

      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          What are templates?
        </Typography>
        <Typography gutterBottom variant="body1">
          Templates are a way to create entries quickly, using field variables
          and formatting of your choosing. On the entries screen choose a
          template from the template menu (lighting bolt), enter data in the
          field data form, and then the entry is created using that templates
          formatting, and also filling in the templates data values.
        </Typography>
        <Typography gutterBottom variant="body1">
          Templates have a name, title, and body. The name is just something
          friendly you can give your template to make it easy to find. The title
          is what all new entries created with this template will use for their
          title. Same with the body. However, the body allows you to use special
          field variables to use data you enter when you select the template.
        </Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          Template fields
        </Typography>
        <Typography gutterBottom variant="body1">
          Templates fields are a powerful way to customize templates. You create
          fields when you create a template. The field name (e.g. 'field1') is
          used to replace all places in the template body where that field name
          is found surrounded by handlebars (e.g. {`{{field1}}`}).
        </Typography>
        <Typography gutterBottom variant="body1">
          For example, a template with the following body:
        </Typography>

        <div style={{ textAlign: "left", marginBottom: 20 }}>
          <code gutterBottom variant="body1">
            Customer Call
            <br />
            <br />
            name: {`{{name}}`}
            <br />
            time: {`{{time_spent}} hours`}
            <br />
            notes: {`{{notes}}`}
            <br />
          </code>
        </div>

        <Typography gutterBottom variant="body1">
          Will turn into this after entering the field data when you add the
          entry:
        </Typography>

        <div style={{ textAlign: "left", marginBottom: 20 }}>
          <code gutterBottom variant="body1">
            Customer Call
            <br />
            <br />
            name: {`John Appleton`}
            <br />
            time: {`4.0 hours`}
            <br />
            notes: {`Call went well, no problems.`}
            <br />
          </code>
        </div>

        <Typography gutterBottom variant="body1">
          Think of fields as variables that you can define and use within the
          entry template anywhere you want. They are magically replaced with the
          values you enter when you use the template to create a new entry.
        </Typography>
        <Typography gutterBottom variant="body1">
          When you create a new entry with a template that has fields, a dialog
          will ask you to enter the field values instead of the usual freeform
          entry dialog. This field data can then be edited using the data dialog
          to update the entry.
        </Typography>
        <Typography gutterBottom variant="h6">
          Field Types
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Code</TableCell>
              <TableCell align="right">Description</TableCell>
            </TableHead>
            <TableBody>
              <TableCell align="left">String</TableCell>
              <TableCell align="center">string</TableCell>
              <TableCell align="right">A single line string.</TableCell>
            </TableBody>
            <TableBody>
              <TableCell align="left">Multiple Line String</TableCell>
              <TableCell align="center">multiline</TableCell>
              <TableCell align="right">
                A multiple line string text box.
              </TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography gutterBottom variant="h5">
          Smart fields
        </Typography>
        <Typography gutterBottom variant="body1">
          Smart fields are like template fields, except information is pulled
          from different sources and automatically inserted into your entry. The
          full list of smart fields are below and what they do. More smart
          fields will be added in the future.
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Tag</TableCell>
            <TableCell align="right">Description</TableCell>
          </TableHead>
          <TableBody>
            <TableCell align="left">Date</TableCell>
            <TableCell align="center">{`{{date}}`}</TableCell>
            <TableCell align="right">
              Inserts the date portion of the entries creation time.
            </TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Date/Time</TableCell>
            <TableCell align="center">{`{{datetime mode="LLL"}}`}</TableCell>
            <TableCell align="right">
              Inserts the date and time portion of the entries creation time
              using time formatting. Mode can be "full", "date", 'time', or a
              date formatter string.
            </TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Time</TableCell>
            <TableCell align="center">{`{{time}}`}</TableCell>
            <TableCell align="right">
              Inserts the time portion of the entries creation time.
            </TableCell>
          </TableBody>
          <TableBody>
            <TableCell align="left">Title</TableCell>
            <TableCell align="center">{`{{data.title}}`}</TableCell>
            <TableCell align="right">
              Inserts the title field of the entry taken from the entry data.
            </TableCell>
          </TableBody>

          <TableBody>
            <TableCell align="left">From Now</TableCell>
            <TableCell align="center">{`{{from_now}}`}</TableCell>
            <TableCell align="right">
              Inserts how much time has passed since the entry was created.
            </TableCell>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TemplateHelp;
