import React from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Snackbar,
  TextField,
  Grid
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CookieConsent from "react-cookie-consent";
import firebase from "firebase/app";
import { isMobile, isTablet } from "react-device-detect";
import { Helmet } from "react-helmet";

import "firebase/analytics";
import "firebase/auth";
import firebaseApp from "../Firebase/firebase";

import { Link } from "react-router-dom";

import { showFullSize } from "../Library/Helper";

import * as ROUTES from "../../constants/routes";
import YellowButton from "../Library/LandingYellowButton";

import logo from "../../assets/signin_image.png";
import ColorManager, { colorThemes } from "../Library/ColorManager";
const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const simplelib = require("@bvelasquez/simple-log-lib");

const theme = createTheme({
  typography: {
    fontFamily: [colors.font].join(","),
    button: {
      textTransform: "none"
    }
  },
  palette: {
    primary: {
      main: colors.secondaryBackground
    },

    background: {
      default: colors.secondaryBackground
    }
  }
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
      errorOpen: false,
      errorMessage: ""
    };

    localStorage.removeItem("currentUserId");
    localStorage.removeItem("logId");
    firebaseApp.auth().signOut();
  }

  componentDidMount() {
    console.log("Mount - signin");

    firebaseApp.analytics().logEvent("page_view", {
      page_location: "signin",
      page_path: "/signin",
      page_title: "Sign In"
    });
  }

  onSubmit = event => {
    console.log("LOGIN SUBMIT", event);
    const { email, password } = this.state;

    const history = this.props.history;
    const current = this;

    firebaseApp
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function() {
        return firebaseApp
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(credentials => {
            firebaseApp.analytics().setUserId(credentials.user.uid);
            firebaseApp
              .analytics()
              .logEvent("login", { method: "email address" });
            console.log("Sign in successful", credentials.user.uid);
            localStorage.setItem("currentUserId", credentials.user.uid);
            localStorage.setItem("email", credentials.user.email);

            simplelib.SetUserId(credentials.user.uid);
            simplelib.SetFirebase(firebaseApp);
            history.push(ROUTES.HOME);
          })
          .catch(reason => {
            console.log(reason);
            current.setState({ errorOpen: true, errorMessage: reason.message });
          });
      })
      .catch(function(error) {
        // Handle Errors here.
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleForgot = event => {
    const { email } = this.state;
    firebaseApp
      .auth()
      .sendPasswordResetEmail(email)
      .then(value => {
        firebaseApp.analytics().logEvent("forgot_password");

        console.log(value);
        this.setState({
          errorOpen: true,
          errorMessage: "Check your email for password reset instructions."
        });
      })
      .catch(reason => {
        console.log(reason);
        this.setState({ errorOpen: true, errorMessage: reason.message });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ errorOpen: false });
  };

  validateEmail = text => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      console.log("Email is Not Correct");
      return false;
    }

    return true;
  };

  getWidth = () => {
    let size = "md";
    if (!showFullSize()) {
      size = "xs";
    }
    return size;
  };

  render() {
    const { email, password } = this.state;
    const emailInValid = email === "" || !this.validateEmail(email);

    return (
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>SimpleLog3 - Sign in to your account</title>
          <meta
            name="description"
            content="Need a journal, log, or diary? SimpleLog3, the last log you'll ever need. Journal, Diary, Logging made simple. Free sign up."
          />
        </Helmet>
        <CssBaseline />
        <Container
          maxWidth={this.getWidth()}
          style={{ marginTop: 54, marginBottom: 12, paddingBottom: 24 }}
        >
          <Container maxWidth={this.getWidth()}>
            <Grid container>
              {showFullSize() === true && (
                <Grid
                  item
                  style={{
                    backgroundColor: colors.primaryBackground,
                    padding: 60,
                    borderTopLeftRadius: 12,
                    borderBottomLeftRadius: 12
                  }}
                  sm={6}
                >
                  <img
                    style={{
                      width: !showFullSize() ? 100 : isTablet ? 250 : 300
                    }}
                    src={logo}
                    alt="Simple Log Logo"
                  />
                </Grid>
              )}

              <Grid
                item
                style={{
                  backgroundColor: "white",
                  padding: isMobile ? 24 : 60,
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                  borderTopLeftRadius: isMobile ? 12 : 0,
                  borderBottomLeftRadius: isMobile ? 12 : 0
                }}
                sm={6}
              >
                <Typography
                  style={{
                    color: colors.secondaryBackground,
                    fontSize: 60,
                    fontWeight: "700",
                    marginBottom: isMobile ? 42 : 113
                  }}
                  align="center"
                >
                  Sign in
                </Typography>
                <form onSubmit={this.onSubmit}>
                  <Box style={{ marginBottom: 20 }}>
                    <TextField
                      required={true}
                      fullWidth={true}
                      variant="outlined"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      type="email"
                      placeholder="Email address"
                      autoComplete="username"
                    />
                  </Box>
                  <Box style={{ marginBottom: 20 }}>
                    <TextField
                      required={true}
                      fullWidth={true}
                      variant="outlined"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                    />
                  </Box>

                  <Box
                    style={{
                      width: "100%",
                      marginTop: 20,
                      marginBottom: 20,
                      textAlign: "center"
                    }}
                  >
                    <YellowButton
                      variant="contained"
                      type="submit"
                      style={{ width: 220 }}
                    >
                      Sign in
                    </YellowButton>
                  </Box>
                </form>

                <Box
                  style={{
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 20,
                    textAlign: "center"
                  }}
                >
                  <Button
                    style={{
                      color: emailInValid ? "gray" : colors.secondaryBackground
                    }}
                    disabled={emailInValid}
                    onClick={() => this.handleForgot()}
                  >
                    Forgot password? Enter email address and click here
                  </Button>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 20,
                    textAlign: "center"
                  }}
                >
                  <Link
                    style={{
                      color: colors.secondaryBackground,
                      fontSize: isMobile ? 12 : 14,
                      fontWeight: "500"
                    }}
                    to={ROUTES.REGISTER}
                  >
                    Need an Account? Register Here.
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>

          <Snackbar
            autoHideDuration={5000}
            open={this.state.errorOpen}
            onClose={this.handleErrorClose}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.errorMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />

          <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="simpleLogCookieAgree"
            style={{ background: colors.secondaryBackgroundBackground }}
            buttonStyle={{
              color: colors.secondaryBackground,
              fontSize: "13px",
              backgroundColor: colors.primaryHighlight,
              borderRadius: 8
            }}
            expires={150}
          >
            This website uses cookies to enhance the user experience. You can
            read our{" "}
            <a style={{ color: "white" }} href="./help/privacy">
              privacy policy
            </a>{" "}
            and{" "}
            <a style={{ color: "white" }} href="./help/terms">
              terms of service
            </a>{" "}
            here. Tap OK if you agree.
          </CookieConsent>
        </Container>
      </MuiThemeProvider>
    );
  }
}

export default Home;
