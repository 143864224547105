import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Box
} from "@material-ui/core";

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  isSameDay
} from "date-fns";

import { MuiThemeProvider } from "@material-ui/core/styles";

import { DateRangePicker } from "react-date-range";
import "../../../src/react-datepicker.css";

import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file
import "../../../src/date-range.css";

import dialogTheme from "../Library/DialogTheme";
import YellowButton from "../Library/YellowButton";
import colors from "../Library/Colors";

const moment = require("moment");

const NewDateSelector = props => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setOpen(props.open);
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props.open, props.startDate, props.endDate]);

  const handleClose = () => {
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  const handleGo = async () => {
    setOpen(false);
    props.didSelect({ start: moment(startDate), end: moment(endDate) });
  };

  const handleSelect = async ranges => {
    // setOpen(false);

    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);

    if (props.didSelect) {
      //props.didSelect({ start: moment(startDate), end: moment(endDate) });
    }
  };

  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    }
  };

  function createStaticRanges(ranges) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
  }

  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    startOfTwoWeeks: startOfDay(addDays(new Date(), -13)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date())
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection"
  };

  const overrideStaticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday
      })
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday
      })
    },

    {
      label: "This Week",
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek
      })
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek
      })
    },
    {
      label: "Two Weeks",
      range: () => ({
        startDate: defineds.startOfTwoWeeks,
        endDate: defineds.endOfToday
      })
    },
    {
      label: "Last 30 Days",
      range: () => {
        const start = moment()
          .subtract(30, "days")
          .startOf("day");

        return {
          startDate: start.toDate(),
          endDate: defineds.endOfToday
        };
      }
    },
    {
      label: "Last 90 Days",
      range: () => {
        const start = moment()
          .subtract(90, "days")
          .startOf("day");

        return {
          startDate: start.toDate(),
          endDate: defineds.endOfToday
        };
      }
    },
    {
      label: "This Month",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth
      })
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth
      })
    },
    {
      label: "Year to Date",
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfToday
      })
    }
  ]);

  const overrideInputRanges = [];

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        fullWidth={false}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEnforceFocus={true}
      >
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <Box style={{ marginBottom: 10 }}>
            <DateRangePicker
              rangeColors={[
                colors.primaryHighlight,
                colors.primaryBackground,
                colors.primaryBackground
              ]}
              fixedHeight={true}
              maxDate={new Date()}
              editableDateInputs={false}
              //   direction="vertical"
              //   scroll={{ enabled: true }}
              ranges={[selectionRange]}
              staticRanges={overrideStaticRanges}
              inputRanges={overrideInputRanges}
              onChange={handleSelect}
            />
          </Box>
        </DialogContent>

        <DialogActions
          style={{
            marginRight: 24,
            paddingRight: 0,
            marginTop: 35,
            marginBottom: 45
          }}
        >
          <YellowButton
            style={{ width: 200 }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </YellowButton>
          <YellowButton
            style={{ width: 200 }}
            onClick={handleGo}
            color="primary"
          >
            Go
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default NewDateSelector;
