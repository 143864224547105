import React from "react";
import { Typography, Box, Container, Grid, AppBar } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import helpTheme from "../Library/HelpTheme";
import { Helmet } from "react-helmet";
import firebaseApp from "../Firebase/firebase";
import { isMobile } from "react-device-detect";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

import MainHelp from "./MainHelp";
import EntriesHelp from "./EntriesHelp";
import TemplateHelp from "./TemplateHelp";
import FilterHelp from "./FilterHelp";
import LogHelp from "./LogHelp";
import PricingHelp from "./PricingHelp";
import AboutHelp from "./AboutHelp";
import TermsHelp from "./TermsHelp";
import PrivacyHelp from "./PrivacyHelp";
import APIHelp from "./APIHelp";
import Contact from "../Contact";
import MembershipHelp from "./MembershipHelp";
import colors from "../Library/Colors";

const Help = props => {
  console.log("Help", props);

  window.scrollTo(0, 0);

  firebaseApp.analytics().logEvent("page_view", {
    page_location: "help",
    page_path: "/help",
    page_title: "Help"
  });

  return (
    <MuiThemeProvider theme={helpTheme}>
      <Helmet>
        <title>SimpleLog3 - Help</title>
        <meta
          name="description"
          content="SimpleLog3 help. Get all your help here for SimpleLog3. Need a journal, log, or diary? SimpleLog3, the last log you'll ever need. Journal, Diary, Logging made simple."
        />
      </Helmet>

      <CssBaseline />
      <Router>
        <Container
          maxWidth="md"
          style={{ marginTop: 25, marginBottom: 50, minHeight: 600 }}
        >
          <Box style={{ marginTop: 24, marginBottom: 24, textAlign: "center" }}>
            <Typography variant="h4">
              <Link className="menuLinkHeader" to="/help">
                SimpleLog3 Help
              </Link>
            </Typography>
          </Box>

          <Grid
            spacing={isMobile ? 3 : 3}
            justifyContent="center"
            alignItems="center"
            container
            style={{ textAlign: "center" }}
          >
            <Grid item>
              <Typography
                className="menuLinkText"
                variant={isMobile ? "h6" : "h5"}
              >
                <Link className="menuLink" to="/help/logs">
                  Logs
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className="menuLinkText"
                variant={isMobile ? "h6" : "h5"}
              >
                <Link className="menuLink" to="/help/entries">
                  Entries
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className="menuLinkText"
                variant={isMobile ? "h6" : "h5"}
              >
                <Link className="menuLink" to="/help/templates">
                  Templates
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className="menuLinkText"
                variant={isMobile ? "h6" : "h5"}
              >
                <Link className="menuLink" to="/help/filters">
                  Filters
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className="menuLinkText"
                variant={isMobile ? "h6" : "h5"}
              >
                <Link className="menuLink" to="/help/membership">
                  Membership
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className="menuLinkText"
                variant={isMobile ? "h6" : "h5"}
              >
                <Link className="menuLink" to="/help/api">
                  API
                </Link>
              </Typography>
            </Grid>
          </Grid>

          <Switch>
            <Route exact path="/help">
              <MainHelp />
            </Route>
            <Route path="/help/logs">
              <LogHelp />
            </Route>
            <Route path="/help/entries">
              <EntriesHelp />
            </Route>
            <Route path="/help/templates">
              <TemplateHelp />
            </Route>
            <Route path="/help/filters">
              <FilterHelp />
            </Route>
            <Route path="/help/pricing">
              <PricingHelp plans={props.plans} />
            </Route>
            <Route path="/help/about">
              <AboutHelp />
            </Route>
            <Route path="/help/terms">
              <TermsHelp />
            </Route>
            <Route path="/help/privacy">
              <PrivacyHelp />
            </Route>
            <Route path="/help/api">
              <APIHelp />
            </Route>
            <Route path="/help/membership">
              <MembershipHelp />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
          </Switch>
        </Container>
      </Router>
      <AppBar
        position="sticky"
        style={{
          height: 100,
          backgroundColor: colors.secondaryBackgroundBackground,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography
          style={{
            marginTop: 12,
            fontSize: isMobile ? 8 : 14
          }}
        >
          <Link to="/">SimpleLog3</Link> &copy; Copyright 2020 - Barry
          Velasquez. All rights reserved.
        </Typography>
        <Box style={{ marginTop: 12 }}>
          <FacebookShareButton
            style={{ marginRight: 8 }}
            quote="Check out SimpleLog3 - online journal/diary."
            url="https://simplelog3.com"
          >
            <FacebookIcon size={42} round />
          </FacebookShareButton>
          <TwitterShareButton
            style={{ marginRight: 8 }}
            title="Check out SimpleLog3 - a cool online journal/diary."
            url="https://simplelog3.com"
          >
            <TwitterIcon size={42} round />
          </TwitterShareButton>
        </Box>
      </AppBar>
    </MuiThemeProvider>
  );
};

export default Help;
