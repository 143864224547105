import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider } from "@material-ui/core/styles";
import dialogTheme from "../Library/DialogTheme";
import YellowButton from "../Library/YellowButton";

const AlertDialog = props => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    setOpen(props.open);
    setTitle(props.title);
    setMessage(props.message);
  }, [props.open, props.title, props.message, props.hideSubscribeButton]);

  const handleClose = () => {
    props.handleClose();
    setOpen(false);
  };

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!props.hideSubscribeButton && (
            <YellowButton
              className="alertButton"
              onClick={() => {
                props.handleClose();
                if (props.handleNavigation) {
                  setOpen(false);
                  props.handleNavigation();
                }
              }}
              color="primary"
            >
              Subscribe now
            </YellowButton>
          )}
          <YellowButton
            className="alertButton"
            onClick={handleClose}
            color="primary"
            autoFocus
          >
            OK
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default AlertDialog;
