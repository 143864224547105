import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import ColorManager, { colorThemes } from "../Library/ColorManager";
const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const LandingYellowButton = withStyles(theme => ({
  root: {
    color: colors.secondaryBackground,
    backgroundColor: colors.primaryHighlight,
    fontWeight: "600",
    fontSize: 26,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 10
  },
  label: {
    color: colors.secondaryBackground,
    justifyContent: "center",
    textAlign: "center"
  }
}))(Button);

export default LandingYellowButton;
