import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Grid
} from "@material-ui/core";

import { MuiThemeProvider } from "@material-ui/core/styles";
import YellowButton from "../Library/YellowButton";
import dialogTheme from "../Library/DialogTheme";

const simplelib = require("@bvelasquez/simple-log-lib");

const QuickEntryFields = props => {
  const [title, setTitle] = useState(null);
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    setTitle(props.title);
    setOpen(props.open);
    setFields(props.fields);
  }, [props.open, props.fields, props.title]);

  const handleClose = () => {
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  const handleSave = async () => {
    if (props.didSave) {
      await props.didSave(
        fields.map(f => {
          if (f && f.fieldName) {
            return {
              fieldName: f.fieldName ? f.fieldName : "",
              fieldValue: f.fieldValue ? f.fieldValue : "",
              friendlyName: f.friendlyName ? f.friendlyName : "",
              fieldType: f.fieldType ? f.fieldType : simplelib.FieldTypes.string
            };
          }
          return null;
        })
      );
    }
    setOpen(false);
  };

  const editComponent = (field, changed) => {
    switch (field.fieldType) {
      case simplelib.FieldTypes.string:
      default:
        return (
          <TextField
            autoComplete="off"
            size="small"
            fullWidth={true}
            variant="outlined"
            name={field.fieldType}
            defaultValue={field.fieldValue ? field.fieldValue : ""}
            onChange={event => {
              changed(event.target.value);
              //field.fieldValue = event.target.value;
            }}
            type="text"
            placeholder={field.fieldType}
          />
        );

      case simplelib.FieldTypes.multiline:
        return (
          <TextField
            autoComplete="off"
            size="small"
            fullWidth={true}
            variant="outlined"
            multiline={true}
            maxRows={5}
            minRows={5}
            name={field.fieldType}
            defaultValue={field.fieldValue ? field.fieldValue : ""}
            onChange={event => {
              changed(event.target.value);
              //field.fieldValue = event.target.value;
            }}
            type="text"
            placeholder={field.fieldType}
          />
        );
    }
  };

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        fullWidth={true}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle>{title ? title : "Use Template"}</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container>
              {fields &&
                fields.map(f => {
                  return (
                    <Grid
                      key={f.fieldName}
                      container
                      alignItems="center"
                      style={{ marginBottom: 12 }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          marginRight: 12
                        }}
                      >
                        {f.friendlyName ? `${f.friendlyName}` : f.fieldName}
                      </Typography>

                      {editComponent(f, value => {
                        f.fieldValue = value;
                      })}
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions
          style={{
            marginRight: 24,
            paddingRight: 0,
            marginTop: 35,
            marginBottom: 45
          }}
        >
          <YellowButton
            style={{ width: 200 }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </YellowButton>
          <YellowButton
            style={{ width: 200 }}
            onClick={handleSave}
            color="primary"
          >
            Save
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default QuickEntryFields;
