import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Select,
  MenuItem,
  Typography
} from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import "../../../src/react-datepicker.css";

import {
  LineChart,
  BarChart,
  ScatterChart,
  Scatter,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

import dialogTheme from "../Library/DialogTheme";
import YellowButton from "../Library/YellowButton";

const simplelib = require("@bvelasquez/simple-log-lib");

const FilterChartsV2 = props => {
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterConfiguration, setFilterConfiguration] = useState(null);
  const [chartType, setChartType] = useState("line");

  var data;
  if (open) {
    if (
      filterConfiguration &&
      filterConfiguration.filter &&
      filterConfiguration.filter.yField &&
      filterConfiguration.filter.yField.length > 0
    ) {
      data = simplelib.ChartData.getFilterChartData2(
        filterData,
        filterConfiguration
      );
    } else {
      data = simplelib.ChartData.getEntryCountData2(
        filterData,
        filterConfiguration
      );
    }
  }

  useEffect(() => {
    setOpen(props.open);
    setFilterData(props.filterData);
    setFilterConfiguration(props.filterConfiguration);
  }, [props.open, props.filterData, props.filterConfiguration]);

  const handleClose = () => {
    setOpen(false);
    if (props.didClose) {
      props.didClose();
    }
  };

  var index = 0;
  const colors = [
    "#ffcd2a",
    "#CF6819",
    "#9CA616",
    "#5B5494",
    "#2E3961",
    "#3D5418"
  ];
  const getColor = () => {
    const result = colors[index];
    index += 1;
    if (index >= colors.length) {
      index = 0;
    }
    return result;
  };

  //   const getRandomColor = () => {
  //     var randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  //     return randomColor;
  //   };

  const handleSetChartType = value => {
    setChartType(value);
  };

  const getChart = (type, data) => {
    var min = 0;
    var max = 0;
    if (data) {
      min = data.minValue - Math.round(data.minValue * 0.02 * 100) / 100;
      max = data.maxValue + Math.round(data.maxValue * 0.02 * 100) / 100;

      min = Math.round(min * 100) / 100;
      max = Math.round(max * 100) / 100;

      console.log("min, max", min, max);
    }
    if (type === "line") {
      return (
        <LineChart width={800} height={400} data={data ? data.values : null}>
          <XAxis dataKey="name" />
          <YAxis domain={[data ? min : 0, data ? max : 0]} />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          {data
            ? data.fields.map(f => {
                return (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={f}
                    stroke={getColor(index)}
                  />
                );
              })
            : null}

          <Tooltip />
        </LineChart>
      );
    } else if (type === "bar") {
      return (
        <BarChart width={800} height={400} data={data ? data.values : null}>
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <XAxis dataKey="name" />
          <YAxis domain={[data ? min : 0, data ? max : 0]} />
          <Tooltip />
          {data
            ? data.fields.map(f => {
                return <Bar key={index} dataKey={f} fill={getColor(index)} />;
              })
            : null}
        </BarChart>
      );
    } else if (type === "scatter") {
      return (
        <ScatterChart width={800} height={400} data={data ? data.values : null}>
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <XAxis dataKey="name" />
          <YAxis domain={[data ? min : 0, data ? max : 0]} />
          <Tooltip />
          {data
            ? data.fields.map(f => {
                return (
                  <Scatter key={index} dataKey={f} fill={getColor(index)} />
                );
              })
            : null}
        </ScatterChart>
      );
    } else {
      return (
        <LineChart width={800} height={400} data={data ? data.values : null}>
          <XAxis dataKey="name" />
          <YAxis
            domain={[data ? data.minValue : 0, data ? data.maxValue : 0]}
          />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          {data
            ? data.fields.map(f => {
                return (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={f}
                    stroke={getColor(index)}
                  />
                );
              })
            : null}

          <Tooltip />
        </LineChart>
      );
    }
  };

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        maxWidth="md"
        fullWidth={false}
        scroll="body"
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEnforceFocus={true}
      >
        <DialogTitle>Charts</DialogTitle>
        <DialogContent>
          <Box style={{ marginBottom: 10 }}>
            <Typography variant="body1">Select Chart Type:</Typography>
            <Select
              displayEmpty
              fullWidth={true}
              variant="outlined"
              value={chartType}
              onChange={event => {
                handleSetChartType(event.target.value);
              }}
            >
              <MenuItem value="line">Line Chart</MenuItem>
              <MenuItem value="bar">Bar Chart</MenuItem>
              <MenuItem value="scatter">Scatter Chart</MenuItem>
            </Select>
          </Box>
        </DialogContent>
        <DialogContent>
          <Box
            className="ChartContainer"
            style={{
              width: "100%",
              height: "400px"
            }}
          >
            {getChart(chartType, data)}
          </Box>
        </DialogContent>

        <DialogActions
          style={{
            marginRight: 24,
            paddingRight: 0,
            marginTop: 35,
            marginBottom: 45
          }}
        >
          <YellowButton
            style={{ width: 200 }}
            onClick={handleClose}
            color="primary"
          >
            Close
          </YellowButton>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default FilterChartsV2;
