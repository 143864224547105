import React from "react";
import Hotkeys from "react-hot-keys";
import FlatList from "flatlist-react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider } from "@material-ui/core/styles";
import * as ROUTES from "../../constants/routes";
import SharedEntry from "../SharedLogPage/SharedEntry";
import { Container, Typography } from "@material-ui/core";
import pageTheme from "../Library/PageTheme";
import firebaseApp from "../Firebase/firebase";
import { Helmet } from "react-helmet";
import AlertDialog from "../Library/AlertDialog";

const queryString = require("query-string");

const styles = {
  dialogPaper: {
    minHeight: "600",
    maxHeight: "80vh"
  }
};

const SearchBar = props => {
  return (
    <TextField
      autoComplete="off"
      defaultValue={props.defaultValue}
      style={{ width: "100%", marginBottom: 20 }}
      color="secondary"
      id="standard-search"
      type="search"
      margin="normal"
      variant="outlined"
      placeholder="Search (e.g. #park or food eating)"
      onChange={props.searchChanged}
    />
  );
};

const LogTitle = props => {
  return (
    <Box style={{ textAlign: "center", marginTop: 18 }}>
      <Typography variant="h5" style={{ textDecoration: "none" }}>
        {props.logName}
      </Typography>
    </Box>
  );
};

const EmptyData = props => {
  return (
    <Container maxWidth="sm">
      <Box>
        <Typography
          align="center"
          variant="h5"
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          Oops! Could not find the shared log or it's empty
        </Typography>

        <Typography align="center" variant="h6">
          The log you are looking for has not entries yet, or is not longer
          shared.
        </Typography>
      </Box>
      <Box style={{ marginTop: 52 }}>
        <Typography
          style={{ marginTop: "12px", textAlign: "center" }}
          variant="h6"
        >
          SimpleLog<sup>3</sup>
        </Typography>
        <Typography
          style={{ marginTop: "12px", textAlign: "center" }}
          variant="h6"
        >
          <a href={`${window.location.origin.toString()}/register`}>Sign up</a>{" "}
          for a free account today, and start logging.
        </Typography>
      </Box>
    </Container>
  );
};

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class SharedLogPage extends React.Component {
  constructor(props) {
    super(props);

    this.searchTimer = null;
    this.selectedValue = null;
    this.dataSize = 30;
    this.showBookmarks = false;

    let url = this.props.location.search;
    let params = queryString.parse(url);

    this.state = {
      params: params,
      data: null,
      defaultValue: null,
      logId: null,
      userId: null,

      advancedSearch: false,

      selectedLog: "default",

      errorOpen: false,
      errorMessage: "",

      alertOpen: false,
      alertTitle: "",
      alertMessage: ""
    };

    this.shortcuts = [];
  }

  onKeyUp = (keyName, e, handle) => {
    // console.log("test:onKeyUp", e, handle);
  };

  onKeyDown = (keyName, e, handle) => {
    console.log("test:onKeyDown", keyName);

    const command = this.shortcuts.find(f => {
      return f.command === keyName;
    });
    if (command) {
      command.function();
    }
  };

  showError = error => {
    this.setState({ errorOpen: true, errorMessage: error });
  };

  setupData = (searchText = null, filterSearch = null) => {
    let match = this.props.match.params;

    let url = this.props.location.search;
    let params = queryString.parse(url);

    console.log("MATCH ==>", match, params);

    let logId = match.logId;
    let userId = match.userId;

    if (this.snapshotRef) {
      this.snapshotRef();
    }

    if (userId && logId) {
      this.logDocRef = firebaseApp
        .firestore()
        .collection("shared_log")
        .doc(`${userId}/${logId}/metadata`)
        .get()
        .then(doc => {
          if (doc.data()) {
            this.setState({
              logName: doc.data().name,
              logId: logId,
              userId: userId
            });
          }
        });

      this.entriesCollectionRef = firebaseApp
        .firestore()
        .collection("shared_log")
        .doc(userId)
        .collection(`/${logId}/shared/items`);

      if (searchText && searchText.length > 0) {
        const bodyArray = searchText
          .toLocaleLowerCase()
          .replace("\n", " ")
          .split(" ");

        const searchArray = bodyArray.filter(f => {
          return f.trim().length > 0;
        });

        this.snapshotRef = this.entriesCollectionRef
          .where("keywords", "array-contains-any", searchArray)
          .orderBy("createdAt", "desc")
          .onSnapshot(
            snapshot => {
              this.setState({
                data: snapshot.docs,
                advancedSearch: true,
                filterSearch: false
              });
            },
            error => {
              console.log(error);
            }
          );
      } else {
        let ref = this.entriesCollectionRef.orderBy("createdAt", "desc");

        if (params.limit) {
          const limit = parseInt(params.limit);
          if (Number.isInteger(limit)) {
            ref = ref.limit(limit);
          }
        }

        if (params.search) {
          const bodyArray = params.search
            .toLocaleLowerCase()
            .replace("\n", " ")
            .split(" ");

          const searchArray = bodyArray.filter(f => {
            return f.trim().length > 0;
          });

          ref = ref.where("keywords", "array-contains-any", searchArray);
        }

        this.snapshotRef = ref.onSnapshot(
          snapshot => {
            this.setState({
              data: snapshot.docs,
              advancedSearch: false,
              filterSearch: false
            });
          },
          error => {
            console.log(error);
          }
        );
      }
    }
  };

  componentDidMount() {
    this.setupData();
  }

  componentWillUnmount() {
    if (this.snapshotRef) {
      console.log("Deleting snapshotRef.");
      this.snapshotRef();
    }
  }

  showAlert = (title, message) => {
    this.setState({
      alertOpen: true,
      alertTitle: title,
      alertMessage: message
    });
  };

  handleLoadMore = () => {
    this.dataSize += 15;
    this.setupData();
  };

  handleDateChange = date => {
    this.selectedDate = date;
    this.setState({ selectedDate: date });
  };

  searchChanged = event => {
    const value = event.target.value;

    if (this.searchTimer != null) {
      clearTimeout(this.searchTimer);
    }

    this.searchTimer = setTimeout(() => {
      if (value.length > 0) {
        this.setupData(value);
      } else {
        this.setupData();
      }
    }, 400);
  };

  renderCredits = () => {
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 12
        }}
      >
        <Box style={{ textAlign: "center", marginTop: 4, marginBottom: 12 }}>
          <Typography style={{ textDecoration: "none", fontSize: 16 }}>
            This Log created with <a href="/">SimpleLog3</a>
          </Typography>
          <Typography style={{ textDecoration: "none", fontSize: 12 }}>
            Get your free log today!
          </Typography>
        </Box>
      </Container>
    );
  };

  renderTitle = () => {
    if (this.state.params && this.state.params.hideTitle) {
      return (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: 12
          }}
        >
          <Box></Box>
        </Container>
      );
    }
    return <LogTitle logName={this.state.logName} />;
  };

  renderSearchBar = data => {
    if (this.state.params && this.state.params.hideSearch) {
      return (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: 12
          }}
        >
          <Box></Box>
        </Container>
      );
    }
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        {data.length > 0 && (
          <SearchBar
            defaultValue={this.state.defaultValue}
            searchChanged={this.searchChanged}
          />
        )}
      </Container>
    );
  };

  render() {
    const { data } = this.state;

    if (data === null) {
      return null;
    }

    return (
      <Hotkeys
        keyName={this.shortcuts
          .map(f => {
            return f.command;
          })
          .join(",")}
        onKeyDown={this.onKeyDown.bind(this)}
        onKeyUp={this.onKeyUp.bind(this)}
      >
        <MuiThemeProvider theme={pageTheme}>
          <Helmet>
            <title>SimpleLog3 - Shared Log</title>
            <meta name="description" content="SimpleLog3 shared log." />
            <meta name="robots" content="noindex" />
          </Helmet>
          <Container maxWidth="md" style={{ height: "80%", paddingBottom: 25 }}>
            {this.renderTitle()}

            {this.renderSearchBar(data)}

            <FlatList
              style={{ height: "100%" }}
              list={data}
              renderItem={(item, idx) => {
                const value = item.data();
                return (
                  <SharedEntry
                    hideAd={true}
                    key={value.id}
                    value={value}
                    logName={this.state.logName}
                    logId={this.state.logId}
                    userId={this.state.userId}
                    history={this.props.history}
                  />
                );
              }}
              renderWhenEmpty={() => {
                return <EmptyData addClick={() => {}} />;
              }}
            />
          </Container>
          {this.renderCredits()}
          <Snackbar
            open={this.state.errorOpen}
            autoHideDuration={3000}
            onClose={() => {
              this.setState({ errorOpen: false });
            }}
          >
            <Alert
              autoHideDuration={3000}
              onClose={() => {
                this.setState({ errorOpen: false });
              }}
              severity="error"
            >
              {this.state.errorMessage}
            </Alert>
          </Snackbar>

          <AlertDialog
            open={this.state.alertOpen}
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            handleClose={() => {
              this.setState({ alertOpen: false });
            }}
            handleNavigation={() => {
              // navigate to account.
              console.log("HANDLE NAVIGATION");
              this.props.history.push(ROUTES.ACCOUNT);
            }}
          />
        </MuiThemeProvider>
      </Hotkeys>
    );
  }
}

export default withStyles(styles)(SharedLogPage);
