import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyDMdNuxBC5xmrPplOkBSYr6I9y_22pEvVM",
  authDomain: "simplelog-6c60d.firebaseapp.com",
  databaseURL: "https://simplelog-6c60d.firebaseio.com",
  projectId: "simplelog-6c60d",
  storageBucket: "simplelog-6c60d.appspot.com",
  messagingSenderId: "553416885061",
  appId: "1:553416885061:web:cfdbcb96b9dfaab8",
  measurementId: "G-XFHRG0FHSS"
};

// src/firebase.js
const firebaseApp = firebase.initializeApp(firebaseConfig);
const remoteConfig = firebaseApp.remoteConfig();

remoteConfig.defaultConfig = {
  free_logs: 1,
  free_filters: 2,
  free_templates: 2
};

// try {
//   remoteConfig.fetchAndActivate();
// } catch (e) {}

// firebase.firestore().settings({
//   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
// });

// firebaseApp
//   .firestore()
//   .enablePersistence({
//     synchronizeTabs: true
//   })
//   .catch(e => {
//     console.error("OFFLINE FAILED =>", e);
//   });

export default firebaseApp;
