import React from "react";
import { Typography, Box, Container } from "@material-ui/core";

import Subscriptions from "../Library/Subscriptions";

const PricingHelp = props => {
  return (
    <Container maxWidth="md" style={{ height: "80%", marginTop: 25 }}>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Typography variant="h4">Pricing</Typography>
      </Box>
      <Box style={{ marginTop: 24, textAlign: "center" }}>
        <Subscriptions plans={props.plans} />
      </Box>
    </Container>
  );
};

export default PricingHelp;
