import React from "react";
import { Typography } from "@material-ui/core";
import colors from "../Library/Colors";

const LogName = props => {
  let logName = "My Log";

  if (props.value && props.logs) {
    const logData = props.logs.find(l => {
      const data = l.data();
      return data.id === props.value.log;
    });

    if (logData) {
      logName = logData.data().title;
    }
  }

  return (
    <Typography
      style={{
        marginTop: "10px",
        color: colors.entryPrimaryHighlight
      }}
      variant="subtitle2"
    >
      {`${logName}`}
    </Typography>
  );
};

export default LogName;
