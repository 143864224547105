import React from "react";
import uuid from "react-native-uuid";
import FlatList from "flatlist-react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import CssBaseline from "@material-ui/core/CssBaseline";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Helmet } from "react-helmet";
import * as ROUTES from "../../constants/routes";
import Log from "../Logs/Log";
import MyLog from "../Logs/MyLog";
import { Container, Typography, Grid, IconButton } from "@material-ui/core";

import firebaseApp from "../Firebase/firebase";

import LogEdit from "./LogEdit";
import AlertDialog from "../Library/AlertDialog";
import NavigationButton from "../Library/NavigationButton";
import pageTheme from "../Library/PageTheme";
import colors from "../Library/Colors";
import YellowButton from "../Library/YellowButton";
import PasswordEntry from "../Home/PasswordEntry";

import { checkPlan } from "../Library/Helper";

const simplelib = require("@bvelasquez/simple-log-lib");
const crypto = require("crypto");

const styles = {
  dialogPaper: {
    minHeight: "600",
    maxHeight: "80vh"
  }
};

const EmptyData = props => {
  return (
    <Container maxWidth="sm">
      <Box>
        <Typography
          align="center"
          variant="h5"
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          You have no additional logs
        </Typography>

        <Typography align="center" variant="h6">
          To get started, click the + button at the upper right and add an
          additional log. You can then choose that log when adding new entries.
        </Typography>

        <Box
          component="div"
          maxWidth="xs"
          style={{ textAlign: "center", marginTop: 24 }}
        >
          <YellowButton
            variant="contained"
            color="primary"
            align="center"
            onClick={() => {
              props.addClick();
            }}
          >
            Add a new log now
          </YellowButton>
        </Box>
      </Box>
    </Container>
  );
};

class Logs extends React.Component {
  constructor(props) {
    super(props);

    this.selectedLog = null;
    this.editLog = {
      title: "",
      body: "",
      allowWriteAPIAccess: false,
      allowReadAPIAccess: false,
      enableWebhook: false,
      webhookPasscode: "",
      isVaulted: false
    };

    this.state = {
      data: null,
      open: false,
      fabOpen: false,
      alertOpen: false,
      alertTitle: "",
      alertMessage: "",
      showArchived: false,
      passwordOpen: false
    };

    this.actions = [
      {
        id: 0,
        icon: <AddIcon />,
        name: "Add Log",
        action: this.handleAddEntryButton
      }
    ];
  }

  setupData = () => {
    simplelib.FirebaseSnapshots.logs(this.observer, docs => {
      if (!this.state.showArchived) {
        const finalDocs = docs.filter(d => {
          const data = d.data();
          return !data.archived || data.archived === false;
        });
        this.setState({ data: finalDocs });
      } else {
        this.setState({ data: docs });
      }
    });

    simplelib.FirebaseSnapshots.defaultLog(this.defaultObserver, doc => {
      this.setState({ defaultLog: doc });
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    firebaseApp.analytics().logEvent("page_view", {
      page_location: "logs",
      page_path: "/logs",
      page_title: "Logs"
    });

    this.setupData();

    this.authSubscription = firebaseApp.auth().onAuthStateChanged(user => {
      if (!user) {
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("logId");
        this.props.history.push(ROUTES.LANDING);
      } else {
        localStorage.setItem("currentUserId", user.uid);
      }
    });
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer();
    }
    if (this.defaultObserver) {
      this.defaultObserver();
    }
    if (this.authSubscription) {
      this.authSubscription();
    }
  }

  onChangeTitle = event => {
    //this.setState({ selectedTitle: event.target.value });
    this.selectedTitle = event.target.value;
  };

  onChangeBody = event => {
    //this.setState({ selectedBody: event.target.value });
    this.selectedBody = event.target.value;
  };

  handleClose = () => {
    this.setState({ open: false, fabOpen: false });
  };

  handleSave = () => {
    this.onSubmit(() => {
      this.setState({ open: false, fabOpen: false });
    });
  };

  onSubmit = completion => {
    let id = this.editLog.id;
    var isAdd = true;
    if (this.selectedLog) {
      id = this.selectedLog.id;
      isAdd = false;
    }

    let update = {
      id: id,
      title: this.editLog.title,
      body: this.editLog.body,
      allowWriteAPIAccess: this.editLog.isVaulted
        ? false
        : this.editLog.allowWriteAPIAccess,
      allowReadAPIAccess: this.editLog.isVaulted
        ? false
        : this.editLog.allowReadAPIAccess,
      enableWebhook: this.editLog.isVaulted
        ? false
        : this.editLog.enableWebhook,
      webhookPasscode: this.editLog.webhookPasscode,
      updatedAt: new Date()
    };

    if (isAdd) {
      var tokenString = null;
      if (this.editLog.isVaulted && this.editLog.vaultPassword.length >= 10) {
        // Create vault validation token.

        const token = {
          logId: id,
          check: "HELLO"
        };
        tokenString = simplelib.encrypt(
          JSON.stringify(token),
          this.editLog.vaultPassword,
          id,
          crypto
        );
        update.isVaulted = this.editLog.isVaulted;
        update.tokenString = tokenString;
      }
    }

    simplelib.FirebaseFunctions.saveLog(id, update)
      .then(() => {
        completion();
      })
      .catch(e => {
        completion();
      });
  };

  showAlert = (title, message) => {
    this.setState({
      alertOpen: true,
      alertTitle: title,
      alertMessage: message
    });
  };

  handleAddEntryButton = () => {
    if (!checkPlan()) {
      const freeValue = firebaseApp.remoteConfig().getValue("free_logs");
      console.log("free logs", freeValue.asNumber());

      if (!this.state.data) {
        return;
      }

      if (this.state.data && this.state.data.length >= 0) {
        firebaseApp.analytics().logEvent("need_subscription_alert");
        this.showAlert(
          "Subscription",
          "You need a subscription to add more Logs. Logs are a great way to organize your entries by type. Keep a log of travel, or one for your workouts. Sign up, and create as many logs as you like."
        );
        return;
      }
    }

    firebaseApp.analytics().logEvent("add_log");

    this.selectedLog = null;
    this.isAdd = true;

    this.editLog = {
      id: uuid.v4(),
      title: "",
      body: "",
      allowWriteAPIAccess: false,
      allowReadAPIAccess: false,
      enableWebhook: false,
      webhookPasscode: "",
      isVaulted: false
    };

    this.setState({
      open: true,
      fabOpen: false
    });
  };

  didDeleteLog = value => {
    if (value.isVaulted) {
      //
      this.potentialLog = value;
      this.passwordAction = "delete";
      this.setState({ passwordOpen: true });
    } else {
      var r = window.confirm(
        "Delete this Log? This cannot be undone and all data and images will be lost."
      );
      if (r === true) {
        simplelib.FirebaseFunctions.deleteLog(value.id);
      }
    }
  };

  didEditDefaultLog = () => {
    //
    this.isAdd = false;
    this.editLog = {
      id: "default",
      title: "My Log",
      body: `"My Log" is the default place for your entries. You cannot edit or delete this log. New entries will go into this log unless you create or choose a different log.`,
      allowWriteAPIAccess: false,
      allowReadAPIAccess: false,
      enableWebhook: false,
      webhookPasscode: null,
      isVaulted: false
    };
    if (this.state.defaultLog) {
      this.editLog = {
        id: "default",
        title: "My Log",
        body: this.state.defaultLog.body,
        allowWriteAPIAccess: this.state.defaultLog.allowWriteAPIAccess
          ? this.state.defaultLog.allowWriteAPIAccess
          : false,
        allowReadAPIAccess: this.state.defaultLog.allowReadAPIAccess
          ? this.state.defaultLog.allowReadAPIAccess
          : false,
        enableWebhook: this.state.defaultLog.enableWebhook
          ? this.state.defaultLog.enableWebhook
          : false,
        webhookPasscode: this.state.defaultLog.webhookPasscode
          ? this.state.defaultLog.webhookPasscode
          : null,
        isVaulted: false
      };
    }
    this.setState({
      open: true,
      fabOpen: false
    });
  };

  didEditLog = value => {
    if (!value) {
      return;
    }
    const plan = checkPlan();
    if (!plan) {
      this.showAlert(
        "Subscription",
        "You need a subscription to edit additional Logs. Logs are a great way to organize your entries. Keep a log of travel, or one for your workouts. Sign up to edit your additional logs."
      );
      return;
    }

    this.isAdd = false;
    this.editLog = {
      id: value.id,
      title: value.title,
      body: value.body,
      allowWriteAPIAccess: value.allowWriteAPIAccess
        ? value.allowWriteAPIAccess
        : false,
      allowReadAPIAccess: value.allowReadAPIAccess
        ? value.allowReadAPIAccess
        : false,
      enableWebhook: value.enableWebhook ? value.enableWebhook : false,
      webhookPasscode: value.webhookPasscode ? value.webhookPasscode : null,
      isVaulted: value.isVaulted ? value.isVaulted : false
    };

    if (this.editLog.isVaulted) {
      //
      this.potentialLog = value;
      this.passwordAction = "edit";
      this.setState({ passwordOpen: true });
    } else {
      this.setState({
        open: true
      });
    }
  };

  handleFabOpen = () => {
    console.log("OPENING FAB.");
    this.setState({ fabOpen: true });
  };

  handleFabClose = () => {
    console.log("CLOSING FAB.");
    this.setState({ fabOpen: false });
  };

  renderSpeedDial = () => {
    const { fabOpen } = this.state;
    return (
      <Box style={{ position: "fixed", right: 10, bottom: 20 }}>
        <SpeedDial
          ariaLabel="SpeedDial"
          hidden={false}
          icon={<SpeedDialIcon />}
          onClose={() => {
            //console.log("SPEED DIAL onClose()");
            this.handleFabClose();
          }}
          onMouseEnter={() => {
            //console.log("SPEED DIAL onOpen()");
            this.handleFabOpen();
          }}
          onTouchStart={() => {
            this.handleFabOpen();
          }}
          open={fabOpen}
          direction="up"
        >
          {this.actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.action}
            />
          ))}
        </SpeedDial>
      </Box>
    );
  };

  renderTitleArea = () => {
    return (
      <Grid spacing={1} container alignItems="center">
        <Grid item md={6}>
          <Typography variant="h4" align="left">
            Logs
          </Typography>
        </Grid>
        <Grid item md={5} style={{ textAlign: "right" }}>
          <NavigationButton
            style={{ width: isMobile ? 150 : 250 }}
            variant="outlined"
            onClick={() => {
              this.setState({ showArchived: !this.state.showArchived });
              this.setupData();
            }}
          >
            {this.state.showArchived
              ? `Hide archived logs`
              : `Show archived logs`}
          </NavigationButton>
        </Grid>
        <Grid item md={1}>
          <IconButton onClick={this.handleAddEntryButton}>
            <AddCircleIcon
              style={{
                fontSize: isMobile ? 24 : colors.primaryIconSize,
                color: colors.primaryHighlight
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { data } = this.state;

    if (data === null) {
      return null;
    }

    return (
      <MuiThemeProvider theme={pageTheme}>
        <Helmet>
          <title>SimpleLog3 - Logs</title>
          <meta name="description" content="SimpleLog3 logs." />
          <meta name="robots" content="noindex" />
        </Helmet>
        <CssBaseline />
        <Container maxWidth="md" style={{ marginTop: 25, paddingBottom: 25 }}>
          <Container style={{ marginBottom: 24, paddingLeft: 0 }}>
            {this.renderTitleArea()}

            <Typography
              align="left"
              style={{
                width: isMobile ? 400 : 800,
                fontSize: isMobile ? 12 : colors.featureDescriptionFontSize
              }}
            >
              Logs are where your entries are stored. Want to be more organized?
              Create a workout log, a customer meeting log, or one for a trip.
              Click the + button to get started.
            </Typography>
            <Link className="helpImproveLink" to="/contact">
              Help us improve. Give us feedback
            </Link>
          </Container>

          <MyLog
            history={this.props.history}
            didEdit={this.didEditDefaultLog}
          />

          <FlatList
            style={{ height: "100%" }}
            list={data}
            renderItem={(item, idx) => {
              const value = item.data();
              return (
                <Log
                  key={value.id}
                  value={value}
                  history={this.props.history}
                  didEdit={this.didEditLog}
                  didDeleteLog={this.didDeleteLog}
                ></Log>
              );
            }}
            renderWhenEmpty={() => {
              return (
                <EmptyData
                  addClick={() => {
                    this.handleAddEntryButton();
                  }}
                />
              );
            }}
          />

          <LogEdit
            open={this.state.open}
            editLog={this.editLog}
            isAdd={this.isAdd}
            didSave={data => {
              this.editLog = data;
              this.setState({ open: false });
              this.handleSave();
            }}
            didClose={() => {
              this.setState({ open: false });
            }}
          />

          <AlertDialog
            open={this.state.alertOpen}
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            handleClose={() => {
              this.setState({ alertOpen: false });
            }}
            handleNavigation={() => {
              // navigate to account.
              console.log("HANDLE NAVIGATION");
              this.props.history.push(ROUTES.ACCOUNT);
            }}
          />

          <PasswordEntry
            open={this.state.passwordOpen}
            didSelect={password => {
              if (this.potentialLog) {
                try {
                  const tokenValue = simplelib.decrypt(
                    this.potentialLog.tokenString,
                    password,
                    this.potentialLog.id,
                    crypto
                  );
                  const tokenObject = JSON.parse(tokenValue);

                  if (
                    tokenObject &&
                    tokenObject.logId === this.potentialLog.id &&
                    tokenObject.check === "HELLO"
                  ) {
                    // Password works.
                    if (this.passwordAction === "edit") {
                      this.editLog = {
                        id: this.potentialLog.id,
                        title: this.potentialLog.title,
                        body: this.potentialLog.body,
                        allowWriteAPIAccess: this.potentialLog
                          .allowWriteAPIAccess,
                        allowReadAPIAccess: this.potentialLog
                          .allowReadAPIAccess,
                        enableWebhook: this.potentialLog.enableWebhook,
                        webhookPasscode: this.potentialLog.webhookPasscode,
                        isVaulted: this.potentialLog.isVaulted
                      };
                      this.setState(
                        {
                          passwordOpen: false,
                          open: true
                        },
                        () => {
                          this.potentialLog = null;
                        }
                      );
                    } else if (this.passwordAction === "delete") {
                      //
                      this.setState({ passwordOpen: false });
                      var r = window.confirm(
                        "Delete this Log? This cannot be undone and all data and images will be lost."
                      );
                      if (r === true) {
                        simplelib.FirebaseFunctions.deleteLog(
                          this.potentialLog.id
                        );
                      }
                    }
                  }
                } catch (e) {
                  this.potentialLog = null;
                  this.showAlert(
                    "Invalid Password",
                    "The password provided does not unlock this log."
                  );

                  this.setState({ passwordOpen: false });
                }
              }
            }}
            didClose={() => {
              this.setState({ passwordOpen: false });
            }}
          />
        </Container>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Logs);
