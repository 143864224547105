import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ColorManager, { colorThemes } from "../Library/ColorManager";
const colors = ColorManager.getColorTheme(colorThemes.midnightFireTheme);

const TitleDarkOutlineButton = withStyles(theme => ({
  root: {
    color: colors.secondaryBackground,
    backgroundColor: colors.secondaryBackground,
    fontWeight: "500",
    fontSize: 26,
    paddingTop: 0,
    paddingBottom: 0
  }
}))(Button);

export default TitleDarkOutlineButton;
